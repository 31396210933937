import React, { useState, useEffect } from "react";
import { Grid, Button, Paper, Hidden, Divider, Drawer, ListItem, List, ListItemText, IconButton } from "@material-ui/core";
import mainLogo from "./../../../images/newLogo.png";

import { Link } from "react-router-dom";
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Mailer from "../../Contact/mailer";


function HeaderBar(props) {
  const [trasparent, setTrasparent] = useState(true);
  const [numberOfItems, setNumberOfItems] = useState("")
  window.addEventListener("scroll", (e) => {
    setTrasparent(window.scrollY === 0);
  });

  return (
    <div className="uk-container">
        
        {/** DESKTOP NAV **/}
      <div className="uk-visible@l">
             <div
      component={Grid}
      container
      className="headerBar"
      elevation={1}
      square
      style={{
        background:
          !props.fillHeader && trasparent ? "transparent" : "rgba(0,0,0,0.93)",
      }}
    >
           <div className="uk-container">
             <div className="mainNavBar">
             <nav class="uk-navbar-container" data-uk-navbar>

                   <div class="uk-navbar-left" uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                      <Link to ="/"><img src= {mainLogo} className="logoStyle"/> </Link>
                  </div>


                 <div class="uk-navbar-center">
                 <nav class="stroke  "   >
                        <ul class="uk-navbar-nav ">
                            
                            <li>
                               <Link to ="/"> Home</Link>
                           
                            </li>

                            <li>
                                <Link to="/About">About Us</Link>
                                <div class="uk-navbar-dropdown">
                                    <ul class="uk-nav uk-navbar-dropdown-nav">
                                    <Link to ="/team">  <li class="uk-active "><a href="#"> The Team </a></li> </Link>
                                     
                                      </ul>
                                      
                                  </div>
                            
                            </li>
                             <li>
                                <a href="#">Our Services</a>
                                <div class="uk-navbar-dropdown">
                                    <ul class="uk-nav uk-navbar-dropdown-nav">
                                      <Link to ="/estatedevelopment">  <li class="uk-active "><a href="#"> Real estate development </a></li> </Link>
                                      <Link to ="/Construction" > <li className=""><a href="#"> Construction </a></li> </Link>
                                      <Link to ="/investment">  <li className=""><a href="#">Investment </a></li> </Link>
                                       <Link to ="/remodelling" > <li className=""><a href="#">Remodeling </a></li> </Link>
                                       <Link  to ="/infrastructure"> <li className=""><a href="#">Infrastructure</a></li> </Link>
                                    </ul>
                                </div>
                            </li>
                               
                            <li>
                              <Link to="/Portfolio">  Portfolio </Link>
                            
                            </li>
                          
                            <li>
                                <a href="#">Projects</a>
                                <div class="uk-navbar-dropdown">
                                    <ul class="uk-nav uk-navbar-dropdown-nav">
                                      <Link to ="/boite311">  <li class="uk-active "><a href="#"> Boite 311 Residence</a></li> </Link>
                                      <Link to ="/valley_court" > <li className=""><a href="#"> Valley Court </a></li> </Link>
                                      <Link to ="/boiteAzure" > <li className=""><a href="#"> Boite Azure </a></li> </Link>
                                      <Link to ="/BoiteSapphire" > <li className=""><a href="#"> Boite Sapphire </a></li> </Link>
                                      
                                      <Link to ="/Valley_Court2" > <li className=""><a href="#"> Valley Court II </a></li> </Link>
                                      
                                      
                                      
                                    </ul>
                                </div>
                            </li>

                            <li>
                                <Link to="/Contact"><a href="#">Contact</a> </Link>
                            
                            </li>








                        </ul>
                        </nav>
                 </div>
                   



                 <div class="uk-navbar-right">
                   
                     <div className="headerBtn">
                     
<button class="uk-button appntmentButton uk-button-default uk-margin-small-right" type="button" data-uk-toggle="target: #modal-example">Book Appointment</button>


<div id="modal-example" data-uk-modal>
    <div class="uk-modal-dialog uk-modal-body">
       <div>
           <Mailer />
       </div>
    </div>
</div>
               
                     </div>
                   
                   </div>



             </nav>
          </div>
        </div>

    </div>
    </div> {/** desktop nav ends here **/}




      {/*MOBILE NAV STARTS HERE*/}
      <div className="uk-hidden@l">
      <div
                  component={Grid}
                  container
                  className="headerBar"
                  elevation={1}
                  square
                  style={{
                    background:
                      !props.fillHeader && trasparent ? "transparent" : "rgba(0,0,0,0.93)",
                  }}
                >
                   
                        <div className="mobileNav">
                        <div className="" data-uk-grid>
                              <div className="uk-width-1-2">
                                    <div>
                                    <Link to ="/"><img src= {mainLogo} className="logoStyle"/> </Link>
                                    </div>
                              </div>

                              <div className="uk-width-1-2 ">
                                <div className="modalBtn">
                              <a class="uk-button uk-button-default " data-uk-toggle="target: #offcanvas-flip" uk-icon="menu" uk-toggle></a>

                      <div id="offcanvas-flip" data-uk-offcanvas="flip: true; overlay: true">
                              <div class=" mobileMenuBar uk-offcanvas-bar">

                              <button class="uk-offcanvas-close" type="button" data-uk-close></button>





                               
                                     <div className="uk-margin-xlarge-top">
                             
                                     <nav id="menu">

                                                <ul class="parent-menu">

                                               <Link to="/"> <li><a href="#">Home</a> </li> </Link>
                                                <Link to="/About"> <li><a href="#">About Us</a></li> </Link>


                                                
                                                    <li><a href="#">Our Services</a>

                                                      <ul>
                                                      <Link to ="/estatedevelopment">  <li class="uk-active "><a href="#"> Real estate development </a></li> </Link>
                                                        <Link to ="/Construction" > <li className=""><a href="#"> Construction </a></li> </Link>
                                                        <Link to ="/investment">  <li className=""><a href="#">Investment </a></li> </Link>
                                                       
                                       <Link to ="/remodelling" > <li className=""><a href="#">Remodeling </a></li> </Link> 
                                       <Link to ="/infrastructure" >  <li className=""><a href="#">Infrastructure</a></li> </Link>

                                                    </ul>
                                                </li>
                                                
                                               <Link to ="/Portfolio"> <li><a href="#">Portfolio</a> </li></Link>

                                            <li>
                                                 <a href="#">Projects</a>

                                                <ul>
                                                <Link to ="/boite311">  <li class="uk-active "><a href="#"> Boite 311 Residence</a></li> </Link>
                                                <Link to ="/valley_court" > <li className=""><a href="#"> Valley Court </a></li> </Link>
                                     
                                               
                                                </ul>
                                           </li>
                                                 <Link to ="/Contact"><li><a href="#">Contact Us</a></li> </Link>

                                                



                                          </ul>

                          </nav>

                          </div>

                          <div className="">
                            
                               <p>BUTTON</p>
                          </div>
                                                          










                             </div>
                       </div>
                              </div>
                              
                              
                              
                              
                              </div>
                        </div>
                        </div>
      
      
      
      
       </div>
      </div>
   




























    </div>
  );
}



export default HeaderBar;
