import Layout from "../Layout";
import React from "react";
import First_pane from "./Blocks/First_pane";



export default function AboutUsPage() {

    return (
        <Layout>
        <First_pane />
            
        </Layout>

    )




}