import react from 'react'
import Layout from '../../Layout'
import construct6 from './../../../images/construct6.jpg'


import const1 from './../../../images/const1.jpg'
import const2 from './../../../images/const2.jpg'
import const3 from './../../../images/const3.jpg'
import const4 from './../../../images/const4.jpg'


import const5 from './../../../images/const5.jpg'
import const6 from './../../../images/const6.jpg'
import const7 from './../../../images/const7.jpg'
import const8 from './../../../images/const8.jpg'



const constructionPort = () => (

    <Layout>
            <div className=''>
                     <div
                       style={{ backgroundImage: `url(${construct6})`,
                       backgroundRepeat: 'no-repeat',
                       backgroundSize: 'cover',
                       paddingBottom: '50px',
                       height: '80vh',
                       borderRadius: "10px",
                       paddingTop:'60px'
                   }}
                     >

                       <div className='uk-container'>
                      <div className='uk-margin-xlarge-top'>
                            <h1 className='uk-text-bold light header1 '>Our PortFolio</h1>
                            <h3 className='uk-text-bold header3 light  uk-margin-medium-top'>Construction </h3>
                            </div>

                            </div>

                     </div>




                     <div className='uk-container uk-margin-large-top'>

                        <div className='uk-text-center'>
                            <h2 className='header2'>Minna Boite Residence</h2>
                            
                            <h4 className='uk-text-bold blueText uk-text-center'> <span className=''> THE PROMISE   </span></h4>
                    </div>

                     </div>


                     <div className='uk-container uk-margin-top'>
                            <div className='uk-grid' data-uk-grid>
                                 <div className='uk-width-1-2@s'>
                                 <div
                                        style={{ backgroundImage: `url(${const1})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        paddingBottom: '50px',
                                        height: '50vh',
                                        borderRadius: "3px",
                                        paddingTop:'40px'
                                    }}
                                    >

                                    </div>
                                 </div>

                                 <div className='uk-width-1-2@s'>
                                 <div
                                        style={{ backgroundImage: `url(${const2})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        paddingBottom: '50px',
                                        height: '50vh',
                                        borderRadius: "3px",
                                        paddingTop:'40px'
                                    }}
                                    >

                                    </div>
                                 </div>
                            </div>



                            <div className='uk-grid' data-uk-grid>
                                 <div className='uk-width-1-2@s'>
                                 <div
                                        style={{ backgroundImage: `url(${const3})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        paddingBottom: '50px',
                                        height: '50vh',
                                        borderRadius: "3px",
                                        paddingTop:'40px'
                                    }}
                                    >

                                    </div>
                                 </div>

                                 <div className='uk-width-1-2@s'>
                                 <div
                                        style={{ backgroundImage: `url(${const4})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        paddingBottom: '50px',
                                        height: '50vh',
                                        borderRadius: "3px",
                                        paddingTop:'40px'
                                    }}
                                    >

                                    </div>
                                 </div>
                            </div>

                     </div>





                     
                     <div className='uk-container uk-margin-xlarge-top'>
                     <h4 className='uk-text-bold blueText uk-text-center'> <span className=''> THE PROCESS   </span></h4>
                            <div className='uk-grid uk-margin-top' data-uk-grid>
                                 <div className='uk-width-1-2@s'>
                                 <div
                                        style={{ backgroundImage: `url(${const5})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        paddingBottom: '50px',
                                        height: '50vh',
                                        borderRadius: "3px",
                                        paddingTop:'40px'
                                    }}
                                    >

                                    </div>
                                 </div>

                                 <div className='uk-width-1-2@s'>
                                 <div
                                        style={{ backgroundImage: `url(${const6})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        paddingBottom: '50px',
                                        height: '50vh',
                                        borderRadius: "3px",
                                        paddingTop:'40px'
                                    }}
                                    >

                                    </div>
                                 </div>
                            </div>



                            <div className='uk-grid' data-uk-grid>
                                 <div className='uk-width-1-2@s'>
                                 <div
                                        style={{ backgroundImage: `url(${const7})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        paddingBottom: '50px',
                                        height: '50vh',
                                        borderRadius: "3px",
                                        paddingTop:'40px'
                                    }}
                                    >

                                    </div>
                                 </div>

                                 <div className='uk-width-1-2@s'>
                                 <div
                                        style={{ backgroundImage: `url(${const8})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        paddingBottom: '50px',
                                        height: '50vh',
                                        borderRadius: "3px",
                                        paddingTop:'40px'
                                    }}
                                    >

                                    </div>
                                 </div>
                            </div>

                     </div>




            </div>


        </Layout>
)



export default constructionPort