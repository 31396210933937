import React from 'react'
import v8 from './../../../images/v8.jpg'
import barline from './../../../images/Bar_line.png'


const First_Pane = () => (
    <div>
    <div
       style={{ backgroundImage: `url(${v8})`,
       backgroundRepeat: 'no-repeat',
       backgroundSize: 'cover',
       paddingBottom: '50px',
       paddingTop: '100px',
       height: '100vh'
   }}

   >

       <div className='uk-container'>
      <div className='uk-grid' data-uk-grid>
                      
           <div className='uk-width-1-2@s uk-margin-medium-bottom'>
               <h1 className='light uk-text-bold uk-padding-small uk-margin-xlarge-top'>Our Works</h1>
               <p className=' light uk-padding-small'>The printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s the printing and typesetting industry</p>
            </div>
             <div className='uk-width-1-2@s'>

            </div>
            </div> 



        
      <div className='blueBg '>
        <div className=''>       
             <div className=''>

          <div class="uk-text-center" data-uk-grid>
                <div class="uk-width-1-6@s">
                </div>
                <div class="uk-width-4-6@s">
                    
                <div className='uk-text-center'>
                        <h3 className='light header3 uk-padding uk-text-bold'>We pride ourselves on our expertise as well as our company culture. 
                        No matter what place they work on, or what field they work in, 
                        every Toolbox Atelien shares a passion for excellence. </h3>
                    </div>
                </div>
                </div>

                <div class="uk-width-1-6@s">
                </div>
           </div>


          </div>

      </div>



      </div>

   </div>
</div>
  )


export default First_Pane