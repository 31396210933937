import React from 'react'
import projectBg from './../../../images/remodel3.png'

const First_pane = () =>  (
      <div>

              {/***DESKTOP VIEW***/}
    <div className="uk-visible@s"


style={{ backgroundImage: `url(${projectBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    paddingBottom: '50px',
    paddingTop: '100px',
    height: '90vh',
    marginTop:'-20px',
}}
    
   
       
    >
            
            <div className="uk-container uk-margin-xlarge-top" >
            <div className="uk-grid  " data-uk-grid>
                 <div className="uk-width-1-2@s shadow">
                      <div className=" " >
                          <h1 className="header1  " uk-scrollspy="cls: uk-animation-slide-left; repeat: true"> Our Recent Projects</h1>
                          <p>Living. Over. He god, living a. Creature that appearplace creeping upon. It you said seas every creeping and alife shall unto, years</p>
                           </div>

                      <div class="uk-flex uk-margin-large-top ">
                        
                      </div>
  
                 </div>

                 <div className="uk-width-1-2@s">
                     
                 </div>
            </div>
            </div>
        </div>





      </div>
  )


export default First_pane