import React from "react";
import check from  '../../../images/check.png'



const SixthPane = () => (
    
    <section className="grayBg">

         <div className="uk-container ">
             
             <div className="uk-grid" data-uk-grid>
                   <div className="uk-width-1-2@s">

                       <div className="uk-padding-small boxShadow">
                          <h3 className="uk-text-bold"> Mission</h3> 
                              <div className='uk-grid uk-margin-top uk-grid-collapse' data-uk-grid >
                                <div className='uk-width-1-6' uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                                    <img src = {check} className='checkIcon'/>
                                </div>

                                <div className='uk-width-5-6' uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                                <p className='moveIconLeft'>To be a leading brand and name synonymous with real estate services</p>
                                </div>
                              </div>


                              <div className='uk-grid uk-margin-top uk-grid-collapse' data-uk-grid >
                                <div className='uk-width-1-6' uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                                    <img src = {check} className='checkIcon'/>
                                </div>

                                <div className='uk-width-5-6' uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                                <p className='moveIconLeft'>To excel in becoming the industry's premier role model.</p>
                                </div>
    
                              </div>


                              
                              <div className='uk-grid uk-margin-top uk-grid-collapse' data-uk-grid >
                                <div className='uk-width-1-6' uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                                    <img src = {check} className='checkIcon'/>
                                </div>

                                <div className='uk-width-5-6' uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                                <p className='moveIconLeft'>To deliver best value, exceptional service and satisfaction for our clients and
associates.</p>
                                </div>
    
                              </div>



                              

                              
                              <div className='uk-grid uk-margin-top uk-grid-collapse' data-uk-grid >
                                <div className='uk-width-1-6' uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                                    <img src = {check} className='checkIcon'/>
                                </div>

                                <div className='uk-width-5-6' uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                                <p className='moveIconLeft'>To embrace changes and innovation for continual improvements in all our
business solutions.</p>
                                </div>
    
                              </div>



                                     
                              <div className='uk-grid uk-margin-top uk-grid-collapse' data-uk-grid >
                                <div className='uk-width-1-6' uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                                    <img src = {check} className='checkIcon'/>
                                </div>

                                <div className='uk-width-5-6' uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                                <p className='moveIconLeft'>To cultivate a positive, energized and productive working atmosphere.</p>
                                </div>
    
                              </div>


                                                
                              <div className='uk-grid uk-margin-top uk-grid-collapse' data-uk-grid >
                                <div className='uk-width-1-6' uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                                    <img src = {check} className='checkIcon'/>
                                </div>

                                <div className='uk-width-5-6' uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                                <p className='moveIconLeft'> To transform and enrich the lifestyle of the employees and agents.</p>
                                </div>
    
                              </div>




                         </div>
                   </div>

                   <div className="uk-width-1-2@s">
                   <div className="uk-padding-small">
                          <h3 className="uk-text-bold"> Vission</h3> 
                          
                          <div className="line"></div>
                          <p className="uk-margin-medium-top">To become the foremost and biggest
player in the real estate development
industry in Nigeria and Africa, and a
partner of choice for those who believe
that smart, thoughtful real estate
development changes communities for
the better. We do so while maintaining our
authentic level of service, founded on the
basic core values of integrity and
partnership and ultimately providing top
quality, affordable and exquisite housing
solutions to our esteemed clientele while
also giving them value for money</p>
                         </div>
                   </div>


                 
             </div>
          
         </div>
                    
</section>

) 


export default SixthPane;