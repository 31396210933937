import React from 'react'
import ConsBg from './../../../images/consBg.jpg'

import barline from './../../../images/Bar_line.png'


const FirstPane = () => (

    <div>
            <div
            style={{ backgroundImage: `url(${ConsBg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            paddingBottom: '50px',
            paddingTop: '100px',
            height: '70vh'
        }}

        >

            <div className='uk-container'>
           <div className='uk-grid' data-uk-grid>
                           
                <div className='uk-width-1-2@s'>
                    <h1 className='light uk-text-bold uk-padding-small uk-margin-xlarge-top'>Construction Company with over 10 years Experience</h1>
                    </div>
                  <div className='uk-width-1-2@s'>

                 </div>
                 </div> 
           </div>
     
        </div>
        </div>
)

export default FirstPane