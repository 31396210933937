import React from 'react'
import combined from './../../../images/combined.png'

import gray_dots from './../../../images/gray_dots.jpg'
import vc6 from './../../../images/vc6.jpg'

import { Link } from 'react-router-dom'

const Second_Pane = () => (
  
    <div className='uk-margin-large-top'>
        <div className='uk-container'>
            <div className='uk-grid' data-uk-grid>
            <div className='uk-width-1-2@s'>
               <img src={combined} />
            </div>

            <div className='uk-width-1-2@s uk-padding' > 
                <div className='uk-margin-medium-top '>
                  <h1 className='uk-text-bold'>Build your investment portfolio with us</h1>
                  <p> Over the years we continue to create new projects hence offering 
                      extensive investment opportunities. And with our proven investment
                       model and investment bouquets, anyone without expertise in the 
                       real estate sector can invest without lifting a finger; we take
                        care of every aspect of the project, so investors can relax and 
                        watch their investments work for them, ultimately delivering great returns on project completion.</p>


                     <p className='uk-margin-medium-top'>Investment in any of our projects enables us to scale our projects of building 
                     great homes and communities in Nigeria and across Africa in the future, hence allowing us to make ambitious
                      endeavours to build luxurious communities that are affordable. We have raised the bar and set new standards
                       for home design and property development while creating new communities that benefit Nigerians at home and 
                       in the diaspora.</p>                </div>
                
                <div className='uk-grid uk-margin-medium-top ' data-uk-grid>
                    <div className='uk-width-1-2'>
                    <Link to="/contact"><div class="btn uk-text-center " uk-scrollspy="cls: uk-animation-slide-left; repeat: true">Book Consultaion</div> </Link>
               
                    </div>
                    <div className='uk-width-1-2'>

                    </div>
                 </div>    
             </div>
        </div>
        </div>



        

       {/*Next pane*/}
       <div className="uk-margin-xlarge-top">
                 <div className=""
                 style={{ backgroundImage: `url(${gray_dots})`,
                 backgroundRepeat: 'no-repeat',
                 backgroundSize: 'cover',
                 paddingBottom: '50px',
               
                 height: '70vh'
                 }}>
                <div className="uk-grid" data-uk-grid>
                     <div className="uk-width-1-2@s">
                       <div className="uk-container uk-margin-large-top ">

                           <div className="uk-grid" data-uk-grid>
                              <div className="uk-width-1-5">

                              </div>

                              <div className="uk-width-3-5  uk-margin-large-top">
                              <h2 className="header2 uk-text-bold uk-text-left light ">Let’s sit down
                               and talk about
                                 your new home</h2> </div>


                              <div className="uk-width-1-5">

                              </div>


                           </div>


                           <div className="uk-grid" data-uk-grid>
                              <div className="uk-width-1-5 ">

                              </div>


                              <div className="uk-width-3-5  uk-margin-large-top">
                              <Link to="/contact" className='ctaBtn'>Book Appointment
                           </Link>
                              </div>


                              <div className="uk-width-1-5">

                              </div>


                           </div>



                      
                           </div>
                     </div>


                     <div className="uk-width-1-2@s">
                       <div
                         style={{ backgroundImage: `url(${vc6})`,
                         backgroundRepeat: 'no-repeat',
                         backgroundSize: 'cover',
                        
                       
                         height: '70vh'
                         }}
                       
                       >



                        </div>
                     </div>
                </div>
                </div>
             </div>





















    </div>
  )


export default Second_Pane