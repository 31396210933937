import react from 'react'
import v8 from './../../../images/v8.jpg'
import boiteIntro from './../../../images/boiteIntro.png'
import barline from './../../../images/Bar_line.png'
import boiteHero from './../../../images/boiteHero.jpg'

import boite2 from './../../../images/boite2.jpg'
import v4 from './../../../images/v4.png'

import gray_dots from './../../../images/gray_dots.jpg'

import {Link } from 'react-router-dom'


const First_Pane =() =>{
    return (

        <div>
        <div
        
            style={{ 
            backgroundImage: `-webkit-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${boiteHero})`,
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${boiteHero})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            paddingBottom: '50px',
            paddingTop: '100px',
            height: '100vh'
            }}
        
        
        >


            <div className='uk-container'>

                <div className='uk-grid' data-uk-grid>
                    <div className='uk-width-1-2@s'>
                        
               <h1 className='light header1 uk-margin-xlarge-top' uk-scrollspy="cls: uk-animation-slide-right; repeat: true">Boite 311 Residence</h1>
               <h5 className='light uk-margin-medium-top' uk-scrollspy="cls: uk-animation-fade; repeat: true">Living. Over. He god, living a. Creature that appearplace creeping upon.
                  It you said seas  every creeping and alife shall unto, years </h5>
                    </div>

                    <div className='uk-width-1-2@s'>

                    </div>
                </div>

            </div>
           
        </div>

                <div className='uk-container'>
                                
                                      

                </div>


                

       {/*Next pane*/}
             <div className="" style={{backgroundColor:'#3FC6EB', padding:'40px'}}>
                 <div className="uk-container uk-margin-large-top uk-margin-large-bottom"

                 style={{ backgroundImage: `url(${gray_dots})`,
                 backgroundRepeat: 'no-repeat',
                 backgroundSize: 'cover',
                 paddingBottom: '10px',
               
                 height: '70vh'
                 }}
                 
                 >
                <div className="uk-grid" data-uk-grid>
                     <div className="uk-width-1-2@s">
                       <div className="uk-container uk-margin-large-top ">

                           <div className="uk-grid" data-uk-grid>
                              <div className="uk-width-1-5@s">

                              </div>


                              <div className="uk-width-3-5@s">
                              <h1 className="uk-text-bold uk-text-left light uk-margin-large-top">Let’s sit down
                               and talk about
                                 your new home</h1>
                              </div>


                              <div className="uk-width-1-5@s">

                              </div>

                           </div>

                           <div className="uk-grid" data-uk-grid>
                              <div className="uk-width-1-5">

                              </div>


                              <div className="uk-width-3-5">
                            <Link to="/contact"><p className="header1 uk-text-left light uk-padding">Contact Us
                               </p>  </Link>
                              </div>


                              <div className="uk-width-1-5">

                              </div>


                           </div>

                           </div>
                     </div>


                     <div className="uk-width-1-2@s">
                       <div>

                           <img src= {v4} />

                        </div>
                     </div>
                </div>
                </div>
             </div>
</div>
    )
}




export default First_Pane