import React from 'react'
import { Link } from 'react-router-dom'
import Third_pane from '../../ValleyCourt2/Blocks/Third_pane'
import boiteAzureBg from './../../../images/boiteAzureBg.jpg'
import gray_dots from './../../../images/gray_dots.jpg'

import homebox from './../../../images/home-box.png'


import new1 from './../../../images/new1.png'

import new2 from './../../../images/new2.jpg'

import new3 from './../../../images/new3.jpg'

import new4 from './../../../images/new4.jpg'

import new5 from './../../../images/new5.jpg'

import new6 from './../../../images/new6.jpg'

import new7 from './../../../images/new7.jpg'

import new8 from './../../../images/new8.jpg'




const First_Pane = () => 
(
    <div>
        
        <div 
            style={{ backgroundImage: `url(${boiteAzureBg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            paddingBottom: '50px',
            paddingTop: '100px',
            height: '95vh'
        }}

        >
            <div className='uk-container uk-margin-xlarge-top'>
                <div className='uk-grid'>
                    <div className='uk-width-3-5@s'>

                    <h1 className='light uk-text-bold header1'>Boite Azure </h1>
                    <h5 className='light'>Over the years, we have built a reputation across Nigeria as 
                        the foremost property development company solving the problem 
                        of housing deficits in Africa and providing the most reliable 
                        and quality real estate services on the continent. </h5>
                    </div>

                    <div className='uk-width-2-5@s'>

                    </div>
                </div>


            </div>
           
          
        </div>

                   
                   
                   <div
                    style={{ backgroundImage: `url(${gray_dots})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    paddingBottom: '50px',
                    paddingTop: '100px',
                    height: '100vh'
                }} >


                       <div className='uk-container'>
                           <div className='uk-grid uk-grid-collapse' data-uk-grid>
                                <div className='uk-width-1-2@s'>
                                    <div 
                                    style={{ backgroundImage: `url(${new1})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    paddingBottom: '50px',
                                    paddingTop: '100px',
                                    height: '75vh'
                                }}
                                    
                                    >

                                    </div>
                                </div>

                                <div className='uk-width-1-2@s'>
                                    <div 
                                         style={{ backgroundImage: `url(${homebox})`,
                                         backgroundRepeat: 'no-repeat',
                                         backgroundSize: 'cover',
                                         paddingBottom: '50px',
                                         paddingTop: '100px',
                                         height: '75vh'
                                     }} >

                                         <div className='uk-padding'>
                                             <p className='light'>Over the years, we have built a reputation 
                                             across Nigeria as 
                                               the foremost property development company solving the problem 
                                                of housing deficits in Africa and providing the most reliable 
                                               and quality real estate services on the continent</p>

                                               <p className='light'>Over the years, we have built a reputation 
                                               across Nigeria as 
                                               the foremost property development company solving the problem 
                                                of housing deficits in Africa and providing the most reliable 
                                               and quality real estate services on the continent</p>
                                         </div>

                                    </div>
                                </div>
                           </div>
                       </div>
                   </div>


                   <Third_pane />



                   <div className='container uk-margin-large-top'>
                            
                            <div className='uk-margin-top uk-margin-bottom'>
                                <h2 className='uk-text-center uk-text-bold'>Boite Azure Realizations</h2>
                            </div>

                       <div className='row'>
                           <div className='col-sm-8'>
                                   <div
                                   style={{ backgroundImage: `url(${new2})`,
                                   backgroundRepeat: 'no-repeat',
                                   backgroundSize: 'cover',
                                   paddingBottom: '50px',
                                   paddingTop: '100px',
                                   height: '60vh'
                               }} >

                                </div>
                               </div>

                           <div className='col-sm-4'>
                                     <div
                                     style={{ backgroundImage: `url(${new2})`,
                                     backgroundRepeat: 'no-repeat',
                                     backgroundSize: 'cover',
                                     paddingBottom: '50px',
                                     paddingTop: '100px',
                                     height: '60vh'
                                 }}>
                           </div>
                                  </div>
                       </div>


                        <div className='uk-margin-small-top'>
                       <div className='row'>
                            <div className='col-sm-4'>
                                <div
                                    style={{ backgroundImage: `url(${new3})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    paddingBottom: '50px',
                                    paddingTop: '100px',
                                    height: '60vh'
                                }}
                                >

                                </div>
                            </div>

                            <div className='col-sm-4'>
                                <div
                                   style={{ backgroundImage: `url(${new4})`,
                                   backgroundRepeat: 'no-repeat',
                                   backgroundSize: 'cover',
                                   paddingBottom: '50px',
                                   paddingTop: '100px',
                                   height: '60vh'
                               }}
                                >

                                </div>
                            </div>


                            <div className='col-sm-4'>
                                <div
                                  style={{ backgroundImage: `url(${new5})`,
                                  backgroundRepeat: 'no-repeat',
                                  backgroundSize: 'cover',
                                  paddingBottom: '50px',
                                  paddingTop: '100px',
                                  height: '60vh'
                              }}
                                
                                >

                                </div>
                            </div>
                       </div>
                       </div>



                      <div className='uk-margin-small-top'>
                       <div className='row'>
                            <div className='col-sm-6'>
                                 <div
                                      style={{ backgroundImage: `url(${new6})`,
                                      backgroundRepeat: 'no-repeat',
                                      backgroundSize: 'cover',
                                      paddingBottom: '50px',
                                      paddingTop: '100px',
                                      height: '60vh'
                                  }}
                                 >

                                 </div>
                            </div>


                            <div className='col-sm-6'>
                            <div
                                      style={{ backgroundImage: `url(${new7})`,
                                      backgroundRepeat: 'no-repeat',
                                      backgroundSize: 'cover',
                                      paddingBottom: '50px',
                                      paddingTop: '100px',
                                      height: '60vh'
                                  }}
                                 >

                                 </div>
                            </div>
                       </div>
                       </div>

                   </div>




       {/*Next pane*/}
       <div className="uk-margin-large-top">
                 <div className=""
                 style={{ backgroundImage: `url(${gray_dots})`,
                 backgroundRepeat: 'no-repeat',
                 backgroundSize: 'cover',
                 paddingBottom: '50px',
               
                 height: '70vh'
                 }}>
                <div className="uk-grid" data-uk-grid>
                     <div className="uk-width-1-2@s">
                       <div className="uk-container uk-margin-large-top ">

                           <div className="uk-grid" data-uk-grid>
                              <div className="uk-width-1-5@s">

                              </div>


                              <div className="uk-width-3-5@s">
                              <h1 className="uk-text-bold uk-text-left light uk-padding">Let’s sit down
                               and talk about
                                 your new home</h1>
                              </div>


                              <div className="uk-width-1-5@s">

                              </div>


                           </div>




                           <div className="uk-grid" data-uk-grid>
                              <div className="uk-width-1-5">

                              </div>


                              <div className="uk-width-3-5">
                            <Link to="/contact"><p className="header1 uk-text-left light uk-padding">Contact Us
                               </p>  </Link>
                              </div>


                              <div className="uk-width-1-5">

                              </div>


                           </div>



                      
                           </div>
                     </div>


                     <div className="uk-width-1-2@s">
                       <div
                         style={{ backgroundImage: `url(${new7})`,
                         backgroundRepeat: 'no-repeat',
                         backgroundSize: 'cover',
                        
                       
                         height: '70vh'
                         }}
                       
                       >



                        </div>
                     </div>
                </div>
                </div>
             </div>



        


      


        






        
    </div>
  )


export default First_Pane