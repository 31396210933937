import React from 'react'
import cutImg from "./../../../images/cutImg.png"
import homeIcon1 from "./../../../images/home-icon-1.png"
import homeIcon2 from "./../../../images/home-icon-2.png"
import homebox from './../../../images/home-box.png'
import gray_dots from './../../../images/gray_dots.jpg'
import boite6 from './../../../images/boite6.jpg'


const Second_Pane = () => 
   (
    <div>
          <div
              style={{ backgroundImage: `url(${gray_dots})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              paddingBottom: '50px',
              paddingTop: '100px',
              height: '90vh'
          }}
          
          
          >

           <div className='uk-container'>


            <div className='uk-grid uk-grid-collapse' data-uk-grid>

                 <div className='uk-width-1-2@s'>
                     <div
                       style={{ backgroundImage: `url(${homebox})`,
                       backgroundRepeat: 'no-repeat',
                       backgroundSize: 'cover',
                       paddingBottom: '20px',
                       paddingTop: '40px',
                       height: '70vh'
                   }}
                     >

                 <div className='uk-padding '>
                     <h2 className='uk-text-center light uk-text-bold' uk-scrollspy="cls: uk-animation-slide-top; repeat: true">Built to last</h2>
                    <p className='uk-text-center uk-padding-small light'uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true">
                    We build quality homes and we don not compromise on quality hence having the standards high while keeping the price 
                    friendly.</p>
                       <h4 className='uk-text-bold light uk-text-center'>Committed to only High Quality Service</h4>
                 

                 <div className='uk-flex  uk-margin-medium-top'>
                                       <div className=''>
                                         <img src= {homeIcon1} className="homeIcon1" uk-scrollspy="cls: uk-animation-slide-left; repeat: true"/>
                                       </div>
                                       <div className='uk-margin-lefts'>
                                      <h5 className=' uk-text-left light' uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                                         Building quality standards</h5>
                                       </div>
                     </div>


                     <div className='uk-flex uk-margin-top'>
                                       <div className=''>
                                         <img src= {homeIcon2} className="homeIcon1" uk-scrollspy="cls: uk-animation-slide-left; repeat: true"/>
                                       </div>
                                       <div className=''>
                                      <h5 className=' light uk-margin-small-left' uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                                         Design & Build Package</h5>
                                       </div>
                                   </div>

                     </div>
                 <div>

                 </div>


                     </div>
                 </div>

                 <div className='uk-width-1-2@s'>
                     
                          <div
                          
                          style={{ backgroundImage: `url(${boite6})`,
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'cover',
                          paddingBottom: '50px',
                          paddingTop: '100px',
                          height: '70vh'
                      }}>




                          </div>




                 </div>

             </div>
 </div>


          </div>




















    </div>
  )


export default Second_Pane