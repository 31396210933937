import React from 'react'
import v8 from './../../../images/v8.jpg';
import barline from './../../../images/Bar_line.png'

const First_Pane = () => {
  return (
    <div className=''>
           <div
        
        style={{ 
        backgroundImage: `-webkit-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${v8})`,
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${v8})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        paddingBottom: '50px',
        paddingTop: '100px',
        height: '100vh'
        }}
    
    
    >


        <div className='uk-container'>

            <div className='uk-grid' data-uk-grid>
                <div className='uk-width-1-2@s'>
                    
           <h1 className='light header1 uk-margin-xlarge-top' uk-scrollspy="cls: uk-animation-slide-right; repeat: true">Valley Court</h1>
      
           <h5 className='light uk-margin-medium-top' uk-scrollspy="cls: uk-animation-fade; repeat: true">Living. Over. He god, living a. Creature that appearplace creeping upon.
              It you said seas  every creeping and alife shall unto, years </h5>
                </div>

                <div className='uk-width-1-2@s'>

                </div>
            </div>

        </div>
       
    </div>
    </div>
  )
}

export default First_Pane