import React from 'react'
import First_Pane from './Blocks/First_Pane'
import Layout from '../Layout'
import Second_Pane from './Blocks/Second_Pane'
import Third_Pane from './Blocks/Third_Pane'

export default function investment() {

    return (
    <Layout>
       <First_Pane />
       <Second_Pane />
       <Third_Pane />
 

    </Layout>
)

};