import React from 'react'

const Second_Pane = () => 
 (
    <div>

        <div className='uk-container uk-margin-large-top'>
              <div className='uk-grid uk-padding'>
                   <div className='uk-width-1-3@s'>
                       <div className='shadow'>
                           <h6 className='uk-text-bold'> START A RELATIONSHIP</h6>
                           <h2 className='uk-margin-remove-top uk-text-bold'>New Business</h2>
                        </div>
                   </div>

                   <div className='uk-width-1-3@s uk-padding'>
                       <h6>HAVE A QUESTION</h6>
                       <h4 className='uk-margin-remove-top uk-text-bold'>hello@toolboxateliers</h4>
                   </div>

                   <div className='uk-width-1-3@s'>
                   <h6>HAVE A QUESTION</h6>
                       <h4 className='uk-margin-remove-top uk-text-bold'>hello@toolboxateliers</h4>
                  
                   </div>
              </div>
        </div>


    </div>
  )


export default Second_Pane