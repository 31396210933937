import React from 'react'
import gray_dots from './../../../images/gray_dots.jpg'
import vc6 from './../../../images/vc6.jpg'
import {Link} from 'react-router-dom'

const Fourth_Pane = () => 
  (
    <div className=''>
        


       {/*Next pane*/}
       <div className="">
                 <div className=""
                 style={{ backgroundImage: `url(${gray_dots})`,
                 backgroundRepeat: 'no-repeat',
                 backgroundSize: 'cover',
                 paddingBottom: '50px',
               
                 height: '70vh'
                 }}>
                <div className="uk-grid" data-uk-grid>
                     <div className="uk-width-1-2@s">
                       <div className="uk-container uk-margin-large-top ">

                           <div className="uk-grid" data-uk-grid>
                              <div className="uk-width-1-5">

                              </div>


                              <div className="uk-width-3-5">
                              <h2 className="header2 uk-text-bold uk-text-left light ">Let’s sit down
                               and talk about
                                 your new home</h2>
                              </div>


                              <div className="uk-width-1-5">

                              </div>


                           </div>




                           <div className="uk-grid" data-uk-grid>
                              <div className="uk-width-1-5">

                              </div>


                              <div className="uk-width-3-5">
                            <Link to="/contact"><p className="header1 uk-text-left light uk-padding">Contact Us
                               </p>  </Link>
                              </div>


                              <div className="uk-width-1-5">

                              </div>


                           </div>



                      
                           </div>
                     </div>


                     <div className="uk-width-1-2@s">
                       <div
                         style={{ backgroundImage: `url(${vc6})`,
                         backgroundRepeat: 'no-repeat',
                         backgroundSize: 'cover',
                        
                       
                         height: '70vh'
                         }}
                       
                       >



                        </div>
                     </div>
                </div>
                </div>
             </div>
    </div>
  )


export default Fourth_Pane