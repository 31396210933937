import react from 'react'
import {Link}  from 'react-router-dom'

import vc4 from './../../../images/vc4.jpg'
import vc2 from './../../../images/vc2.jpg'
import vc5 from './../../../images/vc5.jpg'
import vc6 from './../../../images/vc6.jpg'
import vc7 from './../../../images/vc7.jpg'
import vc1 from './../../../images/vc1.jpg'
import valleyPort9 from './../../../images/valleyPort9.png'
import quality from './../../../images/quality.png'
import gray_dots from './../../../images/gray_dots.jpg'



const Third_Pane = () => (
    <div className='uk-margin-xlarge-top'>
           <div className='uk-container'>
           <div class="row ">
            <div class="col-sm " >
            <div className="" 
                    style={{ backgroundImage: `url(${vc7})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    paddingBottom: '50px',
                    paddingTop: '100px',
                    height: '30vh'
                    }}
                    
            >
         
             </div>
             <div className="uk-margin-small-top" 
               
               style={{ backgroundImage: `url(${vc5})`,
               backgroundRepeat: 'no-repeat',
               backgroundSize: 'cover',
               paddingBottom: '50px',
               paddingTop: '100px',
               height: '30vh'
               }}>
             </div>


             <div className="uk-margin-small-top" 
               
               style={{ backgroundColor: '#3bcbee',
               backgroundRepeat: 'no-repeat',
               backgroundSize: 'cover',
               paddingBottom: '30px',
              
               height: '28vh'
               }}>

                   <div className="uk-padding">
                       <img src = {quality}  className="quality"/>
                       <h4 className="light">We don't compromise quality</h4>
                   </div>
             </div>




            </div>

            <div class="col-sm-6 " >
            <div className="" 
            
            style={{ backgroundImage: `url(${vc2})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            paddingBottom: '50px',
            paddingTop: '100px',
            height: '45vh'
            }}
            
            ></div>

            <div className="uk-margin-small-top" 
            
            style={{ backgroundImage: `url(${vc6})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            paddingBottom: '50px',
            paddingTop: '100px',
            height: '45vh'
            }}
            
            ></div>

            




            </div>

        <div class="col-sm" >

            <div className=""
                style={{ backgroundImage: `url(${valleyPort9})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                paddingBottom: '50px',
                paddingTop: '100px',
                height: '60vh'
                }}
            >
               </div>

               <div className="uk-margin-small-top"
                style={{ backgroundImage: `url(${vc1})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                paddingBottom: '50px',
                paddingTop: '100px',
                height: '30vh'
                }}
            >
               </div>


        
        </div>


        
  </div>
           </div>





       {/*Next pane*/}
       <div className="uk-margin-xlarge-top">
                 <div className=""
                 style={{ backgroundImage: `url(${gray_dots})`,
                 backgroundRepeat: 'no-repeat',
                 backgroundSize: 'cover',
                 paddingBottom: '50px',
               
                 height: '70vh'
                 }}>
                <div className="uk-grid" data-uk-grid>
                     <div className="uk-width-1-2@s">
                       <div className="uk-container uk-margin-large-top ">

                           <div className="uk-grid" data-uk-grid>
                              <div className="uk-width-1-5@s">

                              </div>


                              <div className="uk-width-3-5@s">
                              <h1 className="uk-text-bold uk-text-left light uk-padding">Let’s sit down
                               and talk about
                                 your new home</h1>
                              </div>


                              <div className="uk-width-1-5@s">

                              </div>


                           </div>




                           <div className="uk-grid" data-uk-grid>
                              <div className="uk-width-1-5">

                              </div>


                              <div className="uk-width-3-5">
                            <Link to="/contact"><p className="header1 uk-text-left light uk-padding">Contact Us
                               </p>  </Link>
                              </div>


                              <div className="uk-width-1-5">

                              </div>


                           </div>



                      
                           </div>
                     </div>


                     <div className="uk-width-1-2@s">
                       <div
                         style={{ backgroundImage: `url(${vc6})`,
                         backgroundRepeat: 'no-repeat',
                         backgroundSize: 'cover',
                        
                       
                         height: '70vh'
                         }}
                       
                       >



                        </div>
                     </div>
                </div>
                </div>
             </div>






    </div>
)



export default Third_Pane