import React from 'react'
import Layout from '../Layout'
import First_pane from './Blocks/First_pane'

 
export default  function Projects ()  {
return(
    <Layout>
        <First_pane />
    </Layout>
);
  }

