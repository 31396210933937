import React from "react";
import vNight2 from './../../../images/boite5.jpg'
import historyIcon from './../../../images/historyIcon.png'
import gray_dots from './../../../images/gray_dots.jpg'
import homebox from './../../../images/home-box.png'



const ThirdPane = () => (

    <div className=""
    
    
    style={{ backgroundImage: `url(${gray_dots})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    paddingBottom: '50px',
    height: '100vh',
    borderRadius: "10px"
}}
    
    
    
    
    >

                   <div className="uk-container ">
                          <div className="uk-grid uk-grid-collapse uk-margin-large-top " data-uk-grid>
                              <div className="uk-width-1-2@s ">
                                  <div className="uk-padding"
                                  
                                  style={{ backgroundImage: `url(${homebox})`,
                                  backgroundRepeat: 'no-repeat',
                                  backgroundSize: 'cover',
                                  paddingBottom: '50px',
                                  paddingTop: '20px',
                                  height: '72vh'
                              }}
                                  
                                  
                                  
                                  
                                  >
                                       <div className="uk-grid uk-grid-collapse" data-uk-grid>
                                            <div className="uk-width-1-5" uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                                              <img src= {historyIcon} className="historyIcon uk-margin-top" />
                                            </div>

                                            <div className="uk-width-4-5">
                                            <p className='uk-text-light uk-margin-small-bottom uk-text-small uk-text-left' uk-scrollspy="cls: uk-animation-slide-left; repeat: true">OUR STORY</p>
                                  <h3 className="uk-text-bold header3 light uk-margin-remove-top" uk-scrollspy="cls: uk-animation-fade; repeat: true">The Company</h3>
         
                                           </div>
                                       </div>
                                                            <p className="uk-margin-top  light" uk-scrollspy="cls: uk-animation-fade; repeat: true" >We also partner with local and foreign investors and landowners to jointly develop
                                    properties based on agreed equity and contribution.Our experience in architecture, project 
                                    management and construction has enabled us to deliver properties that are beautiful and budget
                                   friendly using the most state of the art technology to achieve desired quality</p>


                                    <p className="uk-margin-top light" uk-scrollspy="cls: uk-animation-fade; repeat: true" >
                          
                    The Company was established to develop well designed,
                competitively Tpriced real estate in Nigeria. We develop houses for sale
                 either off plan or completed, in high-yielding locations, with basic 
                 infrastructure to conceptualize and construct commercial or residential
                  properties that yield healthy return per square meter.
                                    </p>

                                   </div>
                              </div>  

                             <div className="uk-width-1-2@s " uk-scrollspy="cls: uk-animation-fade; repeat: true">
                                  <div    style={{ backgroundImage: `url(${vNight2})`,
                                   backgroundRepeat: 'no-repeat',
                                   backgroundSize: 'cover',
                                   paddingBottom: '50px',
                                   paddingTop: '100px',
                                   height: '72vh'
        }}>

                                  </div>
                             </div>
                          </div>
                   </div>

     </div>
    
)



export  default  ThirdPane