import React from 'react'
import barline from './../../../images/Bar_line.png'
import remodellingBg from './../../../images/remodellingBg.jpg'

const First_Pane = () => (
    
    <div>
    <div
       style={{ backgroundImage: `url(${remodellingBg})`,
       backgroundRepeat: 'no-repeat',
       backgroundSize: 'cover',
       paddingBottom: '50px',
       paddingTop: '100px',
       height: '85vh'
   }}

   >

       <div className='uk-container'>
      <div className='uk-grid' data-uk-grid>
                      
           <div className='uk-width-1-2@s'>
               <h1 className='light uk-text-bold uk-padding-small uk-margin-xlarge-top'uk-scrollspy="cls: uk-animation-slide-right; repeat: true">Remodelling</h1>
               <img src= {barline} uk-scrollspy="cls: uk-animation-slide-left; repeat: true" className='uk-padding-small' uk-scrollspy="cls: uk-animation-slide-left; repeat: true"/>
               <p className=' light uk-padding-small'uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                 Whether you’re looking to increase the property value of your home   ahead of a sale or closing
                  on a house you can’t wait to make your own, chances are there’s a renovation or remodeling
                   project in your future. </p>
                   <p className='light   uk-padding-small'>We prodive remodelling solutions that are modern and eco friendly.</p>
            </div>
             <div className='uk-width-1-2@s'>

            </div>
            </div> 
      </div>

   </div>
</div>



  )


export default First_Pane