import  react from 'react';

import teamHeader from './../../../images/teamHeader.jpg'

import team1 from './../../../images/team1.png'
import briefCase from './../../../images/briefCase.png'


import Layout from './../../Layout/layout'




export default function() {
    return(
        <Layout>
        <div
        
        style={{ 
        backgroundImage: `-webkit-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${teamHeader})`,
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${teamHeader})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        paddingBottom: '50px',
        paddingTop: '100px',
        height: '75vh'
        }}


    
    >


<div className='uk-container '>
             <div className='uk-grid' data-uk-grid>
                 <div className='uk-width-1-3@s uk-margin-xlarge-top'>
                    <h1 className='light uk-text-bold'>Arc. Mamud Imam </h1>
                    <h3 className='light uk-margin-remove-top'>Chief Executive Officer</h3>

                    <div className='uk-margin-top'>
                    <a href="" class="uk-icon-button uk-margin-small-right" uk-icon="twitter"></a>
                        <a href="" class="uk-icon-button  uk-margin-small-right" uk-icon="facebook"></a>
                        <a href="" class="uk-icon-button" uk-icon="youtube"></a>
                        </div>

                 </div>

                 <div className='uk-width-1-3@s'>

                 </div>

                 <div className='uk-width-1-3@s'>
                     <div className='teamBg'>
                     <img src= {team1} />
                     </div>
                 </div>
             </div>
        </div>


      


    </div>



        <div className='uk-container uk-margin-large-top'>
        <div className='uk-grid' data-uk-grid>
            <div className='uk-width-3-5@s'>
               <p className='uk-margin-top'> A graduate of Architecture from the Federal University of Technology 
                   Minna, Mamud possesses over a decade' s wor th of industry experience.</p>

                <p className='uk-margin-top'>He has developed big diversity of project swith national and international
                     clients, which has gained him vast experience in every architectural
                      phase In different areas. He is especially adept at managing complex 
                      projects and diverse teams.A brilliant foreman, Mamud has a strong 
                      vision for achieving unmatched success.</p>

                <p className='uk-margin-top'>Mamud is result-oriented and consistent in his pursuit to achieving 
                    professional excellence.
                </p>

              

            </div>


            <div className='uk-width-2-5@s uk-margin-large-top '>
               
               <div className='teamDesc'>
            <div class="uk-flex ">
                <div class="uk-card "><img src ={briefCase} className="briefImg"/></div>
                <div class="uk-card uk-margin-left"><h4 className='header4 uk-text-bold'> Architecture, Building & Construction</h4></div>
            </div>


            <div class="uk-flex">
                <div class="uk-card "><a href="" uk-icon="icon: location"></a></div>
                <div class="uk-card uk-margin-left"><h5 className=''>Abuja, Nigeria</h5></div>
            </div>
            </div>

               
               </div>
        </div>


        </div>




        <div className='uk-container uk-margin-large-top'>
            <div className='uk-grid' data-uk-grid>
                 <div className='uk-width-1-2@s'>
                   <div>
                       <h1 className=''>FUN <span className=' uk-text-bold'>FACT</span></h1>
                       <hr className='line'></hr>

                       <p>Mamud loves to play volley ball and wears the caftan on Fridays</p>
                   </div>
                 </div>

                 <div className='uk-width-1-2@s'>
                 
                 </div>
            </div>
        </div>


    </Layout>
    )
}