import React from "react";
import { Link } from "react-router-dom";
import toolboxvid from './../../../images/toolboxvid.mp4'




const Second_Pane = () => (
     <div className="uk-container uk-margin-top uk-margin-large-bottom">
            <div className="uk-grid" data-uk-grid>
                <div className="uk-width-3-5@s uk-padding uk-margin-large-top">
                    <h1 className="header3"  uk-scrollspy="cls: uk-animation-slide-left; repeat: true">We build modern, environmentally conscious homes.
</h1>
                    <p className="uk-text-small uk-text-bold"  uk-scrollspy="cls: uk-animation-fade; repeat: true">Toolbox atelier is conceived and built on the foundation of excellence, trust,integrity,
                          </p>

                              <div className="uk-grid">
                                   <div className="uk-width-1-2@s">
                                   <p className="uk-text-small uk-margin-small-top" uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                                     We aim to not only build structures,but also make living space ecological friendly and smart
                         with modern technology to provide incredible living  
                          </p>
                                   </div>

                                   <div className="uk-width-1-2@s">
                                   <p className="uk-text-small uk-margin-small-top" uk-scrollspy="cls: uk-animation-slide-right; repeat: true">  experience to our happy customers 
                          We are poised to explore more avenues of enhancing our services and making our clients even happier 
                          </p>
                                   </div>
                              </div>
                    
                </div>

                <div className="uk-width-2-5@s uk-margin-large-top uk-padding">
                  <video src={toolboxvid} width="600" height="300" controls="controls" autoplay="true"  uk-scrollspy="cls: uk-animation-slide-right; repeat: true"/>

                 
               
                </div>
            </div>
     </div>
)



export default Second_Pane;