import React from 'react'
import valleySide from './../../../images/valleySide.png'
import gray_dots from './../../../images/gray_dots.jpg'

import valley1 from './../../../images/valley1.png'

import homebox from './../../../images/home-box.png'


const Second_Pane = () =>{
    return(
      <div>
      <div 
      style={{ backgroundImage: `url(${gray_dots})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      paddingBottom: '50px',
      paddingTop: '100px',
      height: '90vh'
  }}

  >





<div className='uk-container '>
         <div className='uk-grid-collapse uk-grid' data-uk-grid>
              <div className='uk-width-1-2@s'>
                            <div className='uk-padding'
                    style={{ backgroundImage: `url(${homebox})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    paddingBottom: '50px',
                    paddingTop: '100px',
                    height: '70vh'
                }}>

                  <p className='light'>Each apartment is equipped with contemporary fixtures and  
                       fittings which  will  raise the bar when  it comes  to modern 
                        living in Abuja. In addition, these apartments Are comprised 
                        of spacious 3 Units of 2 Bedroom Apartments and 3 Units of 3
                         Bedroom  Apartments with  BQ.</p>

                  <p className='light'>Close to both the heart of the city and Gwarimpa,  
       providing the sort of ideal locations which are in high demand. Valley Court 2 is
        perfect for people looking to live in a quite and serene environment</p>


                </div>

             
              </div>

             


              <div className='uk-width-1-2@s'>
                  
              <div 
                    style={{ backgroundImage: `url(${valley1})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    paddingBottom: '50px',
                    paddingTop: '100px',
                    height: '70vh'
                }}

                ></div>
             </div>

             </div>
         </div>


  </div>

 </div>
    )
}

export default Second_Pane; 