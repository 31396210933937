import React from 'react'

import sapphire1 from './../../../images/sapphire1.jpg'

import sapphire2 from './../../../images/sapphire2.jpg'

import sapphire3 from './../../../images/sapphire3.jpg'

import sapphire4 from './../../../images/sapphire4.jpg'

import sapphire5 from './../../../images/sapphire5.jpg'

import sapphire6 from './../../../images/sapphire6.jpg'

import sapphire7 from './../../../images/sapphire7.jpg'






const First_Pane = () => 
(
    <div>
        
        <div 
            style={{ backgroundImage: `url(${sapphire3})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            paddingBottom: '50px',
            paddingTop: '100px',
            height: '100vh'
        }}

        >
            <div className='uk-container uk-margin-xlarge-top'>
                <div className='uk-grid'>
                    <div className='uk-width-3-5@s'>

                    <h1 className='light uk-text-bold header1' uk-scrollspy="cls: uk-animation-slide-top; repeat: true">Boite Sapphire </h1>
                    <h5 className='light uk-margin-small-top' uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true">Boite Sapphire is an ambitious and inspiring development you can be
                   proud to call a home. The site is made up of 11 Units of three-bedroom
                   apartments with One room Attached  BQ Located  in  Life-camp area of 
                   Abuja, the Federal Capital City.</h5>
                    </div>

                    <div className='uk-width-2-5@s'>

                    </div>
                </div>


            </div>
           
          
        </div>



        
    </div>
  )


export default First_Pane