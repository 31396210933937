import react from 'react'

import vNight2 from './../../../images/vNight2.jpg' 
import homeIcon from './../../../images/homeIcon.png'
import { Link } from 'react-router-dom'
import CountUpBoite from './CountUpBoite'



const Seventh_Pane = () => (

     
           <div className=' marginUp '>
                 {/**Desktop**/}
                 <div className='uk-grid uk-grid-collapse uk-visible@s' data-uk-grid>
                         <div className='uk-width-2-5@s'>
                            <div className="boiteBg uk-padding" >
                                 <div className=' '>
                                        
                                 <h1 className='header1 light paddingTopMedium'>Boite 311 Residence</h1>

                               <p className='light'>Residential projects are a pathway for building vibrant cities through a range of accessible 
                                   housing options. Boite 311 residence is a Careful consideration for lifestyle, amenity , efficiency and aesthetics.</p>
                                
                                 </div>
                                
                                 <div className='uk-grid uk-grid-collapse uk-margin-medium-top ' data-uk-grid>
                                         <div className='uk-width-1-5 uk-text-left' uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                                              <img src = {homeIcon} className='iconMedium' />
                                         </div>

                                         <div className='uk-width-4-5 uk-margin-small-top ' uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                                             <p className='  light moveLeftTextH'>Plot 311, off jt useni way, Dape, Lifecamp, Abuja</p>
                                         </div>

                                         <div class="uk-padding-small uk-margin-medium-top">
                                            <Link to="/boite311"><div class="btnCustom btn-ghost-light uk-text-center " uk-scrollspy="cls: uk-animation-slide-left; repeat: true">View Project</div> </Link>
                       
                                            </div>
                                 </div>






                            </div>
                         </div>

                         <div className='uk-width-3-5@s'>
                             <div  style={{ backgroundImage: `url(${vNight2})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            paddingBottom: '50px',
                            paddingTop: '100px',
                            height: '50vh'

                            }}>


                                      
                               <div className='uk-grid' data-uk-grid>
                                   <div className='uk-width-1-1 uk-text-center uk-margin-large-top'>

                                <CountUpBoite end={5} start={0} />
                                </div>
                                </div>

                             </div>
                         </div>
                 </div>






                 {/****MOBILE****/}
                 <div className='uk-grid uk-grid-collapse uk-hidden@s' data-uk-grid>
                 <div className='uk-width-3-5@s'>
                             <div  style={{ backgroundImage: `url(${vNight2})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            paddingBottom: '50px',
                            paddingTop: '100px',
                            height: '50vh'

                            }}>

                             </div>
                         </div>
                         <div className='uk-width-2-5@s'>
                            <div className="boiteBg uk-padding" >
                                 <div className=' '>
                                        
                                 <h1 className='header1 light paddingTopMedium'>Boite 311 Residence</h1>
                               <p className=' light uk-text-small uk-margin-small-right'>Separated they live in. Separated they live in Bookmarksgrove right at the coast 
                                   of the Semantics.  Separated they live in. Separated they live in Bookmarksgrove 
                                   right  at the coast of the Semantics.</p>
                                
                                 </div>
                                
                                 <div className='uk-grid uk-grid-collapse uk-margin-medium-top ' data-uk-grid>
                                         <div className='uk-width-1-5 uk-text-center' uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                                              <img src = {homeIcon} className='iconMedium' />
                                         </div>

                                         <div className='uk-width-4-5 uk-margin-small-top ' uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                                             <p className='  light '>Plot 311, off jt useni way, Dape, Lifecamp, Abuja</p>
                                         </div>

                                         <div class="uk-padding-small uk-margin-medium-top">
                                            <Link to="/boite311"><div class="btnCustom btn-ghost-light uk-text-center " uk-scrollspy="cls: uk-animation-slide-left; repeat: true">View Project</div> </Link>
                       
                                            </div>
                                 </div>






                            </div>
                         </div>

                     
                 </div>


        </div>
    
)



export default Seventh_Pane;