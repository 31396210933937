import react from 'react';
import wrap from './../../../images/wrap.webp'

const Third_pane=()=>{

    return(
    <div>
        <div className='uk-container uk-margin-large-top' 

                    style={{ backgroundImage: `url(${wrap})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    paddingBottom: '50px',
                    paddingTop: '100px',
                    height: '50vh'
                    }}
        
        
        
        >
            <div className='uk-text-center'>
                <h2 className='uk-text-bold'>Features</h2>
            </div>
            <div className='uk-grid uk-grid-collapse uk-margin-medium-top' data-uk-grid>
                    <div className='uk-width-1-2@s'>
                       <div className='uk-flex'>
                          <div className='' uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                          <span uk-icon="icon: check; ratio: 1.5"></span>
                          </div>

                          <div className='uk-margin-left' uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                              <p>CCTV Surveillance Cameras</p>
                          </div>
                       </div>


                       <div className='uk-flex'>
                          <div uk-scrollspy="cls: uk-animation-slide-left; repeat: true"> 
                          <span uk-icon="icon: check; ratio: 1.5"></span>
                          </div>

                          <div className='uk-margin-left' uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                              <p>Interlocked Road Network</p>
                          </div>
                       </div>


                       <div className='uk-flex'>
                          <div uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                          <span uk-icon="icon: check; ratio: 1.5"></span>
                          </div>

                          <div className='uk-margin-left' uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                              <p>Generator</p>
                          </div>
                       </div>


                       <div className='uk-flex'>
                          <div uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                          <span uk-icon="icon: check; ratio: 1.5"></span>
                          </div>

                          <div className='uk-margin-left' uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                              <p>Security</p>
                          </div>
                       </div>
                    </div>

                    <div className='uk-width-1-2@s'>
                           
                       <div className='uk-flex'>
                          <div uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                          <span uk-icon="icon: check; ratio: 1.5"></span>
                          </div>

                          <div className='uk-margin-left' uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                              <p>Street Light</p>
                          </div>
                       </div>




                       
                       <div className='uk-flex'>
                          <div uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                          <span uk-icon="icon: check; ratio: 1.5"></span>
                          </div>

                          <div className='uk-margin-left' uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                              <p>Intercom</p>
                          </div>
                       </div>




                       
                       <div className='uk-flex'>
                          <div uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                          <span uk-icon="icon: check; ratio: 1.5"></span>
                          </div>

                          <div className='uk-margin-left' uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                              <p>Perimeter Fencing</p>
                          </div>
                       </div>



                       
                       <div className='uk-flex'>
                          <div uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                          <span uk-icon="icon: check; ratio: 1.5"></span>
                          </div>

                          <div className='uk-margin-left' uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                              <p>Central Overhead Tank</p>
                          </div>
                       </div>
                    </div>
            </div>
        </div>
    </div>
    )
}


export default Third_pane