

import react from 'react'
import Layout from '../../Layout'


import haj1 from './../../../images/haj1.jpg'
import haj2 from './../../../images/haj2.jpg'
import haj3 from './../../../images/haj3.jpg'
import haj4 from './../../../images/haj1.jpg'


import boite1 from './../../../images/boite1.jpg'
import boite2 from './../../../images/boite2.jpg'
import boite3 from './../../../images/boite3.jpg'
import boite4 from './../../../images/boite4.jpg'
import boite5 from './../../../images/boite5.jpg'


const portDesign = () => (
<Layout>
<div   uk-scrollspy="cls: uk-animation-slide-left-medium; repeat: true"
                     style={{ backgroundImage: `url(${haj1})`,
                     backgroundRepeat: 'no-repeat',
                     backgroundSize: 'cover',
                     paddingBottom: '50px',
                     height: '100vh',
                     borderRadius: "10px",
                     paddingTop: '60px'
                     }}
                     
                      >
                          <div className='uk-container'>
                      <div className='uk-margin-xlarge-top'>
                            <h1 className='uk-text-bold light header1 '>Our PortFolio</h1>
                            <h3 className='uk-text-bold header3 light  uk-margin-medium-top'>Design </h3>
                            </div>

                            </div>

                     </div> 











                     
                     <div className='uk-container uk-margin-large-top'>

                        <div className='uk-text-center'>
                            <h2 className='header2'>Hajmam Service apartment</h2>
                            
                            <h4 className='uk-text-bold blueText uk-text-center uk-padding'> <span className=''>    </span></h4>
                    </div>


                    <div className='uk-grid' data-uk-grid>
                        <div className='uk-width-1-2'>
                             <div
                                style={{ backgroundImage: `url(${haj1})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                paddingBottom: '50px',
                                height: '50vh',
                                borderRadius: "10px",
                                paddingTop: '60px'
                                }}
                             >

                             </div>
                        </div>


                        <div className='uk-width-1-2'>
                        <div
                                style={{ backgroundImage: `url(${haj2})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                paddingBottom: '50px',
                                height: '50vh',
                                borderRadius: "10px",
                                paddingTop: '60px'
                                }}
                             >

                             </div>
                       </div>
                         
                    </div>

                   

                   
                   <div className='uk-grid' data-uk-grid>
                        <div className='uk-width-1-2'>
                             <div
                                style={{ backgroundImage: `url(${haj3})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                paddingBottom: '50px',
                                height: '50vh',
                                borderRadius: "10px",
                                paddingTop: '60px'
                                }}
                             >

                             </div>
                        </div>


                        <div className='uk-width-1-2'>
                        <div
                                style={{ backgroundImage: `url(${haj4})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                paddingBottom: '50px',
                                height: '50vh',
                                borderRadius: "10px",
                                paddingTop: '60px'
                                }}
                             >

                             </div>
                       </div>
                         
                    </div>

 
</div>



                                    <div className='uk-container uk-margin-large-top'>

                                    <div className='uk-text-center'>
                                        <h2 className='header2'>Boite 311 Residence</h2>
                                        
                                        <h4 className='uk-text-bold blueText uk-text-center uk-padding'> <span className=''>    </span></h4>
                                    </div>


                                    <div className='uk-grid' data-uk-grid>
                                    <div className='uk-width-1-3'>
                                        <div
                                            style={{ backgroundImage: `url(${boite1})`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                            paddingBottom: '50px',
                                            height: '50vh',
                                            borderRadius: "10px",
                                            paddingTop: '60px'
                                            }}
                                        >

                                        </div>
                                    </div>

                                    <div className='uk-width-1-3'>
                                        <div
                                            style={{ backgroundImage: `url(${boite2})`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                            paddingBottom: '50px',
                                            height: '50vh',
                                            borderRadius: "10px",
                                            paddingTop: '60px'
                                            }}
                                        >

                                        </div>
                                    </div>


                                    <div className='uk-width-1-3'>
                                    <div
                                            style={{ backgroundImage: `url(${boite3})`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                            paddingBottom: '50px',
                                            height: '50vh',
                                            borderRadius: "10px",
                                            paddingTop: '60px'
                                            }}
                                        >

                                        </div>
                                    </div>
                                    
                                    </div>




                                    <div className='uk-grid' data-uk-grid>
                                    <div className='uk-width-1-2'>
                                        <div
                                            style={{ backgroundImage: `url(${boite4})`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                            paddingBottom: '50px',
                                            height: '50vh',
                                            borderRadius: "10px",
                                            paddingTop: '60px'
                                            }}
                                        >

                                        </div>
                                    </div>


                                    <div className='uk-width-1-2'>
                                    <div
                                            style={{ backgroundImage: `url(${boite5})`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                            paddingBottom: '50px',
                                            height: '50vh',
                                            borderRadius: "10px",
                                            paddingTop: '60px'
                                            }}
                                        >

                                        </div>
                                    </div>
                                    
                                    </div>


                                    </div>



                     
</Layout>


)


export default portDesign