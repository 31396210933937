import React from 'react'
import boiteAzureBg from './../../../images/boiteAzureBg.jpg'

import vc1 from './../../../images/vc1.jpg'

import vc2 from './../../../images/vc2.jpg'

import vc3 from './../../../images/vc3.jpg'

import vc4 from './../../../images/vc4.jpg'

import vc5 from './../../../images/vc5.jpg'

import vc6 from './../../../images/vc6.jpg'

import vc7 from './../../../images/vc7.jpg'






const First_Pane = () => 
(
    <div>
        
        <div 
            style={{ backgroundImage: `url(${vc1})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            paddingBottom: '50px',
            paddingTop: '100px',
            height: '90vh'
        }}

        >
            <div className='uk-container uk-margin-xlarge-top'>
                <div className='uk-grid'>
                    <div className='uk-width-3-5@s'>

                    <h1 className='light uk-text-bold header1'>Valley Court II </h1>
                    <h5 className='light'>Valley Court 2 comes  on the back of the highly successful selling-out
                        of the first phase. With spacious  apartments boasting luxury fixtures 
                        and fttings makes this exquisite masterpiece is second  to none.</h5>
                    </div>

                    <div className='uk-width-2-5@s'>

                    </div>
                </div>


            </div>
           
          
        </div>

        



     


       


        


        
    






        
    </div>
  )


export default First_Pane