import react from 'react';
import gray_dots_BS from './../../../images/gray_dots.jpg'

import sapphire1a from './../../../images/sapphire1a.png'



const Second_Pane = () =>(
    
        <div>

            <div className=''
            style={{ backgroundImage: `url(${gray_dots_BS})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            paddingBottom: '50px',
            paddingTop: '100px',
            height: '90vh'
           }}
           >

            <div className='uk-container'>
              <div className='uk-grid ' data-uk-grid>
                    <div className='uk-width-1-2@s'>
                          <div className='uk-grid uk-grid-collapse' data-uk-grid>
                              <div className='uk-width-1-2' uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                                 <img src= {sapphire1a} />
                              </div>

                              <div className='uk-width-1-2 uk-margin-large-top' uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true">
                              <img src= {sapphire1a} />
                              </div>
                          </div>
                    </div>

                    <div className='uk-width-1-2@s' uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                        <div className='box_shadow_white'>
                            <p> This Luxurious but yet affordable homes developed from 
                                 thoughtful layouts, comes with Three (3) bedroom units.</p>

                                 <p className='uk-margin-small-top'>With elegant, premium fixtures and high end fittings, Boite 
                                     Sapphire also enjoys access to shared landscape around garden
                                      areas rarely found in the city.</p>

                                      <p className=''>From layout to lighting, each apartment has been thoughtfully designed with resient's 
                                          needs and lifestyles in mind. Fixtures,   fittings  and  finishes  are  contemporary 
                                           and best-in-class throughout, while sunlight levels have been ingeniously maximised  in every home</p>
                            </div>
                    </div>
              </div>
        </div>

            </div>

    







        </div>

) 



export default Second_Pane