import React from 'react'
import v8 from './../../../images/v8.jpg'
import Full from './../../../images/null.png'
import barline from './../../../images/Bar_line.png'

import dots from './../../../images/dots.png'




const First_Pane = () => {



    return(
        <div>
        <div
        
            style={{ 
            backgroundImage: `-webkit-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${v8})`,
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${v8})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            paddingBottom: '50px',
            paddingTop: '100px',
            height: '90vh'
            }}
        
        
        >


            <div className='uk-container'>

                <div className='uk-grid' data-uk-grid>
                    <div className='uk-width-1-2@s'>
                        
               <h1 className='light header1 uk-margin-xlarge-top' uk-scrollspy="cls: uk-animation-slide-right; repeat: true">Real Estate Development</h1>
  <p className='light uk-margin-medium-top' uk-scrollspy="cls: uk-animation-fade; repeat: true">
                   Toolboox Atelier's Real Estate is driven by a team of dynamic, dedicated, and ambitious
                   humans who live, think, and breathe real estate. </p>
                    </div>

                    <div className='uk-width-1-2@s'>

                    </div>
                </div>

            </div>
           
        </div>

        
        <div className='  '
        >


                  <div className='uk-container'
                       style={{ backgroundImage: `url(${dots})`,
                       backgroundRepeat: 'no-repeat',
                       backgroundSize: 'cover',
                    
                       height: '90vh',
                       borderRadius: "10px"
                   }}
                     
                  >                   
                   <div className='uk-grid uk-grid-collapse'>
                          <div className='uk-width-1-2@s uk-margin-medium-top'>
                              <div className='borderWhite uk-margin-xlarge-top' uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                                 <h3 className='uk-margin-large-top uk-text-bold uk-margin-large-bottom'>From our team of creative 
                   thinkers, and innovative designers to the management board, we are bound by
                    the core values of integrity, service, transparency, and professionalism.  </h3>
                              </div>
                          </div>

                          <div className='uk-width-1-2@s'>
                              <div className='blue'>
                              <div className='uk-margin-xlarge-top uk-padding'>
                              <h3 className='uk-text-bold '>Why you should trust us in real estate Development</h3>



                              <div className='uk-grid uk-margin-top uk-grid-collapse' data-uk-grid >
                                <div className='uk-width-1-6 uk-text-left' uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                                <span uk-icon="icon: check; ratio: 1.6" className='uk_check'></span>
                                </div>

                                <div className='uk-width-5-6' uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                                <p className=''>Our core values are centered on professionalism, Integrity and you</p>
                                </div>
                          </div>



                              

                          <div className='uk-grid uk-margin-top uk-grid-collapse' data-uk-grid >
                                <div className='uk-width-1-6 uk-text-left' uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                                      <span uk-icon="icon: check; ratio: 1.6" className='uk_check'></span>
                                </div>

                                <div className='uk-width-5-6' uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                                <p className=''>We build quality homes that meet your pocket while exceeding your expectations</p>
                                </div>
                          </div>


                          <div className='uk-grid uk-margin-top uk-grid-collapse' data-uk-grid >
                                <div className='uk-width-1-6 uk-text-left' uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                                <span uk-icon="icon: check; ratio: 1.6" className='uk_check'></span>
                                </div>

                                <div className='uk-width-5-6' uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                                <p className=''>We have a payment plan that turns your dream home into a reality.</p>
                                </div>
                          </div>


                          <div className='uk-grid uk-margin-top uk-grid-collapse' data-uk-grid >
                                <div className='uk-width-1-6 uk-text-left' uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                                <span uk-icon="icon: check; ratio: 1.6" className='uk_check'></span>
                                </div>

                                <div className='uk-width-5-6' uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                                <p className=''>We have found a way to keep the standards high while keeping the price friendly</p>
                                </div>
                          </div>




                          </div>
                              </div>
                        
                          </div>
                   </div>
                   </div>

        </div>






</div>
    )
   

}

export default First_Pane;