import React from 'react'
import homeBox from './../../../images/home-box.png'
import remodelSide from './../../../images/remodelSide.png'
import { Link } from 'react-router-dom'

const Second_Pane = () => 
   (
    <div  className=''>
          <div
              style={{ backgroundImage: `url(${homeBox})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              paddingBottom: '10px',
              paddingTop: '10px',
              height: '80vh'
          }}
          >
            <div className='uk-margin-large-top uk-container'>
            <div className='uk-grid' data-uk-grid>

                   <div className='uk-width-1-2@s'>
                     <div  className='uk-margin-top '  >
                             <img src= {remodelSide} />
                     </div>

                   </div>

                   <div className='uk-width-1-2@s'>
                     <div className=' uk-margin-medium-top'>
                         <h3 className='light header3 uk-text-bold'>Tech is the future you know now dont dull</h3>
                         <p className='light'>Excellent! You've been using Forminator for a while now.
                            Hope you are enjoying it so far. We have spent countless
                             hours developing this free plugin for you, and we would really 
                             appreciate it if you could drop us a rating on wp.org to help us 
                             spread the word and boost our motivation.</p>
                             
                             <p className='light'>Excellent! You've been using Forminator for a while now.
                            Hope you are enjoying it so far. We have spent countless
                             hours developing this free plugin for you, and we would really 
                             appreciate it if you could drop us a rating on wp.org to help us 
                             spread the word and boost our motivation.</p>
                         </div>
                   </div>
              </div>
              </div>
              
          </div>

         










    </div>
  )


export default Second_Pane