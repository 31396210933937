import emailjs from 'emailjs-com'



const Mailer = () =>{

  function sendEmail(e){
      e.preventDefault();

      emailjs.sendForm('service_otzsq9a', 'template_ihc2zkg', e.target)
     .then(res=>{
         console.log(res)
     })
  }



    return(
      <div>
          <h3 className='header3'>Your investment elevated, one email away. </h3>
        




          <form class="uk-grid-small" data-uk-grid
            onSubmit={sendEmail}
          >
          <div class="uk-width-1-2@s">
              <input class="uk-input" name="first_name" type="text" placeholder="First Name" />
          </div>

            <div class="uk-width-1-2@s">
                <input class="uk-input"  name="last_name" type="text" placeholder="Last Name" />
            </div>

            <div class="uk-width-1-1">
                <input class="uk-input" name="user_email" type="text" placeholder="Email Address" />
            </div>

            <div class="uk-width-1-2@s">
           <p className="uk-text-small">What service are you interested in?</p>
            </div>

            <div class="uk-width-1-2@s">
                        <div class="uk-margin" >
                    <div class="uk-form-controls" name="drop_down">
                        <select class="uk-select" id="form-horizontal-select">
                            <option>Option 01</option>
                            <option>Option 02</option>

                        </select>
                    </div>
                    </div>
          </div>

          <div class="uk-width-1-1"> 
          
            <div class="uk-margin">
                <textarea class="uk-textarea" name="message" rows="5" placeholder="Textarea"></textarea>
            </div>
            </div>

            <div className="uk-margin">
            <button class="uk-button uk-button-default">Submit</button>
            </div>
   
</form>
      </div>





    )
};



export default Mailer;