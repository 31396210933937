import  react from 'react';

import teamHeader from './../../../images/teamHeader.jpg'

import team from './../../../images/team.png'
import briefCase from './../../../images/briefCase.png'


import Layout from './../../Layout/layout'




export default function() {
    return(
        <Layout>
        <div
        
        style={{ 
        backgroundImage: `-webkit-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${teamHeader})`,
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${teamHeader})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        paddingBottom: '50px',
        paddingTop: '100px',
        height: '75vh'
        }}


    
    >


<div className='uk-container '>
             <div className='uk-grid' data-uk-grid>
                 <div className='uk-width-1-3@s uk-margin-xlarge-top'>
                    <h1 className='light uk-text-bold'>Umar Mohammed </h1>
                    <h3 className='light uk-margin-remove-top'>Project Manager</h3>

                    <div className=' uk-margin-top'>

                    <a href="" class="uk-icon-button uk-margin-small-right" uk-icon="twitter"></a>
<a href="" class="uk-icon-button  uk-margin-small-right" uk-icon="facebook"></a>
<a href="" class="uk-icon-button" uk-icon="youtube"></a>
</div>
                 </div>

                 <div className='uk-width-1-3@s'>

                 </div>

                 <div className='uk-width-1-3@s'>
                     <div className='teamBg'>
                     <img src= {team} />
                     </div>
                 </div>
             </div>
        </div>


      


    </div>



        <div className='uk-container uk-margin-large-top'>
        <div className='uk-grid' data-uk-grid>
            <div className='uk-width-3-5@s'>
               <p className='uk-margin-top'>
                Umar is a seasoned Project Management Professionals and business
                strategist with proven track record of strategically executing techniques
                and conveying on organizational objectives. He believes in the combined 
                part of individual driven strategies and frameworks in conveying greatness.</p>

                <p className='uk-margin-top'>Umar is adept at overseeing daily operations of the
                 organization whilst driving critical processes targeted at promoting Strategy 
                 Execution, Leadership Development, and Continuous Improvement..</p>

                <p className='uk-margin-top'>He understands critically, customer engagement,
                 and strategy. His strengths include business management, people management, 
                 customer relationship management and more.Umar is a graduate of Federal
                  University of Minna, Niger State, Nigeria and a certified Urban and 
                  regional Planner..
                </p>

              

            </div>


            <div className='uk-width-2-5@s uk-margin-medium-top '>
               
               <div className='teamDesc'>
            <div class="uk-flex ">
                <div class="uk-card "><img src ={briefCase} className="briefImg"/></div>
                <div class="uk-card uk-margin-left"><h4 className='uk-text-bold'> Project Management</h4></div>
            </div>


            <div class="uk-flex">
                <div class="uk-card "><a href="" uk-icon="icon: location"></a></div>
                <div class="uk-card uk-margin-left "><h5 className=''>Abuja, Nigeria</h5></div>
            </div>
            </div>

               
               </div>
        </div>


        </div>




        <div className='uk-container uk-margin-large-top'>
            <div className='uk-grid' data-uk-grid>
                 <div className='uk-width-1-2@s'>
                   <div>
                       <h1 className=''>FUN <span className=' uk-text-bold'>FACT</span></h1>
                       <hr className='line'></hr>

                       <p>Umar likes reading about politics in his country</p>
                   </div>
                 </div>

                 <div className='uk-width-1-2@s'>
                 
                 </div>
            </div>
        </div>


    </Layout>
    )
}