import react from 'react'
import home2bg from './../../images/home2bg.jpg'
import {Link } from 'react-router-dom'

const First_Pane =() =>(
     <div>
         <div className=''
           style={{ backgroundImage: `url(${home2bg})`,
           backgroundRepeat: 'no-repeat',
           backgroundSize: 'cover',
           paddingBottom: '50px',
           paddingTop: '100px',
           height: '130vh'
       }}>
                       
                       <div className='uk-container uk-margin-xlarge-top'>
                            <div className='uk-grid'>
                    <div className='uk-width-3-5@s' uk-scrollspy="cls: uk-animation-slide-left; repeat: true">

                    <h1 className='light uk-text-bold header1'>We design and build modern <span style={{color:'#3bcbee'}}>structures</span> </h1>
                  
                    </div>

                    <div className='uk-width-2-5@s'>

                    </div>
                </div>

                <div className='uk-margin-xlarge-top'>
                    <div className='ctaHero'>
                  <Link to="/projects">See our Projects</Link>
                    </div>
                </div>


            </div>
              





        </div>
     </div>
)


export default First_Pane