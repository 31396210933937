
import { Link } from "react-router-dom";
import logo from './../../../images/newLogo.png'
import projectPics from './../../../images/v8.jpg'
import homeIcon from './../../../images/homeIcon.png'
import message from './../../../images/message1.png'
import phoneIcon from './../../../images/phoneIcon.png'
import footerImg from './../../../images/footerImg.jpg'



const FooterBlock = () => (
   <div className="uk-margin-xlarge-top">
   


     <div className="mainFooter">
     <div class="icon-bar">
            <a href="#" class="facebook"><i class="fa fa-facebook"></i></a>
            <a href="#" class="twitter"><i class="fa fa-twitter"></i></a>
            <a href="#" class="google"><i class="fa fa-instagram"></i></a>
            <a href="#" class="linkedin"><i class="fa fa-linkedin"></i></a>
            
        </div>



          <div className="uk-container">
              <div className="uk-grid">

              <div className="uk-width-2-5@s">
                    <div className="footerInfoBg"
                    style={{ backgroundImage: `url(${footerImg})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    paddingBottom: '40px',
                    paddingTop: '10px',
                    height: 'auto',
                    marginTop:'-90px'
                    }}
                    >
                     <img src= {logo}  className="logoFooter"/>
                     <p className="footerText">Toolbox atelier is conceived and built on the
                          foundation of excellence, trust,integrity,
                           professional and best practice ,we have grown 
                           in size and portfolio to become a reliable 
                           property development company with a proven
                            track record of excellence </p>
                        <div className="uk-flex uk-margin-left">
                        <a href="" class="uk-icon-button uk-margin-small-right socialBtn"  uk-icon="twitter"></a>
                         <a href="" class="uk-icon-button  uk-margin-small-right socialBtn" uk-icon="facebook"></a>
                         <a href="" class="uk-icon-button  uk-margin-small-right socialBtn" uk-icon="instagram"></a>
                         <a href="" class="uk-icon-button socialBtn" uk-icon="youtube"></a>
                        </div>

                    </div>
                </div>

                <div className="uk-width-3-5@s">
                    <div className="uk-grid uk-padding">
                         <div className="uk-width-1-2@s">
                         <h3 className="light uk-text-bold">Site Map</h3>
                            <nav className="footerNav">
                                <ul>
                               <a href="#"> <li>Home</li> </a>
                               <a href="#">  <li>About</li></a>
                               <a href="#">  <li>Services</li></a>
                               <a href="#">  <li>Portfolio</li></a>
                               <a href="#"> <li>Contact Us</li></a>
                                </ul>
                            </nav>
                         </div>

                         <div className="uk-width-1-2@s">
                           <h3 className="light uk-text-bold">Contact Us</h3>
                                <div className="uk-grid uk-grid-collapse" >
                                    <div className="uk-width-1-5">
                                    <span class="uk-margin-small-right" uk-icon="icon: location; ratio: 1.5"></span>
                                    </div>

                                    <div className="uk-width-4-5">
                                        <p className="infoText">plot 311 , off jt useni way, Dape, Abuja</p>
                                    </div>
                                </div>

                                <div className="uk-grid uk-grid-collapse uk-margin-top" >
                                    <div className="uk-width-1-5">
                                    <span class="uk-margin-small-right" uk-icon="icon: mail; ratio: 1.5"></span>
                                    </div>

                                    <div className="uk-width-4-5">
                                        <p className="infoText">info@toolboxateliers.com</p>
                                    </div>
                                </div>


                                <div className="uk-grid uk-grid-collapse uk-margin-top" >
                                    <div className="uk-width-1-5">
                                    <span class="uk-margin-small-right" uk-icon="icon: receiver; ratio: 1.5"></span>
                                    </div>

                                    <div className="uk-width-4-5">
                                        <p className="infoText">08111111111111</p>
                                    </div>
                                </div>

                            


                              


                                
                         </div>
                    </div>
                </div>
              </div>
               
          </div>
     </div>




   </div>
);


export default FooterBlock;

