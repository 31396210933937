import react from 'react'
import projectImg2 from './../../../images/projectImg2.jpg'
import under_const from './../../../images/under_const.png'
import boite6 from './../../../images/boite6.jpg'
import boite7 from './../../../images/boite7.jpg'
import boite8 from './../../../images/boite8.jpg'
import boite9 from './../../../images/boite9.jpg'
import boite10 from './../../../images/boite10.jpg'
import boite11 from './../../../images/boite11.jpg'
import boite12 from './../../../images/boite12.jpg'
import boite13 from './../../../images/boite13.jpg'
import boite14 from './../../../images/boite14.jpg'

import {Link} from 'react-router-dom'


const Fourth_Pane = () =>{
    return(
        <div>
            <div className='uk-container uk-margin-medium-top'>

                    <div className='uk-grid' data-uk-grid>
                         <div className='uk-width-1-5@s'>

                         </div>

                         <div className='uk-width-3-5@s'>
                              <h3 className='uk-text-bold header3 uk-text-center'>THE PROCESS</h3>
                              <p className='uk-text-center'>Living. Over. He god, living a. Creature that appearplace creeping upon. It you said seas every creeping and a</p>
                         </div>

                         <div className='uk-width-1-5@s'>

                         </div>
                    </div>
                    
                     {/**The Promise Slider***/}
                     
                     <div className="uk-margin-medium-top" data-uk-slider>

<div class="uk-position-relative">

    <div class="uk-slider-container uk-light">
        <ul class="uk-slider-items uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-4@m">
            <li>
                <img src={boite6} alt=""/>
                <div class="uk-position-center uk-panel"><h1>1</h1></div>
            </li>
            <li>
                <img src={boite7} alt=""/>
                <div class="uk-position-center uk-panel"><h1>2</h1></div>
            </li>
            <li>
                <img src={boite8} alt=""/>
                <div class="uk-position-center uk-panel"><h1>3</h1></div>
            </li>
            <li>
                <img src={boite9} alt=""/>
                <div class="uk-position-center uk-panel"><h1>4</h1></div>
            </li>
            <li>
                <img src={boite10} alt=""/>
                <div class="uk-position-center uk-panel"><h1>5</h1></div>
            </li>
            <li>
                <img src={boite11} alt=""/>
                <div class="uk-position-center uk-panel"><h1>6</h1></div>
            </li>
            <li>
                <img src={boite12} alt=""/>
                <div class="uk-position-center uk-panel"><h1>7</h1></div>
            </li>
            <li>
                <img src={boite13} alt=""/>
                <div class="uk-position-center uk-panel"><h1>8</h1></div>
            </li>
            <li>
                <img src={boite14} alt=""/>
                <div class="uk-position-center uk-panel"><h1>9</h1></div>
            </li>
          
        </ul>
    </div>

    <div class="uk-hidden@s uk-light">
        <a class="uk-position-center-left uk-position-small" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
        <a class="uk-position-center-right uk-position-small" href="#" uk-slidenav-next uk-slider-item="next"></a>
    </div>

    <div class="uk-visible@s">
        <a class="uk-position-center-left-out uk-position-small" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
        <a class="uk-position-center-right-out uk-position-small" href="#" uk-slidenav-next uk-slider-item="next"></a>
    </div>

</div>

<ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>

</div>


            </div>
        </div>
    )
}


export default Fourth_Pane;