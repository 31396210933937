import { ExpansionPanel } from '@material-ui/core';
import react from 'react';

import ceo from './../../../images/ceo.png'
import cityuse from './../../../images/cityuse.jpg'




const Eight_Pane = () => (
    <div
    
        
    
style={{ backgroundImage: `url(${cityuse})`,
backgroundRepeat: 'no-repeat',
backgroundSize: 'cover',
paddingBottom: '40px',
paddingTop: '10px',
height: 'auto'
}}
    
    >
         <div className='uk-container uk-margin-large-top uk-margin-large-bottom'>
             <div className='uk-grid' data-uk-grid>
                  <div className='uk-width-1-2@s uk-padding'>
                      <h1 className='header1 light uk-margin-xlarge-top'>Top<br/> Management</h1>
                      <p className='light'> A graduate of Architecture  from  the Federal University of Technology Minna, Mamud 
                      possesses over a decade' s wor th of industry experience.He has developed big diversity of projects with 
                      national and international clients, which has gained him vast experience in every architectural phase In different areas</p>
                           
                      <h1 className='header1 light uk-margin-large-top'>Arc <br/> Mamud Imam</h1>
                      <p className='light'> Chairman/ CEO </p>
                  </div>

                  <div className='uk-width-1-2@s'>
                      <div className='ceoImg uk-padding uk-margin-large-top'>
                          <img src= {ceo} />
                      </div>
                  </div>
             </div>
        </div>   
    </div>
)



export default Eight_Pane; 