import React from 'react'
import Layout from '../Layout'
import First_Pane from './Blocks/First_Pane'
import Second_Pane from './Blocks/Second_Pane'
import Third_Pane from './Blocks/Third_Pane'

const Remodelling = () => {
    return (
      <Layout>
      <First_Pane />
      <Second_Pane />
      <Third_Pane />
      </Layout>
    )
  }


export default Remodelling