import react from 'react';
import onlineCourse from '../../../images/online-classroom.png';
import cyberteckKids from '../../../images/cyberteck-kids.png'
import check from '../../../images/check.png';
import { Link } from "react-router-dom";
import sidePics from './../../../images/sidePics.jpg'

import team4 from './../../../images/team4.png'
import team2 from './../../../images/team2.png'
import team1 from './../../../images/team1.png'
import team from './../../../images/team.png'
import coreIcon from './../../../images/coreIcon.png'
import integrityIcon from './../../../images/integrityIcon.png'
import partnershipIcon from './../../../images/partnershipIcon.png'
import excellenceIcon from './../../../images/excellenceIcon.png'
import innovativeIcon from './../../../images/innovativeIcon.png'
import leadershipIcon from './../../../images/leadershipIcon.png'


const NinethPane =  () =>( 

            <div>

           <div className="uk-container uk-margin-xlarge-top">
                <div className='uk-grid' data-uk-grid>
                      <div className='uk-width-1-2@s'>
                         <div   style={{ backgroundImage: `url(${sidePics})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            paddingBottom: '50px',
            paddingTop: '100px',
            height: '115vh'
        }} uk-scrollspy="cls: uk-animation-slide-left; repeat: true">

                        </div>
                      </div>



                      
                          
                      <div className='uk-width-1-2@s'>
                          <div className='moveLeftDiv'>
                      <div className='uk-margin-medium-top'>
                             <div className='uk-grid' data-uk-grid>
                                  <div className='uk-width-1-5'>
                                   <img src= {coreIcon} />
                                  </div>

                                  <div className='uk-width-4-5'>
                                  <h2 className='header2'>Our Values</h2>
                                  </div>
                             </div>
                              
                          </div>
                          <div className='uk-grid uk-margin-top uk-grid-collapse' data-uk-grid >
                                <div className='uk-width-1-6' uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                                    <img src = {integrityIcon} className='checkIcon'/>
                                </div>

                                <div className='uk-width-5-6' uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                                    
                                <p className='moveIconLeft'><span className='uk-text-bold'>INTEGRITY:</span>
                                We embrace the highest standards of ethical behavior and transparency in every aspect of our business to yield a
                               Company that is trusted by its clients and stakeholders</p>
                                </div>
                          </div>


                          <div className='uk-grid uk-grid-collapse uk-margin-top' data-uk-grid >
                                <div className='uk-width-1-6' uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                                    <img src = {partnershipIcon} className='checkIcon'/>
                                </div>

                                <div className='uk-width-5-6' uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                                <p className='moveIconLeft'><span className='uk-text-bold'>PARTNERSHIP:</span>Our success and delivery of quality programs and Services are largely dependent upon the partnerships that we create
                                 with all of our internal and external stakeholders</p>
                                </div>
                          </div>

                          <div className='uk-grid uk-grid-collapse uk-margin-top' data-uk-grid >
                                <div className='uk-width-1-6' uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                                    <img src = {excellenceIcon} className='checkIcon'/>
                                </div>

                                <div className='uk-width-5-6' uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                                <p className='moveIconLeft'><span  className='uk-text-bold'>EXCELLENCE</span>Our commitment to professional excellence ensures that
                                         our clients receive the highest quality service. We aspire to provide flawless execution and delivery of our products
                            and services</p>
                                </div>
                          </div>


                          <div className='uk-grid uk-grid-collapse uk-margin-top' data-uk-grid >
                                <div className='uk-width-1-6' uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                                    <img src = {innovativeIcon} className='checkIcon'/>
                                </div>

                                <div className='uk-width-5-6' uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                                <p className='moveIconLeft'><span  className='uk-text-bold'>INNOVATION
</span>We thrive on creativity and ingenuity. In today's fast paced
technological climate, innovative ideas, concepts, and
processes are essential to the continued success and
growth of a company. </p>
                                </div>
                          </div>



                          
                          <div className='uk-grid uk-grid-collapse uk-margin-top' data-uk-grid >
                                <div className='uk-width-1-6' uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                                    <img src = {leadershipIcon} className='checkIcon'/>
                                </div>

                                <div className='uk-width-5-6' uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                                <p className='moveIconLeft'><span  className='uk-text-bold'>LEADERSHIP
</span>The spirit of leadership is instilled in every employee. </p>
                                </div>
                          </div>



                      </div>
                      </div>
               
                      </div>

                </div>









                   
             </div>

     
);



export default NinethPane;