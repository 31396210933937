import react from 'react'

import Layout from './../Layout/layout'
import First_Pane from './Blocks/First_Pane'
import Second_Pane from '../ValleyCourt2/Blocks/Third_pane'
import Third_Pane from './Blocks/Third_Pane'
import Fourth_Pane from './Blocks/Fourth_Pane'



export default function Project_Boite311  () {

    return (
    <Layout>
       <First_Pane />
       <Second_Pane />
       <Third_Pane />
       <Fourth_Pane />
     
 

    </Layout>
)

};