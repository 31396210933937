import Layout from "../Layout";
import React from "react";
import First_Pane from "./Blocks/First_pane";
import Second_Pane from "./Blocks/Second_Pane";
import Third_pane from "../ValleyCourt2/Blocks/Third_pane";
import Fourth_Pane from "./Blocks/Fourth_Pane";



export default function BoiteSapphire() {

    return (
        <Layout>
        <First_Pane />
        <Second_Pane  />
        <Third_pane />
        <Fourth_Pane />
            
        </Layout>

    )




}