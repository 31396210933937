import  react from 'react';

import teamHeader from './../../../images/teamHeader.jpg'

import team2 from './../../../images/team2.png'
import briefCase from './../../../images/briefCase.png'


import Layout from './../../Layout/layout'




export default function() {
    return(
        <Layout>
        <div
        
        style={{ 
        backgroundImage: `-webkit-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${teamHeader})`,
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${teamHeader})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        paddingBottom: '50px',
        paddingTop: '100px',
        height: '75vh'
        }}


    
    >


<div className='uk-container '>
             <div className='uk-grid' data-uk-grid>
                 <div className='uk-width-1-3@s uk-margin-xlarge-top'>
                    <h1 className='light uk-text-bold'>Engr. Fabian Chinedu  </h1>
                    <h3 className='light uk-margin-remove-top'></h3>

                  <div className='uk-margin-top'>
                    <a href="" class="uk-icon-button uk-margin-small-right" uk-icon="twitter"></a>
                    <a href="" class="uk-icon-button  uk-margin-small-right" uk-icon="facebook"></a>
                    <a href="" class="uk-icon-button" uk-icon="youtube"></a>
                   </div>

                 </div>

                 <div className='uk-width-1-3@s'>

                 </div>

                 <div className='uk-width-1-3@s'>
                     <div className='teamBg'>
                     <img src= {team2} />
                     </div>
                 </div>
             </div>
        </div>


      


    </div>



        <div className='uk-container uk-margin-large-top'>
        <div className='uk-grid' data-uk-grid>
            <div className='uk-width-3-5@s'>
               <p className='uk-margin-top'>
               A graduate of Civil Engineering from the Nnamdi Azikiwe University Awka,
                Anambara State Nigeria, Fabian holds an over 10 years' experience in the
                 Real Estate & Construction Industry – Designing, Constructing and Managing
                  both Public and Private Projects across Nigeria.</p>

                <p className='uk-margin-top'>
                He has served in many capacities as Project Engineer, Principal Consultant 
                Infrastructure, Principal Consultant (Structure and Infrastructure), 
                Structural/Inf rastructure Engineer, and Project Manager.</p>

                <p className='uk-margin-top'>
                A seasoned professional with a tenacious attention to detail, 
                Engr Fabian is truly an engineering giant.
                </p>

              

            </div>


            <div className='uk-width-2-5@s uk-margin-medium-top '>
               
               <div className='teamDesc'>
            <div class="uk-flex ">
                <div class="uk-card "><img src ={briefCase} className="briefImg"/></div>
                <div class="uk-card uk-margin-left"><h4  className='uk-text-bold'> Project Management</h4></div>
            </div>


            <div class="uk-flex">
                <div class="uk-card "><a href="" uk-icon="icon: location"></a></div>
                <div class="uk-card uk-margin-left"><h4 className=''>Abuja, Nigeria</h4></div>
            </div>
            </div>

               
               </div>
        </div>


        </div>




        <div className='uk-container uk-margin-large-top'>
            <div className='uk-grid' data-uk-grid>
                 <div className='uk-width-1-2@s'>
                   <div>
                       <h1 className=''>FUN <span className=' uk-text-bold'>FACT</span></h1>
                       <hr className='line'></hr>

                       <p>Fabian likes travelling when he is off work</p>
                   </div>
                 </div>

                 <div className='uk-width-1-2@s'>
                 
                 </div>
            </div>
        </div>


    </Layout>
    )
}