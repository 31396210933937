import Layout from "../Layout";
import React from "react";
import './../mainStyle.css'
import FirstPane from "./Blocks/First_Pane";
import Second_Pane from "./Blocks/Second_Pane";
import Third_Pane from "./Blocks/Third_Pane";
import Fourth_Pane from "./Blocks/Fourth_Pane";
import Fifth_Pane from "./Blocks/Fifth_Pane";
import Sixth_Pane from "./Blocks/Sixth_Pane";
import Seventh_Pane from "./Blocks/Seventh_Pane";
import Eight_Pane from "./Blocks/Eight_Pane";
import Testimonial from './../AboutUsPage/Blocks/SeventhPane'
import './homepage.css'




export default function Homepage() {

    return (
        <Layout>
       
          <FirstPane />
          <Second_Pane />
          <Fourth_Pane/>
          <div className="uk-margin-large-bottom"><Sixth_Pane /> </div>
          <Seventh_Pane /> 
        

          <div className="uk-margin-xlarge-top"><Testimonial /> </div>
          < Eight_Pane />
  
 

            </Layout>

    )




};