import react from 'react'
import Layout from '../../Layout'
import remodel3 from './../../../images/remodel3b.png'
import remodel2 from './../../../images/remodel2.png'
import remodel1 from './../../../images/remodel1.png'
import remodel4 from './../../../images/remodel4.png'
import remodel5 from './../../../images/remodel5.png'
import remodel6 from './../../../images/remodel6.png'
import remodel7 from './../../../images/remodel7.png'
import remodel8 from './../../../images/remodel8.png'


import min1 from './../../../images/min1.JPG'
import min2 from './../../../images/min2.JPG'
import min3 from './../../../images/min3.jpg'
import min4 from './../../../images/min4.jpg'
import min5 from './../../../images/min5.jpg'
import min7 from './../../../images/min7.JPG'
import min8 from './../../../images/min8.JPG'
import min9 from './../../../images/min9.JPG'


const portRemodelling = () => (
       
    <Layout>
               <div className=''>
               <div   uk-scrollspy="cls: uk-animation-slide-left-medium; repeat: true"
                     style={{ backgroundImage: `url(${remodel3})`,
                     backgroundRepeat: 'no-repeat',
                     backgroundSize: 'cover',
                     paddingBottom: '50px',
                     height: '100vh',
                     borderRadius: "10px",
                     paddingTop: '60px'
                     }}
                     
                      >
                          <div className='uk-container'>
                      <div className='uk-margin-xlarge-top'>
                            <h1 className='uk-text-bold light header1 '>Our PortFolio</h1>
                            <h3 className='uk-text-bold header3 light  uk-margin-medium-top'>Remodelling </h3>
                            </div>

                            </div>

                     </div> 



                     <div className='uk-container uk-margin-large-top'>

                        <div className='uk-text-center'>
                            <h2 className='header2'>Hajman Interior Remodelling</h2>
                            
                            <h4 className='uk-text-bold blueText uk-text-center uk-padding'> <span className=''> THE FORMERS   </span></h4>
                    </div>


                    <div className='uk-grid' data-uk-grid>
                        <div className='uk-width-1-2'>
                             <div
                                style={{ backgroundImage: `url(${remodel1})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                paddingBottom: '50px',
                                height: '50vh',
                                borderRadius: "10px",
                                paddingTop: '60px'
                                }}
                             >

                             </div>
                        </div>


                        <div className='uk-width-1-2'>
                        <div
                                style={{ backgroundImage: `url(${remodel2})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                paddingBottom: '50px',
                                height: '50vh',
                                borderRadius: "10px",
                                paddingTop: '60px'
                                }}
                             >

                             </div>
                       </div>
                         
                    </div>

                    <div> <h4 className='uk-text-bold blueText uk-text-center uk-padding'> <span className=''> THE PROMISE   </span></h4>
                   </div>

                   
                   <div className='uk-grid' data-uk-grid>
                        <div className='uk-width-1-2'>
                             <div
                                style={{ backgroundImage: `url(${remodel3})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                paddingBottom: '50px',
                                height: '50vh',
                                borderRadius: "10px",
                                paddingTop: '60px'
                                }}
                             >

                             </div>
                        </div>


                        <div className='uk-width-1-2'>
                        <div
                                style={{ backgroundImage: `url(${remodel4})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                paddingBottom: '50px',
                                height: '50vh',
                                borderRadius: "10px",
                                paddingTop: '60px'
                                }}
                             >

                             </div>
                       </div>
                         
                    </div>

 
</div>

                  <div> <h4 className='uk-text-bold blueText uk-text-center uk-padding'> <span className=''> THE PROCESS   </span></h4>
                   </div>



                   <div className='container'>

                       <div className='row'>
                            <div className='col-sm-4'>
                            <div
                                style={{ backgroundImage: `url(${remodel5})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                paddingBottom: '50px',
                                height: '50vh',
                                borderRadius: "10px",
                                paddingTop: '60px'
                                }}
                             >

                             </div>
                            </div>



                            <div className='col-sm-4'>
                                  <div className='row'>
                                      <div className='col-sm-6'>
                                      <div
                                style={{ backgroundImage: `url(${remodel7})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                paddingBottom: '50px',
                                height: '50vh',
                                borderRadius: "10px",
                                paddingTop: '60px'
                                }}
                             >

                             </div>
                                      </div>


                                      <div className='col-sm-6'>
                                      <div
                                style={{ backgroundImage: `url(${remodel8})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                paddingBottom: '50px',
                                height: '50vh',
                                borderRadius: "10px",
                                paddingTop: '60px'
                                }}
                             >

                             </div>
                                      </div>
                                  </div>
                            </div>





                            <div className='col-sm-4'>
                            <div
                                style={{ backgroundImage: `url(${remodel6})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                paddingBottom: '50px',
                                height: '50vh',
                                borderRadius: "10px",
                                paddingTop: '60px'
                                }}
                             >

                             </div>
                          </div>


                       </div>

                   </div>

                        
                        <div className='uk-container uk-margin-xlarge-top'>
                        <div className='uk-text-center'>
                            <h2 className='header2'> Toolbox Residence Minna</h2>
                            
                            <h4 className=' uk-text-center'> <span className=''> COMPLETED   </span></h4>
                            
                            <h4 className='uk-text-bold blueText uk-text-center uk-padding'> <span className=''> THE FORMER   </span></h4>
                    </div>

                         
                   <div className='uk-grid' data-uk-grid>
                        <div className='uk-width-1-3'>
                             <div
                                style={{ backgroundImage: `url(${min3})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                paddingBottom: '50px',
                                height: '50vh',
                                borderRadius: "10px",
                                paddingTop: '60px'
                                }}
                             >

                             </div>
                        </div>

                        <div className='uk-width-1-3'>
                             <div
                                style={{ backgroundImage: `url(${min4})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                paddingBottom: '50px',
                                height: '50vh',
                                borderRadius: "10px",
                                paddingTop: '60px'
                                }}
                             >

                             </div>
                        </div>


                        <div className='uk-width-1-3'>
                        <div
                                style={{ backgroundImage: `url(${min5})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                paddingBottom: '50px',
                                height: '50vh',
                                borderRadius: "10px",
                                paddingTop: '60px'
                                }}
                             >

                             </div>
                       </div>
                         
                    </div>



                  <div>
                     <h4 className='uk-text-bold blueText uk-text-center uk-padding'> <span className=''> THE PROMISE   </span></h4>
                     </div>
                   

 

        
                  <div className='uk-grid' data-uk-grid>
                       

                        <div className='uk-width-1-2@s'>
                             <div
                                style={{ backgroundImage: `url(${min1})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                paddingBottom: '50px',
                                height: '50vh',
                                borderRadius: "10px",
                                paddingTop: '60px'
                                }}
                             >

                             </div>
                        </div>


                        <div className='uk-width-1-2@s'>
                        <div
                                style={{ backgroundImage: `url(${min2})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                paddingBottom: '50px',
                                height: '50vh',
                                borderRadius: "10px",
                                paddingTop: '60px'
                                }}
                             >

                             </div>
                       </div>
                         
                    </div>


                    <div>
                     <h4 className='uk-text-bold blueText uk-text-center uk-padding'> <span className=''> THE PROCESS   </span></h4>
                     </div>




                     <div className='uk-grid' data-uk-grid>
                       

                       <div className='uk-width-1-2@s'>
                            <div
                               style={{ backgroundImage: `url(${min7})`,
                               backgroundRepeat: 'no-repeat',
                               backgroundSize: 'cover',
                               paddingBottom: '50px',
                               height: '80vh',
                               borderRadius: "10px",
                               paddingTop: '60px'
                               }}
                            >

                            </div>
                       </div>


                       <div className='uk-width-1-2@s'>
                       <div
                               style={{ backgroundImage: `url(${min8})`,
                               backgroundRepeat: 'no-repeat',
                               backgroundSize: 'cover',
                               paddingBottom: '50px',
                               height: '80vh',
                               borderRadius: "10px",
                               paddingTop: '60px'
                               }}
                            >

                            </div>
                      </div>
                        
                   </div>













                        </div>

















               </div>

        </Layout>


)


export default portRemodelling