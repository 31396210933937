import React from 'react'
import infrastructureBg from './../../../images/infrastructureBg.jpg'

import barline from './../../../images/Bar_line.png'

const First_Pane = () => (
    
    <div>
            <div
            style={{ backgroundImage: `url(${infrastructureBg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            paddingBottom: '50px',
            paddingTop: '50px',
            height: '90vh'
        }}

        >

            <div className='uk-container'>
           <div className='uk-grid' data-uk-grid>
                           
                <div className='uk-width-1-2@s'>
                    <h1 className='light uk-text-bold uk-padding-small uk-margin-xlarge-top'uk-scrollspy="cls: uk-animation-slide-left; repeat: true">Infrastructure</h1>
                    <p className='light margin-top'uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                    We have not only developed great infrastructures over the years, but we have also built trust.
                     With dozen  of clients and families enjoying better living in our beautiful
                      homes, you can trust that we will create the perfect space for you and yours. From 
                      residential buildings to commercial properties and estate development, we have
                       created the communities that make life better for all </p>
                   
                 </div>
                  <div className='uk-width-1-2@s'>

                 </div>
                 </div> 
           </div>
     
        </div>
        </div>
  )


export default First_Pane