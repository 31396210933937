import react from 'react'
import home3 from './../../images/home3.png'
import home4 from './../../images/home4.png'
import home6 from './../../images/home6.png'
import home2 from './../../images/home2.png'
import home1 from './../../images/home1.png'

import dots  from './../../images/dots.png'
import {Link}  from 'react-router-dom'


const Fourth_Pane =()=>(

       <div>
            <div className=' grayBgNew'>
              <div></div>
                
                    <div className='leftDark'>
                        <div>
                        </div>

                        <div className='uk-grid uk-margin-large-top' data-uk-grid>
                               <div className='uk-width-3-5 uk-margin-large-top'>

                               <h6 className='light uk-text-center  '>Our offer</h6>
                               </div>

                               <div className='uk-width-2-5 uk-margin-large-top'>
                                  <hr style={{color:'#fff', position:'relative', left:'-150px', width:'90px'}}></hr>
                               </div>
                        </div>



                        <div className='uk-grid' data-uk-grid>
                               <div className='uk-width-4-5'>

                               <h3 className='light uk-text-center header3 uk-text-bold header3 uk-margin-medium-left' >What can we do</h3>
                               </div>

                               <div className='uk-width-1-5'>

                               </div>
                        </div>
                      
                    </div>


                    <div className='uk-container Grid3' >

                  <div className='dots uk-padding'
                   
                   style={{ backgroundImage: `url(${dots})`,
                   backgroundRepeat: 'no-repeat',
                   backgroundSize: 'cover',
                   paddingBottom: '50px',
            
                   height: '85vh'
               }}
                  
                  >


                    <div className='uk-grid' data-uk-grid>
                      
                      <div className='uk-width-1-3@s '>
                      <Link to="/estatedevelopment"> 
                        <div uk-scrollspy="cls: uk-animation-slide-left-medium; repeat: true">
                              <div class="uk-card border  uk-card-default  uk-card-hover uk-card-body">
                           <img src= {home3}  className="uk-margin-top"/>
                           
                              <div className='uk-margin-medium-bottom uk-margin-medium-top'>
                              <h4 class=" uk-text-bold uk-margin-top grayText header4">Real Estate Development   </h4>
                            
                              </div>
                             </div>
                             </div></Link>



                         </div> 
                      

                         <div className='uk-width-1-3@s'>
                         <Link to="/Construction">   
                           <div uk-scrollspy="cls: uk-animation-slide-top-medium; repeat: true">        
                                 <div class="uk-card border uk-card-default uk-card-hover uk-card-body">
                            <img src= {home4} className="uk-margin-top"/>
                           
                           <div className='uk-margin-medium-bottom uk-margin-medium-top'>
                            <h4 class=" uk-text-bold uk-margin-top grayText header4">Construction  </h4>
                            
                            </div>
                          </div>

                          </div>
                          </Link>

                         </div>



                         <div className='uk-width-1-3@s'>
                         <Link to="/investment">
                            <div uk-scrollspy="cls: uk-animation-slide-right-medium; repeat: true">
                         <div class="uk-card border uk-card-default uk-card-hover uk-card-body">
                         <img src= {home6}  className="uk-margin-top"/>
                           
                           <div className='uk-margin-medium-bottom uk-margin-medium-top'>
                            <h4 class=" uk-text-bold uk-margin-top grayText header4">Investment  </h4>
                            
                            </div>
                         </div>
                         </div>  </Link>

                    </div>


                    </div>



                    


                    <div className='uk-grid' data-uk-grid>

                       
                         <div className='uk-width-1-3@s'>
                         <Link to="/remodelling"> 
                           <div uk-scrollspy="cls: uk-animation-slide-left-medium; repeat: true">
                              <div class="uk-card border uk-card-default  uk-card-hover uk-card-body">
                           <img src= {home2} />
                           
                            <div className='uk-margin-medium-bottom uk-margin-medium-top'>
                            <h4 class=" uk-text-bold uk-margin-top grayText header4">Remodelling  </h4>
                            
                            </div>
                         </div>
                         </div></Link>
  </div>




                         <div className='uk-width-1-3@s'>
                           
                         <Link to="/infrastructure"> 
                         <div uk-scrollspy="cls: uk-animation-slide-bottom-medium; repeat: true">
                              <div class="uk-card border uk-card-default uk-card-hover uk-card-body">
                              <img src= {home1} />
                              
                              <div className='uk-margin-medium-bottom uk-margin-medium-top'>
                                <h4 class=" uk-text-bold uk-margin-top grayText header4">Infrastructure  </h4>
                                
                                </div>
                                </div>
                            </div></Link>
                         </div>







                         <div className='uk-width-1-3@s'>
                         <Link to="/investment"> 
                         <div uk-scrollspy="cls: uk-animation-slide-right-medium; repeat: true">
                         <div class="uk-card border uk-card-default uk-card-hover uk-card-body">
                         <img src= {home6} />
                           
                           <div className='uk-margin-medium-bottom uk-margin-medium-top'>
                            <h4 class=" uk-text-bold uk-margin-top grayText header4">Investment  </h4>
                            
                            </div>
                         </div>
                         </div></Link>
                    </div>

                    </div>



                    </div>





                    </div>
       
            </div>
       </div>
)



export default Fourth_Pane