import react from 'react';
import stats1 from '../../../images/stats1.png';
import stats2 from '../../../images/stats2.png';
import stats3 from '../../../images/stats3.png';
import stats4 from '../../../images/stats4.png';
import { Link } from "react-router-dom";
import vNight2 from './../../../images/vNight2.jpg'
import wuye from './../../../images/wuye.jpg'


const EightPane = () =>(

          <div>
           
            {/***Desktop**/}
           <div className=" uk-visible@s uk-padding-large"
           
           style={{ backgroundImage: `url(${wuye})`,
           backgroundRepeat: 'no-repeat',
           backgroundSize: 'cover',
           paddingBottom: '50px',
           paddingTop: '100px',
           height: '100vh',
           backgroundAttachment: 'fixed',
       }}
           
           
           
           
           >
                     <div className="uk-container">
                     <div className="uk-padding">
                           <h5 className="uk-text-center lightText  uk-text-small ">MORE ABOUT US</h5>
                           <h2 className="header2 lightText uk-text-center">Toolbox Ateleirs Stats & Facts</h2>
                           <div className="uk-text-center testyDesc">
                           <p className="lightText" ></p></div>

                     </div>


              <div>
                      
                             <div className=" statsContainer  ">               
                        <div class="uk-grid-large uk-child-width-expand@s uk-text-center" data-uk-grid>

                        <div>
                                <div class="uk-margin-xlarge-top@s bIcon">
                                    <img src={stats1}  className="statsImg"/>
                                    
                                    <h4 className="headerBold"> Increase in Investment </h4>
                                    
                                </div>
                            </div>

    
                                <div class="uk-margin-xlarge-top@s bIcon">
                                    <img src={stats4}  className="statsImg"/>
                                   
                                    <h4 className="headerBold"> 6713  Total hours in Site </h4>
                                    
                                </div>


                                <div class="uk-margin-xlarge-top@s bIcon">
                                    <img src={stats2}  className="statsImg"/>
                                    
                                    <h4 className="headerBold"> Projects Completed</h4>
                                    
                                </div>



                                <div class="uk-margin-xlarge-top@s bIcon">
                                    <img src={stats3}  className="statsImg"/>
                                    
                                    <h4 className="headerBold"> Satisfied Clients</h4>
                                    
                                </div>

                        </div>


                                 <div className="uk-margin-large-top">
                                        <div class="uk-flex uk-flex-center">
                                       <Link to="/contactus" > <div class="btn-light" uk-scrollspy="cls: uk-animation-slide-left; repeat: true">Get in Touch</div> </Link>
                                       <Link to="/courses" > <div class=" btn-ghost-light uk-margin-left" uk-scrollspy="cls: uk-animation-slide-right; repeat: true">Recent Projects</div> </Link>
                                            
                                        </div>
                                 </div>
                        </div>
                </div>
         </div>

           </div>
        






           {/**Mobile***/}
           <div className=" uk-hidden@s uk-padding-large"
           
           style={{ backgroundImage: `url(${vNight2})`,
           backgroundRepeat: 'no-repeat',
           backgroundSize: 'cover',
           paddingBottom: '50px',
           paddingTop: '100px',
           height: 'auto',
           backgroundAttachment: 'fixed',
       }}
           
           
           
           
           >
                     <div className="uk-container">
                     <div className="uk-padding">
                           <h5 className="uk-text-center lightText  uk-text-small ">MORE ABOUT US</h5>
                           <h1 className="header1 lightText uk-text-center">Toolbox Ateleirs Stats & Facts</h1>
                           <div className="uk-text-center testyDesc">
                           <p className="lightText" ></p></div>

                     </div>


              <div>
                      
                             <div className="uk-margin-top statsContainer  ">               
                        <div class="uk-grid-large uk-child-width-expand@s uk-text-center" data-uk-grid>

                        <div>
                                <div class="uk-margin-xlarge-top@s bIcon">
                                    <img src={stats1}  className="statsImg"/>
                                    
                                    <h4 className="headerBold"> Increase in Investment </h4>
                                    
                                </div>
                            </div>

    
                                <div class="uk-margin-xlarge-top@s bIcon">
                                    <img src={stats4}  className="statsImg"/>
                                   
                                    <h4 className="headerBold"> 6713  Total hours in Site </h4>
                                    
                                </div>


                                <div class="uk-margin-xlarge-top@s bIcon">
                                    <img src={stats2} Projects className="statsImg"/>
                                    
                                    <h4 className="headerBold">  Completed</h4>
                                    
                                </div>



                                <div class="uk-margin-xlarge-top@s bIcon">
                                    <img src={stats3}  className="statsImg"/>
                                    
                                    <h4 className="headerBold"> Satisfied Clients</h4>
                                    
                                </div>

                        </div>


                                 <div className="uk-margin-large-top">
                                        <div class="uk-flex uk-flex-center">
                                       <Link to="/contactus" > <div class="btn-light" uk-scrollspy="cls: uk-animation-slide-left; repeat: true">Get in Touch</div> </Link>
                                       <Link to="/courses" > <div class=" btn-ghost-light uk-margin-left" uk-scrollspy="cls: uk-animation-slide-right; repeat: true">Recent Projects</div> </Link>
                                            
                                        </div>
                                 </div>
                        </div>
                </div>
         </div>

           </div>

           
           </div>

);


export default EightPane;
