import React from 'react'
import Layout from '../Layout'
import First_Pane from './Blocks/First_Pane'
import Second_Pane from './Blocks/Second_Pane'

const Investment = () => {
  return (
    <Layout>
     <First_Pane />
     <Second_Pane />
    </Layout>
  )
}

export default Investment