import react from 'react'
import Layout from '../../Layout'

import teamBg from './../../../images/teamBg.jpg'

import wuyeOne from  './../../../images/wuyeOne.png'
import wuyeTwo from  './../../../images/wuyeTwo.png'
import wuyeThree from  './../../../images/wuyeThree.png'
import wuyeFour from  './../../../images/wuyeFour.png'
import wuyeFive from  './../../../images/wuyeFive.png'
import wuyeSix from  './../../../images/wuyeSix.png'
import wuyeSeven from  './../../../images/wuyeSeven.png'


import valleyPort1 from './../../../images/valleyPort1.png'
import valleyPort2 from './../../../images/valleyPort2.png'
import valleyPort3 from './../../../images/valleyPort3.png'
import valleyPort4 from './../../../images/valleyPort4.png'
import valleyPort5 from './../../../images/valleyPort5.png'
import valleyPort6 from './../../../images/valleyPort6.png'
import valleyPort7 from './../../../images/valleyPort7.png'
import valleyPort8 from './../../../images/valleyPort8.png'
import valleyPort9 from './../../../images/valleyPort9.png'
import valleyPort10 from './../../../images/valleyPort10.png'
import valleyPort11 from './../../../images/valleyPort11.png'
import valleyPort12 from './../../../images/valleyPort12.png'



import valley1 from './../../../images/valley1.png'
import valley2 from './../../../images/valley2.png'
import valley3 from './../../../images/valley3.png'
import valley4 from './../../../images/valley4.png'
import valley5 from './../../../images/valley5.png'
import valley6 from './../../../images/valley6.png'
import valley7 from './../../../images/valley7.png'
import valley9 from './../../../images/valley9.png'
import valley10 from './../../../images/valley10.png'
import valley11 from './../../../images/valley11.png'
import valley12 from './../../../images/valley12.png'
import valley13 from './../../../images/valley13.png'



import boite1 from './../../../images/boite1.jpg'
import boite2 from './../../../images/boite2.jpg'
import boite3 from './../../../images/boite3.jpg'
import boite4 from './../../../images/boite4.jpg'
import boite5 from './../../../images/boite5.jpg'
import boite6 from './../../../images/boite6.jpg'
import boite7 from './../../../images/boite7.jpg'
import boite8 from './../../../images/boite8.jpg'
import boite9 from './../../../images/boite9.jpg'
import boite10 from './../../../images/boite10.jpg'
import boite11 from './../../../images/boite11.jpg'
import boite12 from './../../../images/boite12.jpg'
import boite13 from './../../../images/boite13.jpg'
import boite14 from './../../../images/boite14.jpg'


const realEstatePort = () => (
          <Layout>
                 <div>
                     <div className=' '>
                     <div uk-scrollspy="cls: uk-animation-slide-left-medium; repeat: true"
                      
                      style={{ backgroundImage: `url(${teamBg})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      paddingBottom: '50px',
                      height: '80vh',
                      borderRadius: "10px",
                      paddingTop:'60px'
                  }}
                    
                    >
                        <div className='uk-container '>
                            <div className='uk-margin-xlarge-top'>
                            <h1 className='uk-text-bold light header1 '>Our PortFolio</h1>
                            <h3 className='uk-text-bold header3  uk-margin-medium-top'>Real Estate Development</h3>
                            </div>



                       </div>
                    </div>
                     </div>



                             <div className='uk-container uk-margin-large-top'>

                                 <div className='uk-text-center'>
                                      <h2 className='header2'> Wuye Boite Residence</h2>
                                      
                                      <h4 className='uk-text-bold blueText uk-text-center'> <span className=''> COMPLETED   </span></h4>
                                 </div>

                                 </div>


                     <div className='customPortfolioContainer'> 
                           {/****/}
                             <div className='row uk-margin-medium-top'>
                               <div className='col-sm-3'>
                                    <div
                                        style={{ backgroundImage: `url(${wuyeFive})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        paddingBottom: '50px',
                                        height: '81vh',
                                        borderRadius: "3px",
                                        paddingTop:'40px'
                                    }}
                                    >

                                    </div>
                               </div>

                               <div className='col-sm-6'>
                                    <div className='row'>
                                        <div className='col-sm-6'>
                                        <div
                                        style={{ backgroundImage: `url(${wuyeTwo})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        paddingBottom: '50px',
                                        height: '40vh',
                                        borderRadius: "3px",
                                        paddingTop:'20px'
                                    }}
                                    >

                                    </div>
                                        </div>

                                        <div className='col-sm-6'>
                                        <div
                                        style={{ backgroundImage: `url(${wuyeOne})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        paddingBottom: '50px',
                                        height: '40vh',
                                        borderRadius: "3px",
                                        paddingTop:'20px'
                                    }}
                                    >

                                    </div>
                                        </div>
                                    </div>



                                    <div className='row uk-margin-small-top'>
                                        <div className='col-sm-6'>
                                        <div
                                        style={{ backgroundImage: `url(${wuyeFour})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        paddingBottom: '50px',
                                        height: '40vh',
                                        borderRadius: "3px",
                                        paddingTop:'20px'
                                    }}
                                    >

                                    </div>
                                        </div>

                                        <div className='col-sm-6'>
                                        <div
                                        style={{ backgroundImage: `url(${wuyeSeven})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        paddingBottom: '50px',
                                        height: '40vh',
                                        borderRadius: "3px",
                                        paddingTop:'20px'
                                    }}
                                    >

                                    </div>
                                        </div>
                                    </div>
                               </div>


                               <div className='col-sm-3'>
                               <div
                                        style={{ backgroundImage: `url(${wuyeSix})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        paddingBottom: '50px',
                                        height: '81vh',
                                        borderRadius: "3px",
                                        paddingTop:'40px'
                                    }}
                                    >

                                    </div>
                               </div>
                        </div>

                        </div>
                            

                        <div className='uk-container uk-margin-xlarge-top'>

                                <div className='uk-text-center'>
                                    <h2 className='header2'> Valley Court </h2>
                                    
                                    <h4 className='uk-text-bold blueText uk-text-center'> <span className=''> ON GOING   </span></h4>
                                </div>
                             </div>





                             <div className='customPortfolioContainer'>
                             <h6 className='uk-text-bold blueText uk-text-center'> <span className=' uk-padding'> THE PROMISE   </span></h6>
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                        <div
                                        style={{ backgroundImage: `url(${valleyPort1})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        paddingBottom: '50px',
                                        height: '40vh',
                                        borderRadius: "3px",
                                        paddingTop:'40px'
                                    }}
                                    >

                                    </div>
                                        </div>

                                        <div className='col-sm-4'>
                                        <div
                                        style={{ backgroundImage: `url(${valleyPort2})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        paddingBottom: '50px',
                                        height: '40vh',
                                        borderRadius: "3px",
                                        paddingTop:'40px'
                                    }}
                                    >

                                    </div>
                                        </div>

                                        <div className='col-sm-4'>
                                        <div
                                        style={{ backgroundImage: `url(${valleyPort3})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        paddingBottom: '50px',
                                        height: '40vh',
                                        borderRadius: "3px",
                                        paddingTop:'40px'
                                    }}
                                    >

                                    </div>
                                        </div>
                                    </div>





                                    <div className='uk-margin-small-top'>
                                       <div className='row '>
                                        <div className='col-sm-4'>
                                        <div
                                        style={{ backgroundImage: `url(${valley1})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        paddingBottom: '50px',
                                        height: '40vh',
                                        borderRadius: "3px",
                                        paddingTop:'40px'
                                    }}
                                    >

                                    </div>
                                        </div>

                                        <div className='col-sm-4'>
                                        <div
                                        style={{ backgroundImage: `url(${valley2})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        paddingBottom: '50px',
                                        height: '40vh',
                                        borderRadius: "3px",
                                        paddingTop:'40px'
                                    }}
                                    >

                                    </div>
                                        </div>

                                        <div className='col-sm-4'>
                                        <div
                                        style={{ backgroundImage: `url(${valley3})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        paddingBottom: '50px',
                                        height: '40vh',
                                        borderRadius: "3px",
                                        paddingTop:'40px'
                                    }}
                                    >

                                    </div>
                                        </div>
                                    </div>
                                    </div>



                                  


                                    <div className='uk-margin-small-top'>
                                       <div className='row '>
                                        <div className='col-sm-4'>
                                        <div
                                        style={{ backgroundImage: `url(${valley4})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        paddingBottom: '50px',
                                        height: '40vh',
                                        borderRadius: "3px",
                                        paddingTop:'40px'
                                    }}
                                    >

                                    </div>
                                        </div>

                                        <div className='col-sm-4'>
                                        <div
                                        style={{ backgroundImage: `url(${valley5})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        paddingBottom: '50px',
                                        height: '40vh',
                                        borderRadius: "3px",
                                        paddingTop:'40px'
                                    }}
                                    >

                                    </div>
                                        </div>

                                        <div className='col-sm-4'>
                                        <div
                                        style={{ backgroundImage: `url(${valley6})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        paddingBottom: '50px',
                                        height: '40vh',
                                        borderRadius: "3px",
                                        paddingTop:'40px'
                                    }}
                                    >

                                    </div>
                                        </div>
                                    </div>
                                    </div>


                                  <div className='uk-margin-large-top'>
                                  <h6 className='uk-text-bold blueText uk-text-center'> <span className=' uk-padding'> THE PROCESS   </span></h6>
                             
                                  </div>



                                 
                         
                             <div className='row uk-margin-medium-top'>
                               <div className='col-sm-3'>
                                    <div
                                        style={{ backgroundImage: `url(${valley7})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        paddingBottom: '50px',
                                        height: '81vh',
                                        borderRadius: "3px",
                                        paddingTop:'40px'
                                    }}
                                    >

                                    </div>
                               </div>

                               <div className='col-sm-6'>
                                    <div className='row'>
                                        <div className='col-sm-6'>
                                        <div
                                        style={{ backgroundImage: `url(${valley9})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        paddingBottom: '50px',
                                        height: '40vh',
                                        borderRadius: "3px",
                                        paddingTop:'20px'
                                    }}
                                    >

                                    </div>
                                        </div>

                                        <div className='col-sm-6'>
                                        <div
                                        style={{ backgroundImage: `url(${valley10})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        paddingBottom: '50px',
                                        height: '40vh',
                                        borderRadius: "3px",
                                        paddingTop:'20px'
                                    }}
                                    >

                                    </div>
                                        </div>
                                    </div>



                                    <div className='row uk-margin-small-top'>
                                        <div className='col-sm-6'>
                                        <div
                                        style={{ backgroundImage: `url(${valley11})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        paddingBottom: '50px',
                                        height: '40vh',
                                        borderRadius: "3px",
                                        paddingTop:'20px'
                                    }}
                                    >

                                    </div>
                                        </div>

                                        <div className='col-sm-6'>
                                        <div
                                        style={{ backgroundImage: `url(${valley12})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        paddingBottom: '50px',
                                        height: '40vh',
                                        borderRadius: "3px",
                                        paddingTop:'20px'
                                    }}
                                    >

                                    </div>
                                        </div>
                                    </div>
                               </div>


                               <div className='col-sm-3'>
                               <div
                                        style={{ backgroundImage: `url(${valley13})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        paddingBottom: '50px',
                                        height: '81vh',
                                        borderRadius: "3px",
                                        paddingTop:'40px'
                                    }}
                                    >

                                    </div>
                               </div>
                        </div>




                        <div className='uk-container uk-margin-xlarge-top'>

                                <div className='uk-text-center'>
                                    <h2 className='header2'> Boite 311 Residence </h2>
                                    
                                    <h4 className='uk-text-bold blueText uk-text-center'> <span className=''> ON GOING   </span></h4>
                                    
                                <h6 className='uk-text-bold blueText uk-text-center'> <span className=''> THE PROMISE   </span></h6>
                                </div>
                             </div>




                         <div className='row uk-margin-top'>
                            <div className='col-sm-6'>
                            <div
                                        style={{ backgroundImage: `url(${boite1})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        paddingBottom: '50px',
                                        height: '60vh',
                                        borderRadius: "3px",
                                        paddingTop:'40px'
                                    }}
                                    >

                                    </div>
                            </div>

                            <div className='col-sm-6'>
                            <div
                                        style={{ backgroundImage: `url(${boite2})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        paddingBottom: '50px',
                                        height: '60vh',
                                        borderRadius: "3px",
                                        paddingTop:'40px'
                                    }}
                                    >

                                    </div>
                            </div>
                         </div>






                         <div className='row uk-margin-small-top'>
                            <div className='col-sm-4'>
                            <div
                                        style={{ backgroundImage: `url(${boite3})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        paddingBottom: '50px',
                                        height: '45vh',
                                        borderRadius: "3px",
                                        paddingTop:'40px'
                                    }}
                                    >

                                    </div>
                            </div>

                            <div className='col-sm-4'>
                            <div
                                        style={{ backgroundImage: `url(${boite4})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        paddingBottom: '50px',
                                        height: '45vh',
                                        borderRadius: "3px",
                                        paddingTop:'40px'
                                    }}
                                    >

                                    </div>
                            </div>


                            <div className='col-sm-4'>
                            <div
                                        style={{ backgroundImage: `url(${boite5})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        paddingBottom: '50px',
                                        height: '45vh',
                                        borderRadius: "3px",
                                        paddingTop:'40px'
                                    }}
                                    >

                                    </div>
                            </div>
                         </div>

                         <div className='uk-margin-large-top'>
                                <h6 className='uk-text-bold blueText uk-text-center'> <span className=''> THE PROCESS   </span></h6>
                                </div>



                               <div className='row uk-margin-medium-top'>
                                     <div className='col-sm-3'>
                                     <div
                                        style={{ backgroundImage: `url(${boite6})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        paddingBottom: '50px',
                                        height: '45vh',
                                        borderRadius: "3px",
                                        paddingTop:'40px'
                                    }}
                                    >

                                    </div>
                                     </div>

                                     <div className='col-sm-3'>
                                     <div
                                        style={{ backgroundImage: `url(${boite8})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        paddingBottom: '50px',
                                        height: '45vh',
                                        borderRadius: "3px",
                                        paddingTop:'40px'
                                    }}
                                    >

                                    </div>
                                     </div>

                                     <div className='col-sm-3'>
                                     <div
                                        style={{ backgroundImage: `url(${boite9})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        paddingBottom: '50px',
                                        height: '45vh',
                                        borderRadius: "3px",
                                        paddingTop:'40px'
                                    }}
                                    >

                                    </div>
                                     </div>

                                     <div className='col-sm-3'>
                                     <div
                                        style={{ backgroundImage: `url(${boite10})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        paddingBottom: '50px',
                                        height: '45vh',
                                        borderRadius: "3px",
                                        paddingTop:'40px'
                                    }}
                                    >

                                    </div>
                                     </div>
                               </div>




                             </div>














                 </div>

              </Layout>

)

export default realEstatePort