import react from 'react'
import sectionBg2 from './../../images/sectionbg2.png'
import toolboxvid from './../../images/toolboxvid.mp4'
import coreIcon from './../../images/coreIcon.png'
import integrityIcon from './../../images/integrityIcon.png'
import partnershipIcon from './../../images/partnershipIcon.png'
import excellenceIcon from './../../images/excellenceIcon.png'
import innovativeIcon from './../../images/innovativeIcon.png'
import leadershipIcon from './../../images/leadershipIcon.png'
import {Link} from 'react-router-dom'

const Second_Pane = () =>(

                    <div >
                        <div
                          style={{ backgroundImage: `url(${sectionBg2})`,
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'cover',
                          paddingBottom: '50px',
                          paddingTop: '100px',
                          height: '70vh'
                      }}
                        >

                            <div className='uk-container '>
                          <div className='uk-grid' data-uk-grid>
                                <div className='uk-width-2-5@s'>
                                    <h2 className='header2'uk-scrollspy="cls: uk-animation-slide-top; repeat: true">We build modern, environmentally <span style={{color:'#3bcbee'}}>conscious homes.</span></h2>
                                  <p className='uk-margin-medium-top' uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true">We aim to not only build structures,but also make living space ecological friendly and smart with modern technology to provide incredible living
                                    experience to our happy customers We are poised to explore more avenues of enhancing our services and 
                                    making our clients even happier.</p>
                                </div>

                                <div className='uk-width-3-5@s'>
                                   
                                </div>
    
                          </div>
                          </div>
  </div>



                      <div className='uk-container uk-margin-large-top'>
                          <div className='uk-grid' data-uk-grid>
                            <div className='uk-width-1-3@s' >
                              <div>
                              <video src={toolboxvid} width="600" height="300" controls="controls" autoplay="true"  uk-scrollspy="cls: uk-animation-slide-top-small; repeat: true"/>

                                  </div>                                
                            </div>


                            <div className='uk-width-1-3@s'>

                            <div className='uk-grid  uk-grid-collapse' data-uk-grid >
                                <div className='uk-width-1-6' uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                                    <img src = {integrityIcon} className='checkIconHome'/>
                                </div>

                                <div className='uk-width-5-6' uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                                    
                                <h5 className='uk-text-bold'>Integrity</h5>
                                </div>
                             </div>   


                                 <div className='uk-grid uk-margin-small-top uk-grid-collapse' data-uk-grid >
                                <div className='uk-width-1-6' uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                                    <img src = {partnershipIcon} className='checkIconHome'/>
                                </div>

                                <div className='uk-width-5-6' uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                                    
                                <h5 className='uk-text-bold'>Partnership</h5>
                                </div>
                             </div>  



                                 <div className='uk-grid uk-margin-small-top uk-grid-collapse' data-uk-grid >
                                <div className='uk-width-1-6' uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                                    <img src = {excellenceIcon} className='checkIconHome'/>
                                </div>

                                <div className='uk-width-5-6' uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                                    
                                <h5 className='uk-text-bold'>Excellence</h5>
                                </div>
                             </div>   


                             <div className='uk-grid uk-margin-small-top uk-grid-collapse' data-uk-grid >
                                <div className='uk-width-1-6' uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                                    <img src = {innovativeIcon} className='checkIconHome'/>
                                </div>

                                <div className='uk-width-5-6' uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                                    
                                <h5 className='uk-text-bold'>Innovation</h5>
                                </div>
                             </div> 



                             <div className='uk-grid uk-margin-small-top uk-grid-collapse' data-uk-grid >
                                <div className='uk-width-1-6' uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                                    <img src = {leadershipIcon} className='checkIconHome'/>
                                </div>

                                <div className='uk-width-5-6' uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                                    
                                <h5 className='uk-text-bold'>Leadership</h5>
                                </div>
                             </div> 



                            </div>


                            <div className='uk-width-1-3@s uk-margin-large-top'>
                                <div className='uk-padding' uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                                  
                                    <Link to="/about" className='ctaBtn'>Learn More</Link>
                               </div>    
                            </div>
                      </div>
                      </div>







                    </div>
)


export default Second_Pane