import React from 'react'
import contactCover from './../../../images/contact-cover.jpg'

const First_Pane = () => 
(
    <div>
        
        <div 
            style={{ backgroundImage: `url(${contactCover})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            paddingBottom: '50px',
            paddingTop: '100px',
            height: '60vh'
        }}

        >
            <div className='uk-container uk-margin-xlarge-top'>
                <div className='uk-grid'>
                    <div className='uk-width-3-5@s'>

                    <h1 className='light uk-text-bold '>Your investment elevated,
one email away. </h1>
                    </div>

                    <div className='uk-width-2-5@s'>

                    </div>
                </div>


            </div>
           
          
        </div>






        
    </div>
  )


export default First_Pane