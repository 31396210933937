
import React, { Component } from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { Rating } from '@material-ui/lab';
import {
  Grid, Avatar, Box, IconButton
} from "@material-ui/core";

import KeyboardArrowRightTwoToneIcon from '@material-ui/icons/KeyboardArrowRightTwoTone';
import KeyboardArrowLeftTwoToneIcon from '@material-ui/icons/KeyboardArrowLeftTwoTone';

import "slick-carousel/slick/slick-theme.css";
import testifyOne from '../../../images/testifyOne.png'
import testifyTwo from '../../../images/testifyTwo.png'
import testifyThree from '../../../images/testifyThree.png'
import testifyFive from '../../../images/testifyFive.png'
import testifyFour from '../../../images/testifyFour.png'
import testifySix from '../../../images/testifySix.png'
import testifySeven from '../../../images/testifySeven.png'
import testifyEight from '../../../images/testifyEight.png'
import testifyNine from '../../../images/testifyNine.png'
import testifyTen from '../../../images/testifyTen.png'
import testifyEleven from '../../../images/testifyEleven.png'

import testify9 from '../../../images/testify9.png'
import testify10 from '../../../images/testify10.png'
import testify11 from '../../../images/testify11.png'
import testify12 from '../../../images/testify12.png'
import testify13 from '../../../images/testify13.png'
import testify14 from '../../../images/testify14.png'
import testify15 from '../../../images/testify15.png'









  
  export default class CustomArrows extends Component {
    render() {
      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        nextArrow: <IconButton>
            <KeyboardArrowRightTwoToneIcon />
        </IconButton>,
        prevArrow: <IconButton>
            <KeyboardArrowLeftTwoToneIcon />
        </IconButton>,


        responsive: [
          {
              breakpoint: 1024,
              settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  dots: true
              }
          },
          {
              breakpoint: 768,
              settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  initialSlide: 2
              }
          },
          {
              breakpoint: 480,
              settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
              }
          }
      ]



      };
      return (
        <div className="uk-container " >
             

                     <div className="uk-grid uk-margin-large-bottom" data-uk-grid>
                        <div className="uk-width-1-2@s">
                     
                        </div>

                        <div className="uk-width-1-2@s">
                          <div className="headerTestyBg" uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true">
                            <h5 className="uk-text-left  uk-text-small">TESTIMONIAL</h5>
                           <h5 className="header5 uk-text-left">Hear what our customers had to say about us</h5>
                           <div className="uk-text-left ">
                           <p className="uk-text-left"></p></div>
                        </div>
                        </div>




                 

                     </div>

          <Slider {...settings}>
            





            
           
               <div className="singleTestifyContainer">
                    <div class="uk-flex uk-flex-center">
                       <div class="testifyImage"><img src={testify12} /> </div>
                    </div>
                         
                         <div className="testifyTextBg">
                           <div class="uk-flex uk-flex-center ">
                            <p className="testifyText"> The entire team made building our home a smooth 
                            and pleasant experience. They also gave us daily feedback although the span 
                            of the project. I would highly recommend Sikram to anyone buying or building
                             a house. They are the ultimate professionals!</p>
                            </div>

                               <div class="uk-flex uk-flex-center "> <Rating readOnly defaultValue={5} /> </div>
                               <div className="testifyHeader uk-flex uk-flex-center"><h4>Adams Humphrey</h4></div>
                               <div className="testifyLocation uk-flex uk-flex-center"><h6> Maitama</h6></div>
                        </div>
               </div>




            
               <div className="singleTestifyContainer">
                    <div class="uk-flex uk-flex-center">
                       <div class="testifyImage"><img src={testify10} /> </div>
                    </div>
                         
                         <div className="testifyTextBg">
                           <div class="uk-flex uk-flex-center ">
                            <p className="testifyText"> I wasn’t in Nigeria, I was far away in Chicago, however,
                             they delivered on time, without breaching any of our agreements. I am really glad my family is moving in 
                             at the appointed time. Thank you guys.
 </p>
                            </div>

                               <div class="uk-flex uk-flex-center "> <Rating readOnly defaultValue={5} /> </div>
                               <div className="testifyHeader uk-flex uk-flex-center"><h4>  Ire Abolaji</h4></div>
                               <div className="testifyLocation uk-flex uk-flex-center"><h6>Chicago</h6></div>
                        </div>
               </div>




               <div className="singleTestifyContainer">
                    <div class="uk-flex uk-flex-center">
                       <div class="testifyImage"><img src={testify13} /> </div>
                    </div>
                         
                         <div className="testifyTextBg">
                           <div class="uk-flex uk-flex-center ">
                            <p className="testifyText">Toolbox Ateliers was a pleasure to work with! This was our first time building 
                            a home and they made the experience fun and hassle-free! Even after being in the house for
                             almost a year, they still check in on how we are doing on the property! A great team to work with!  </p>
                            </div>

                               <div class="uk-flex uk-flex-center "> <Rating readOnly defaultValue={5} /> </div>
                               <div className="testifyHeader uk-flex uk-flex-center"><h4>Haruna Isah Ahmed</h4></div>
                               <div className="testifyLocation uk-flex uk-flex-center"><h6>Lokogoma</h6></div>
                        </div>
               </div>
           
           






               <div className="singleTestifyContainer">
                    <div class="uk-flex uk-flex-center">
                       <div class="testifyImage"><img src={testify14} /> </div>
                    </div>
                         
                         <div className="testifyTextBg">
                           <div class="uk-flex uk-flex-center ">
                            <p className="testifyText">My boy is always excited to learn something new in Minecraft. He is captivated by what his teacher shows him, and tries to make
                             the designs by himself more and more. It’s good to see him so interested in a class.     </p>
                            </div>

                               <div class="uk-flex uk-flex-center "> <Rating readOnly defaultValue={5} /> </div>
                               <div className="testifyHeader uk-flex uk-flex-center"><h4>Hank</h4></div>
                               <div className="testifyLocation uk-flex uk-flex-center"><h6> Georgetown</h6></div>
                        </div>
               </div>





           









         



          

















          </Slider>
        </div>
      );
    }
  }