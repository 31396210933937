import React from 'react'

import teamBg from './../../../images/teamBg.jpg'

import teamMember from './../../../images/teamMember.jpg'

import team from './../../../images/team.png'

import team1 from './../../../images/team1.png'
import team2 from './../../../images/team2.png'
import team4 from './../../../images/team4.png'







const First_Pane = () => {
  return (
    <div>

     <div
        
        style={{ 
        backgroundImage: `-webkit-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${teamBg})`,
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${teamBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        paddingBottom: '50px',
        paddingTop: '100px',
        height: '80vh'
        }}
    
    
    >

        <div className='uk-container uk-margin-xlarge-top'>
            <h1 className='uk-text-bold header1 light'>Our Team</h1>
            <h3 className=' light uk-text-bold'>Meet the Toolbox Ateliens</h3>

        </div>


     



    </div>

      <div className='blueBg'>
        <div className='uk-container'>       
             <div className='uk-margin-top uk-margin-bottom'>

          <div class="uk-text-center" data-uk-grid>
                <div class="uk-width-1-6@s">
                </div>
                <div class="uk-width-4-6@s">
                    
                <div className='uk-text-center'>
                        <h3 className='light header3 uk-padding uk-text-bold'>We pride ourselves on our expertise as well as our company culture. 
                        No matter what place they work on, or what field they work in, 
                        every Toolbox Atelien shares a passion for excellence. </h3>
                    </div>
                </div>
                </div>

                <div class="uk-width-1-6@s">
                </div>
           </div>


          </div>

      </div>



      <div className='uk-container '>
            <div className='uk-grid' data-uk-grid>
             <div className='uk-width-1-2@s'>
               <div className='uk-margin-xlarge-top'>
                 <h3 className='header3 uk-text-bold'>Our Driving Force</h3>
                 <p className='uk-margin-top uk-margin-right'>We’re proud to be a diverse  team united 
                     by values including openness, positivity. No matter
                      how much our teams grow, we still feel like family.</p>
               </div>
             </div>

             <div className='uk-width-1-2@s'>
               <div className='uk-margin-large-top'
               
               style={{ 
                backgroundImage: `-webkit-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${teamMember})`,
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${teamMember})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                paddingBottom: '50px',
                paddingTop: '100px',
                height: '70vh'
                }}
               
               
            >
               </div>
             </div>
            </div>
        </div>



       






        <div className='customTeamWidth uk-margin-large-top'>
          
         <h3 className='header3 uk-text-bold'>The Team</h3>
        <div class="uk-grid-collapse uk-grid" data-uk-grid>
                            <div class="uk-width-1-4@s">
                               <div>
                                 <figure class="snip1104 red">
                                   <img src={team1} />
                                            <figcaption>
                                                 <h4>Arc. Mamud Imam <br/> <span className='teamTitle'> Chief Executive Officer</span></h4>
                                                          </figcaption>
                                                                  <a href="/teamMember1"></a>
                                                        </figure>
                                                            
                                                       
                                                        </div>
                                                     </div>
                                                     
                                                       <div class="uk-width-1-4@s">
                                                             <div>
                                                            <figure class="snip1104 red"> 
                                                              <img src={team} />
                                                                  <figcaption>
                                                                  <h4>Umar Mohammed <br/> <span className='teamTitle'> Project Manager</span></h4>
                                                 </figcaption>
                                                                 
                                                                  <a href="/teamMember2"></a>
                                                            </figure>
                                                          
                                                        </div>
                                                     </div>

                                                      
                                                     <div class="uk-width-1-4@s">
                                                             <div>
                                                            <figure class="snip1104 red"> 
                                                              <img src={team2} />
                                                                  <figcaption>
                                                                  <h4>Engr. Fabian Chinedu  <br/> <span className='teamTitle'> </span></h4>
                                                 </figcaption>
                                                                 
                                                                  <a href="/teamMember3"></a>
                                                            </figure>
                                                          
                                                        </div>
                                                     </div>

                                                      
                                                     <div class="uk-width-1-4@s">
                                                             <div>
                                                            <figure class="snip1104 red"> 
                                                              <img src={team4} />
                                                                  <figcaption>
                                                                    <h4>Suraj Umar  <span> Abdulhameed</span></h4>
                                                                  </figcaption>
                                                                  <a href="/teamMember4"></a>

                                                            </figure>
                                                            
                                                        </div>
                                                     </div>
                                                </div>

                                               </div>
























    </div>
  )
}

export default First_Pane