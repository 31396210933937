import react from 'react'
import valleyCourt from './../../../images/valleyCourt.jpeg'
import homeIcon from './../../../images/homeIcon.png'
import { Link } from 'react-router-dom'
import CountUp from './CountUpVally'


const Sixth_Pane = () => (
      <div>
              <div className='uk-container mediumContainer'>
             <div className=' uk-margin-xlarge-top'>
               <div className='uk-width-1-1'>
                     <p className='uk-text-small  uk-text-light'>WE'VE BEEN BUSY LATELY</p>
                     <h1 className='header1 uk-margin-remove-top'>RECENT PROJECTS</h1>
                 
                   </div>
             </div>
             </div>


             <div className='uk-grid uk-grid-collapse' data-uk-grid>
                    <div className='uk-width-3-5@s uk-margin-large-top'>
                         <div className='' 

                            style={{ backgroundImage: `url(${valleyCourt})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            paddingBottom: '50px',
                            paddingTop: '100px',
                            height: '50vh'

                            }} 
                         >
                               
                               <div className='uk-grid' data-uk-grid>
                                   <div className='uk-width-1-1 uk-text-center uk-margin-large-top'>
                                   <CountUp end={10} start={0} />
                                      
                                   </div>
                                   
                               </div>

                             
                         </div>
                    </div>

                    <div className='uk-width-2-5@s'>
                           <div className='ValleyCourtBg uk-padding'>
                               <h1 className='header1 light uk-margin-large-top'>Valley court</h1>
                               
                             <p className=' light uk-text-small uk-margin-small-right'>What makes a home? How can a building foster community? We see each residential project as an opportunity to 
                               answer these questions—to find ways that new architecture can reinforce a sense of place, enhance the civic realm, and provide for the wellbeing of the people who live there.</p>
                                  
                          <p className='uk-margin-small-top  light uk-text-small uk-text-bold uk-margin-small-right'>Valley Court is a 10 units semi detached duplex. 
                               </p>
                               
                                 <div className='uk-grid uk-grid-collapse uk-margin-medium-top' data-uk-grid>
                                         <div className='uk-width-1-5 uk-text-center' uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                                              <img src = {homeIcon} className='iconMedium' />
                                         </div>

                                         <div className='uk-width-4-5 uk-margin-small-top ' uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                                             <p className='  light '>Plot 827, Cad zone C02, Gwarinpa 1 District.</p>
                                         </div>

                                         <div class="uk-padding-small uk-margin-medium-top">
                                            <Link to="/valley_court"><div class="btnCustom btn-ghost-light uk-text-center " uk-scrollspy="cls: uk-animation-slide-left; repeat: true">View Project</div> </Link>
                       
                                            </div>
                                 </div>

                           </div>
                      </div>
             </div>


      </div>
)



export default Sixth_Pane