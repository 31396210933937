import React from 'react'
import portfolio1 from './../../../images/portfolio1.png'
import portfolio2 from './../../../images/portfolio2.png'
import portfolio3 from './../../../images/portfolio3.png'
import portfolio4 from './../../../images/portfolio4.png'
import portfolio5 from './../../../images/portfolio5.png'
import portfolio6 from './../../../images/portfolio6.png'
import portfolio7 from './../../../images/portfolio7.png'
import portfolio8 from './../../../images/portfolio8.png'
import remodel1 from './../../../images/remodel1.png'
import remodel2 from './../../../images/remodel2.png'
import remodel3 from './../../../images/remodel3.png'
import remodel4 from './../../../images/remodel4.png'
import remodel5 from './../../../images/remodel5.png'
import remodel6 from './../../../images/remodel6.png'
import remodel7 from './../../../images/remodel7.png'
import remodel8 from './../../../images/remodel8.png'


import construct2  from './../../../images/construct2.png'
import construct4  from './../../../images/construct4.png'
import construct5  from './../../../images/construct5.png'
import construct6  from './../../../images/construct6.png'
import construct7  from './../../../images/construct7.png'
import wuyeOne from  './../../../images/wuyeOne.png'
import wuyeTwo from  './../../../images/wuyeTwo.png'
import wuyeThree from  './../../../images/wuyeThree.png'
import wuyeFour from  './../../../images/wuyeFour.png'
import wuyeFive from  './../../../images/wuyeFive.png'
import wuyeSix from  './../../../images/wuyeSix.png'

import wuyeSeven from  './../../../images/wuyeSeven.png'
import valleyPort1 from './../../../images/valleyPort1.png'
import valleyPort2 from './../../../images/valleyPort2.png'
import valleyPort3 from './../../../images/valleyPort3.png'
import valleyPort4 from './../../../images/valleyPort4.png'
import valleyPort5 from './../../../images/valleyPort5.png'
import valleyPort6 from './../../../images/valleyPort6.png'
import valleyPort7 from './../../../images/valleyPort7.png'
import valleyPort8 from './../../../images/valleyPort8.png'
import valleyPort9 from './../../../images/valleyPort9.png'
import valleyPort10 from './../../../images/valleyPort10.png'
import valleyPort11 from './../../../images/valleyPort11.png'
import valleyPort12 from './../../../images/valleyPort12.png'


import boite1 from './../../../images/boite1.jpg'
import boite2 from './../../../images/boite2.jpg'
import boite3 from './../../../images/boite3.jpg'
import boite4 from './../../../images/boite4.jpg'
import boite5 from './../../../images/boite5.jpg'
import boite6 from './../../../images/boite6.jpg'
import boite7 from './../../../images/boite7.jpg'
import boite8 from './../../../images/boite8.jpg'
import boite9 from './../../../images/boite9.jpg'
import boite10 from './../../../images/boite10.jpg'
import boite11 from './../../../images/boite11.jpg'
import boite12 from './../../../images/boite12.jpg'
import boite13 from './../../../images/boite13.jpg'
import boite14 from './../../../images/boite14.jpg'




import min1 from './../../../images/min1.JPG'
import min2 from './../../../images/min2.JPG'
import min3 from './../../../images/min3.jpg'
import min4 from './../../../images/min4.jpg'
import min5 from './../../../images/min5.jpg'
import min7 from './../../../images/min7.JPG'
import min8 from './../../../images/min8.JPG'
import min9 from './../../../images/min9.JPG'


import haj1 from './../../../images/haj1.jpg'
import haj2 from './../../../images/haj2.jpg'
import haj3 from './../../../images/haj3.jpg'
import haj4 from './../../../images/haj1.jpg'


import const1 from './../../../images/const1.jpg'
import const2 from './../../../images/const2.jpg'
import const3 from './../../../images/const3.jpg'
import const4 from './../../../images/const4.jpg'

import const5 from './../../../images/const5.jpg'
import const6 from './../../../images/const6.jpg'
import const7 from './../../../images/const7.jpg'
import const8 from './../../../images/const8.jpg'




import react from 'react';
import boiteHero from './../../../images/boiteHero.jpg'
import valley2 from './../../../images/valley2.png'
import new3 from './../../../images/new3.jpg'
import sapphire2 from './../../../images/sapphire2.jpg'
import vc2 from './../../../images/vc2.jpg'
import gray_dots from './../../../images/gray_dots.jpg'
import int from './../../../images/int.jpg'
import new6 from './../../../images/new6.jpg'
import {Link } from 'react-router-dom'



const Second_Pane = () =>  (

    <div className='uk-margin-xlarge-top '>
      
              

        <div className='uk-container '>
                 <div className='uk-grid' data-uk-grid>
                   <div className='uk-width-1-2@s '>
                 <div uk-scrollspy="cls: uk-animation-slide-left-medium; repeat: true"
                      
                   style={{ backgroundImage: `url(${boiteHero})`,
                   backgroundRepeat: 'no-repeat',
                   backgroundSize: 'cover',
                   paddingBottom: '50px',
                   height: '90vh',
                   borderRadius: "10px"
               }}
                 
                 >
            
                 </div>

            <div className='uk-margin-top  uk-text-bold uk-text-center'>
                 <h3 className=' uk-text-bold'>
                    Real Estate Development                 </h3>
                 <Link to = "/port-real-estate">View Portfolio </Link>
            </div>



             </div>

             <div className='uk-width-1-2@s'>
                 <div className='uk-grid uk-grid-small' data-uk-grid>
                     <div className='uk-width-2-3 uk-text-right '>
                         <h5 className='blueText  uk-text-bold'>PORTFOLIO</h5>
                    </div>
                     <div className='uk-width-1-3'><hr></hr></div>
                     
                </div>
                
                <div> 
                    <h2 className='header2 uk-text-right uk-margin-top uk-text-bold'>Our Recent Works</h2>

                  <div className='uk-margin-medium-top' uk-scrollspy="cls: uk-animation-slide-right-medium; repeat: true"

                        style={{ backgroundImage: `url(${construct2})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        paddingBottom: '50px',
                        height: '90vh',
                        borderRadius: "10px"
                        }}
                  >

                  </div>
                  <div className='uk-margin-top projectLink uk-text-bold uk-text-center'>
                 <h3 className=' uk-text-bold'>
                 Construction
                 </h3>
                 <Link to = "/port-construction">View Portfolio </Link>
            </div>



                </div>
             </div>
        </div>
   

            <div className='uk-margin-medium-top'>
               <div className='uk-grid uk-grid-small' data-uk-grid>
                  <div className='uk-width-1-3@s'>
                     <div   uk-scrollspy="cls: uk-animation-slide-left-medium; repeat: true"
                     style={{ backgroundImage: `url(${remodel3})`,
                     backgroundRepeat: 'no-repeat',
                     backgroundSize: 'cover',
                     paddingBottom: '50px',
                     height: '50vh',
                     borderRadius: "10px"
                     }}
                     
                      >

                     </div>


                     <div className='uk-margin-top projectLink uk-text-bold uk-text-center'>
                 <h3 className=' uk-text-bold'>
                 Remodelling
                 </h3>
                 <Link to = "/port-remodelling">View Portfolio </Link>
            </div>
                  </div>

                  <div className='uk-width-1-3@s'>
                            <div
                               uk-scrollspy="cls: uk-animation-slide-bottom-medium; repeat: true"
                        style={{ backgroundImage: `url(${sapphire2})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        paddingBottom: '50px',
                        height: '50vh',
                        borderRadius: "10px"
                        }}
                            >

                            </div>

                            
         
                            <div className='uk-margin-top projectLink uk-text-bold uk-text-center'>
                 <h3 className=' uk-text-bold'>
                 Design
                 </h3>
                 <Link to = "/port-design">View Portfolio </Link>
            </div>

                  </div>

                  <div className='uk-width-1-3@s '>
                     <div
                     uk-scrollspy="cls: uk-animation-slide-right-medium; repeat: true"
                     style={{ backgroundImage: `url(${valley2})`,
                     backgroundRepeat: 'no-repeat',
                     backgroundSize: 'cover',
                     paddingBottom: '50px',
                     height: '50vh',
                     borderRadius: "10px"
                     }}
                     
                     >

                     </div>

                           
         
                     <div className='uk-margin-top projectLink uk-text-bold uk-text-center'>
                 <h3 className=' uk-text-bold'>
                 Infrastructure 
                 </h3>
                 <Link to = "/Valley_Court2"> View Portfolio</Link>
            </div>
                  </div>
             </div>
             </div>















































        <div class="uk-container  uk-container-center">  
                    <h3>Toolbox at its best</h3>
                <ul class="uk-tab" id="js-control" data-uk-tab="{connect:'#my-id4'}">
                    <li id="tab1"><a href="">Real  Estate Development</a></li>
                    <li id="tab2"><a href="">Remodelling</a></li>
                    <li id="tab3"><a href="">Design</a></li>
                    <li id="tab3"><a href="">Construction</a></li>
                </ul>
                <ul id="my-id4" class="uk-switcher uk-margin">

                    {/*First Tab*/}
                    <li>
                          <div className='' >
                               
                               <div className='uk-width-1-1'>
                               <h3 className='header3 uk-text-center uk-margin-top'>Wuye Boite Residence</h3>
                                       <h4 className='marginRemoveTop uk-text-center'> <span className=''> COMPLETED   </span></h4>


                                       <h3 className='uk-text-light uk-margin-remove-top uk-text-small uk-text-center'>THE PROMISE</h3>
                                       <div className='uk-grid ' data-uk-grid>
                                            <div className='uk-width-1-2 uk-text-right'>
                                           <div>
                                                 <img src= {wuyeOne} className="custImgSizeW"   uk-scrollspy="cls: uk-animation-slide-left; repeat: true"/>
                                            </div>
                                           </div>

                                           <div className='uk-width-1-2 uk-text-left'>
                                               <div>
                                                 <img src= {wuyeTwo}  className="custImgSizeW"  uk-scrollspy="cls: uk-animation-slide-right; repeat: true"/>
                                              </div>
                                           </div>
                                       </div>


                                       

                                       <h3 className='uk-text-light  uk-text-small uk-text-center'>THE PROCESS TO FINISH</h3>
                                       <div className='uk-grid' data-uk-grid>
                                            <div className='uk-width-1-2 uk-text-right'>
                                           <div>
                                                 <img src= {wuyeThree} className="custImgSizeW"   uk-scrollspy="cls: uk-animation-slide-top; repeat: true"/>
                                            </div>
                                           </div>

                                           <div className='uk-width-1-2 uk-text-left'>
                                               <div>
                                                 <img src= {wuyeFour} className="custImgSizeL"  uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true"/>
                                              </div>
                                           </div>
                                       </div>

                                       <div className='uk-grid' data-uk-grid>
                                            <div className='uk-width-1-2 uk-text-right'>
                                           <div>
                                                 <img src= {wuyeFive} className="custImgSizeL"  uk-scrollspy="cls: uk-animation-slide-top; repeat: true"/>
                                                 <img src= {wuyeSeven} className="custImgSizeL uk-margin-medium-top"  uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true"/>
                                            </div>
                                           </div>

                                           <div className='uk-width-1-2 uk-text-left'>
                                               <div>
                                                 <img src= {wuyeSix} className="custImgSizeL"  uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true"/>
                                              </div>
                                           </div>
                                       </div>








                                         {/**VALLEY COURT**/}
                                       <div className='uk-width-1-1 uk-margin-large-top'>
                               <h3 className='header3 uk-text-center uk-margin-top'>Valley Court</h3>
                                       <h4 className='marginRemoveTop uk-text-center'> <span className=''> ONGOING   </span></h4>


                                       <h3 className='uk-text-light uk-margin-remove-top uk-text-small uk-text-center'>THE PROMISE</h3>
                                       <div className='uk-grid' data-uk-grid>
                                            <div className='uk-width-1-2 uk-text-right'>
                                           <div>
                                                 <img src= {valleyPort1}  className="custImgSizeW"  uk-scrollspy="cls: uk-animation-slide-left; repeat: true"/>
                                            </div>
                                           </div>

                                           <div className='uk-width-1-2 uk-text-left'>
                                               <div>
                                                 <img src= {valleyPort2}  className="custImgSizeW"  uk-scrollspy="cls: uk-animation-slide-right; repeat: true"/>
                                              </div>
                                           </div>
                                       </div>


                                       <div className='uk-grid' data-uk-grid>
                                            <div className='uk-width-1-2 uk-text-right'>
                                           <div>
                                                 <img src= {valleyPort3} className="custImgSizeW"  uk-scrollspy="cls: uk-animation-slide-up; repeat: true"/>
                                            </div>
                                           </div>

                                           <div className='uk-width-1-2 uk-text-left'>
                                               <div>
                                                 <img src= {valleyPort4} className="custImgSizeW"  uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true"/>
                                              </div>
                                           </div>
                                       </div>



                                       

                                       <h3 className='uk-text-light  uk-text-small uk-text-center'>THE PROCESS </h3>
                                       <div className='uk-grid' data-uk-grid>
                                            <div className='uk-width-1-2 uk-text-right'>
                                           <div>
                                                 <img src= {valleyPort5} className="custImgSizeW"  uk-scrollspy="cls: uk-animation-slide-left; repeat: true"/>
                                            </div>
                                           </div>

                                           <div className='uk-width-1-2 uk-text-left'>
                                               <div>
                                                 <img src= {valleyPort6} className="custImgSizeW"  uk-scrollspy="cls: uk-animation-slide-right; repeat: true"/>
                                              </div>
                                           </div>
                                       </div>

                                       <div className='uk-grid' data-uk-grid>
                                            <div className='uk-width-1-2 uk-text-right'>
                                           <div>
                                                 <img src= {valleyPort7} className="custImgSizeL uk-margin-medium-top"  uk-scrollspy="cls: uk-animation-slide-top; repeat: true"/>
                                                 <img src= {valleyPort9} className="custImgSizeL uk-margin-medium-top"  uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true"/>
                                                 <img src= {valleyPort11} className="custImgSizeL uk-margin-medium-top"  uk-scrollspy="cls: uk-animation-slide-top; repeat: true"/>
                                                
                                            </div>
                                           </div>

                                           <div className='uk-width-1-2 uk-text-left'>
                                               <div>
                                                 <img src= {valleyPort8}   className="custImgSizeL uk-margin-medium-top"  uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true"/>
                                                 <img src= {valleyPort10}  className="custImgSizeL uk-margin-medium-top"  uk-scrollspy="cls: uk-animation-slide-top; repeat: true" />
                                                 <img src= {valleyPort12}  className="custImgSizeL uk-margin-medium-top"   uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true"/>
                                              </div>
                                           </div>
                                       </div>

                               </div>











                               <div className='uk-width-1-1 uk-margin-large-top'>
                               <h3 className='header3 uk-text-center uk-margin-top'>Boite 311 Residence</h3>
                                       <h4 className='marginRemoveTop uk-text-center'> <span className=''> ONGOING   </span></h4>


                                       <h3 className='uk-text-light uk-margin-remove-top uk-text-small uk-text-center'>THE PROMISE</h3>
                                       <div className='uk-grid' data-uk-grid>
                                            <div className='uk-width-1-2 uk-text-right'>
                                           <div>
                                                 <img src= {boite1}  className="custImgSizeW"  uk-scrollspy="cls: uk-animation-slide-left; repeat: true"/>
                                            </div>
                                           </div>

                                           <div className='uk-width-1-2 uk-text-left'>
                                               <div>
                                                 <img src= {boite2}  className="custImgSizeW"  uk-scrollspy="cls: uk-animation-slide-right; repeat: true"/>
                                              </div>
                                           </div>
                                       </div>


                                       <div className='uk-grid' data-uk-grid>
                                            <div className='uk-width-1-2 uk-text-right'>
                                           <div>
                                                 <img src= {boite3} className="custImgSizeW"  uk-scrollspy="cls: uk-animation-slide-up; repeat: true"/>
                                                 <img src= {boite5} className="custImgSizeW uk-margin-top"  uk-scrollspy="cls: uk-animation-slide-up; repeat: true"/>
                                           
                                            </div>
                                           </div>

                                           <div className='uk-width-1-2 uk-text-left'>
                                               <div>
                                                 <img src= {boite4} className="custImgSizeW"  uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true"/>
                                              </div>
                                           </div>
                                       </div>



                                       

                                       <h3 className='uk-text-light  uk-text-small uk-text-center'>THE PROCESS </h3>
                                       <div className='uk-grid' data-uk-grid>
                                            <div className='uk-width-1-2 uk-text-right'>
                                           <div>
                                                 <img src= {boite6} className="custImgSizeL"  uk-scrollspy="cls: uk-animation-slide-left; repeat: true"/>
                                            </div>
                                           </div>

                                           <div className='uk-width-1-2 uk-text-left'>
                                               <div>
                                                 <img src= {boite7} className="custImgSizeL"  uk-scrollspy="cls: uk-animation-slide-right; repeat: true"/>
                                              </div>
                                           </div>
                                       </div>

                                       <div className='uk-grid' data-uk-grid>
                                            <div className='uk-width-1-2 uk-text-right'>
                                           <div>
                                                 <img src= {boite8} className="custImgSizeL uk-margin-medium-top"  uk-scrollspy="cls: uk-animation-slide-top; repeat: true"/>
                                                 <img src= {boite9} className="custImgSizeL uk-margin-medium-top"  uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true"/>
                                                 <img src= {boite10} className="custImgSizeL uk-margin-medium-top"  uk-scrollspy="cls: uk-animation-slide-top; repeat: true"/>
                                                
                                            </div>
                                           </div>

                                           <div className='uk-width-1-2 uk-text-left'>
                                               <div>
                                                 <img src= {boite11}   className="custImgSizeL uk-margin-medium-top"  uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true"/>
                                                 <img src= {boite12}  className="custImgSizeL uk-margin-medium-top"  uk-scrollspy="cls: uk-animation-slide-top; repeat: true" />
                                                 <img src= {boite13}  className="custImgSizeL uk-margin-medium-top"   uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true"/>
                                              </div>
                                           </div>
                                       </div>

                               </div>



                               </div>
                             
                          </div>
                  
                    </li>
                    {/*First Tab ends here*/}
                   




                   {/*Second Tab STARTS here*/}
                   <li>
                                                                 {/**REMODELLING  Hajman Interior Remodelling**/}

                                                                 <div className='uk-width-1-1 uk-margin-large-top'>
                               <h3 className='header3 uk-text-center uk-margin-top'>Hajman Interior Remodelling</h3>
                                       <h4 className='marginRemoveTop uk-text-center'> <span className=''> COMPLETED   </span></h4>


                                       <h3 className='uk-text-light uk-margin-remove-top uk-text-small uk-text-center'>THE FORMER</h3>
                                       <div className='uk-grid' data-uk-grid>
                                            <div className='uk-width-1-2 uk-text-right'>
                                           <div>
                                                 <img src= {remodel1}  className="custImgSizeW"  uk-scrollspy="cls: uk-animation-slide-left; repeat: true"/>
                                            </div>
                                           </div>

                                           <div className='uk-width-1-2 uk-text-left'>
                                               <div>
                                                 <img src= {remodel2}  className="custImgSizeW"  uk-scrollspy="cls: uk-animation-slide-right; repeat: true"/>
                                              </div>
                                           </div>
                                       </div>

                                       <h3 className='uk-text-light  uk-text-small uk-text-center'>THE PROMISE</h3>
                                    
                                       <div className='uk-grid' data-uk-grid>
                                            <div className='uk-width-1-2 uk-text-right'>
                                           <div>
                                                 <img src= {remodel3} className="custImgSizeW"  uk-scrollspy="cls: uk-animation-slide-up; repeat: true"/>
                                            </div>
                                           </div>

                                           <div className='uk-width-1-2 uk-text-left'>
                                               <div>
                                                 <img src= {remodel4} className="custImgSizeW"  uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true"/>
                                              </div>
                                           </div>
                                       </div>



                                       
                                       <h3 className='uk-text-light  uk-text-small uk-text-center'>THE PROCESS</h3>
                                       <div className='uk-grid' data-uk-grid>
                                            <div className='uk-width-1-2 uk-text-right'>
                                           <div>
                                                 <img src= {remodel5} className="custImgSizeW"  uk-scrollspy="cls: uk-animation-slide-left; repeat: true"/>
                                            </div>
                                           </div>

                                           <div className='uk-width-1-2 uk-text-left'>
                                               <div>
                                                 <img src= {remodel6} className="custImgSizeW"  uk-scrollspy="cls: uk-animation-slide-right; repeat: true"/>
                                              </div>
                                           </div>
                                       </div>

                                       <div className='uk-grid' data-uk-grid>
                                            <div className='uk-width-1-2 uk-text-right'>
                                           <div>
                                                 <img src= {remodel7} className="custImgSizeL uk-margin-medium-top"  uk-scrollspy="cls: uk-animation-slide-top; repeat: true"/>
                                                 
                                            </div>
                                           </div>

                                           <div className='uk-width-1-2 uk-text-left'>
                                               <div>
                                                 <img src= {remodel8}   className="custImgSizeL uk-margin-medium-top"  uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true"/>
                                                 </div>
                                           </div>
                                       </div>

                               </div>






                              {/**REMODELLING  Hajman Interior Remodelling**/}
                               <div className='uk-width-1-1 uk-margin-large-top'>
                               <h3 className='header3 uk-text-center uk-margin-top'> Toolbox  Residence Minna </h3>
                                       <h4 className='marginRemoveTop uk-text-center'> <span className=''> COMPLETED   </span></h4>


                                       <h3 className='uk-text-light uk-margin-remove-top uk-text-small uk-text-center'>THE FORMER</h3>
                                       <div className='uk-grid' data-uk-grid>
                                            <div className='uk-width-1-2 uk-text-right'>
                                           <div>
                                                 <img src= {min3}  className="custImgSizeW"  uk-scrollspy="cls: uk-animation-slide-left; repeat: true"/>
                                                 <img src= {min5} className="custImgSizeW uk-margin-medium-top"  uk-scrollspy="cls: uk-animation-slide-left; repeat: true"/>
                                           
                                            </div>
                                           </div>

                                           <div className='uk-width-1-2 uk-text-left'>
                                               <div>
                                                 <img src= {min4}  className="custImgSizeW"  uk-scrollspy="cls: uk-animation-slide-right; repeat: true"/>
                                              </div>
                                           </div>
                                       </div>

                                       <h3 className='uk-text-light  uk-text-small uk-text-center'>THE PROMISE</h3>
                                    
                                       <div className='uk-grid' data-uk-grid>
                                            <div className='uk-width-1-2 uk-text-right'>
                                           <div>
                                                 <img src= {min1} className="custImgSizeW"  uk-scrollspy="cls: uk-animation-slide-up; repeat: true"/>
                                            </div>
                                           </div>

                                           <div className='uk-width-1-2 uk-text-left'>
                                               <div>
                                                 <img src= {min2} className="custImgSizeW"  uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true"/>
                                              </div>
                                           </div>
                                       </div>



                                       
                                       <h3 className='uk-text-light  uk-text-small uk-text-center'>THE PROCESS</h3>
                                       <div className='uk-grid' data-uk-grid>
                                            <div className='uk-width-1-2 uk-text-right'>
                                           <div>
                                           <img src= {min7}   className="custImgSizeL "  uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true"/>
                                              
                                                </div>
                                           </div>

                                           <div className='uk-width-1-2 uk-text-left'>
                                               <div>
                                               <img src= {min8}   className="custImgSizeL "  uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true"/>
                                                </div>
                                           </div>
                                       </div>

                                       <div className='uk-grid' data-uk-grid>
                                            <div className='uk-width-1-2 uk-text-right'>
                                           <div>
                                                
                                            </div>
                                           </div>

                                           <div className='uk-width-1-2 uk-text-left'>
                                               <div>
                                                </div>
                                           </div>
                                       </div>

                               </div>

                    </li>

                   {/*Second Tab ENDS  here*/}








                   {/*Third Tab STARTS here*/}
                   <li>
                         
                         

                              {/**REMODELLING  Hajman Interior Remodelling**/}
                              <div className='uk-width-1-1 uk-margin-large-top'>

                                <h3 className='uk-text-light  header3 uk-text-center'>Hajmam Service apartment</h3>
                                      

                                       <div className='uk-grid uk-margin-medium-top' data-uk-grid>
                                            <div className='uk-width-1-2 uk-text-right '>
                                           <div>
                                                 <img src= {haj1}  className="custImgSizeW"  uk-scrollspy="cls: uk-animation-slide-left; repeat: true"/>
                                                 <img src= {haj2} className="custImgSizeW uk-margin-medium-top"  uk-scrollspy="cls: uk-animation-slide-left; repeat: true"/>
                                           
                                            </div>
                                           </div>

                                           <div className='uk-width-1-2 uk-text-left'>
                                               <div>
                                                 <img src= {haj3}  className="custImgSizeW"  uk-scrollspy="cls: uk-animation-slide-right; repeat: true"/>
                                                 <img src= {haj4} className="custImgSizeW uk-margin-medium-top"  uk-scrollspy="cls: uk-animation-slide-up; repeat: true"/>
                                           
                                              </div>
                                           </div>
                                       </div>
                                   



                                       
                                <h3 className='uk-text-light  header3 uk-margin-large-top uk-text-center'>Boite 311 residence</h3>
                                       
                                       <div className='uk-grid' data-uk-grid>
                                            <div className='uk-width-1-2 uk-text-right'>
                                           <div>
                                           <img src= {boite1}   className="custImgSizeL "  uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true"/>
                                           <img src= {boite2}   className="custImgSizeL uk-margin-medium-top"  uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true"/>
                                           <img src= {boite5}   className="custImgSizeL uk-margin-medium-top"  uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true"/>
                                          
                                            
                                                </div>
                                           </div>

                                           <div className='uk-width-1-2 uk-text-left'>
                                               <div>
                                               <img src= {boite3}   className="custImgSizeL "  uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true"/>
                                               <img src= {boite4}   className="custImgSizeL uk-margin-medium-top"  uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true"/>
                                          
                                            </div>
                                           </div>
                                       </div>

                                       <div className='uk-grid' data-uk-grid>
                                            <div className='uk-width-1-2 uk-text-right'>
                                           <div>
                                                
                                            </div>
                                           </div>

                                           <div className='uk-width-1-2 uk-text-left'>
                                               <div>
                                                </div>
                                           </div>
                                       </div>

                               </div>


                    </li>

                   {/*Third Tab ENDS  here*/}






                   

                   {/*Fourth Tab STARTS here*/}
                       {/*Fourth Tab STARTS here*/}
                       <li>
                         
                         

                         {/**CONSTRUCTION  Minna Boite Residence**/}
                         <div className='uk-width-1-1 uk-margin-large-top'>

                           <h3 className='uk-text-light  header3 uk-text-center'>Minna Boite Residence</h3>
                                 
                                    
                           <h3 className='uk-text-light  uk-text-small uk-text-center'>THE PROMISE</h3>
                                  <div className='uk-grid uk-margin-medium-top' data-uk-grid>
                                       <div className='uk-width-1-2 uk-text-right '>
                                      <div>
                                            <img src= {const1}  className="custImgSizeW"  uk-scrollspy="cls: uk-animation-slide-left; repeat: true"/>
                                            <img src= {const2} className="custImgSizeW uk-margin-medium-top"  uk-scrollspy="cls: uk-animation-slide-left; repeat: true"/>
                                      
                                       </div>
                                      </div>

                                      <div className='uk-width-1-2 uk-text-left'>
                                          <div>
                                            <img src= {const3}  className="custImgSizeW"  uk-scrollspy="cls: uk-animation-slide-right; repeat: true"/>
                                            <img src= {const4} className="custImgSizeW uk-margin-medium-top"  uk-scrollspy="cls: uk-animation-slide-up; repeat: true"/>
                                      
                                         </div>
                                      </div>
                                  </div>

                                  
                                  <h3 className='uk-text-light  uk-text-small uk-text-center'>THE PROCESS</h3>
                                  <div className='uk-grid uk-margin-medium-top' data-uk-grid>
                                       <div className='uk-width-1-2 uk-text-right '>
                                      <div>
                                            <img src= {const5}  className="custImgSizeW"  uk-scrollspy="cls: uk-animation-slide-left; repeat: true"/>
                                            <img src= {const6} className="custImgSizeW uk-margin-medium-top"  uk-scrollspy="cls: uk-animation-slide-left; repeat: true"/>
                                      
                                       </div>
                                      </div>

                                      <div className='uk-width-1-2 uk-text-left'>
                                          <div>
                                            <img src= {const7}  className="custImgSizeW"  uk-scrollspy="cls: uk-animation-slide-right; repeat: true"/>
                                            <img src= {const8} className="custImgSizeW uk-margin-medium-top"  uk-scrollspy="cls: uk-animation-slide-up; repeat: true"/>
                                      
                                         </div>
                                      </div>
                                  </div>
                              



                                  
                                 
                        

                          </div>


               </li>


                   {/*Fourth Tab ENDS  here*/}
                   
                </ul>
                
               

       </div>
        </div>

    </div>

  )


export default Second_Pane