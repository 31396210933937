import './App.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import AboutUsPage from './pages/AboutUsPage';
import Homepage from './pages/HomePage/homepage';
import estatedevelopment from './pages/Realestate_development/investment';
import Project_Boite311 from './pages/Project_Boite311/Project_Boite311';
import valleycourt from './pages/Valley_Court/valleycourt';
import Construction from './pages/Construction/Construction';
import Investment from './pages/Investment/Investment';
import Remodelling from './pages/Remodelling/Remodelling';
import Infrastructure from './pages/Infrastructure/Infrastructure';
import Portfolio from './pages/Portfolio/Portfolio';
import Contact from './pages/Contact/Contact'
import Projects from './pages/Projects/Projects'
import boiteAzure from './pages/Boite Azure/boiteAzure'
import BoiteSapphire from './pages/Boite Sapphire/boiteSapphire'
import Valley_Court2 from './pages/ValleyCourt2/valleycourt2'
import Team from './pages/TheTeam/Team'
import teamMember1 from './pages/TheTeam/First_Pane/teamMember1';
import teamMember2 from './pages/TheTeam/First_Pane/teamMember2';
import teamMember3 from './pages/TheTeam/First_Pane/teamMember3';
import teamMember4 from './pages/TheTeam/First_Pane/teamMember4';
import realEstatePort from './pages/Portfolio/Blocks/real-estate-portfolio'

import constructionPort from './pages/Portfolio/Blocks/constructionPort'
import portRemodelling from './pages/Portfolio/Blocks/port-remodelling'
import portDesign from './pages/Portfolio/Blocks/port-Design'

import Home2 from './pages/Home2/Home2';


import "react-toastify/dist/ReactToastify.css";




function App() {
  return (
    <div className="App">
        <Router >
          
          <Switch>
    
          <Route exact path="/home2" component={Homepage} />
          
          <Route exact path="/" component={Home2} />
          
          <Route  exact path="/teamMember4" component={teamMember4} />

            <Route  exact path="/About" component={AboutUsPage} />
            
            <Route  exact path="/estatedevelopment" component={estatedevelopment} />
            
            <Route  exact path="/boite311" component={Project_Boite311} />
            
            <Route  exact path="/valley_court" component={valleycourt} />
            
            <Route  exact path="/Construction" component={Construction} />
            
            <Route  exact path="/investment" component={Investment} />
            <Route  exact path="/remodelling" component={Remodelling} />
            
            <Route  exact path="/infrastructure" component={Infrastructure} />
            
            <Route  exact path="/Portfolio" component={Portfolio} />
            
            <Route  exact path="/Contact" component={Contact} />
            
            <Route  exact path="/Projects" component={Projects} />
            
            <Route  exact path="/boiteAzure" component={boiteAzure} />
            
            
            <Route  exact path="/BoiteSapphire" component={BoiteSapphire} />
            
            
            <Route  exact path="/Valley_Court2" component={Valley_Court2} />
            
            <Route  exact path="/team" component={Team} />
            
            <Route  exact path="/teamMember1" component={teamMember1} />
            
            <Route  exact path="/teamMember2" component={teamMember2} />
            
            <Route  exact path="/teamMember3" component={teamMember3} />
            
            <Route  exact path="/teamMember4" component={teamMember4} />
            
            <Route  exact path="/port-real-estate" component={realEstatePort} />
            
            <Route  exact path="/port-construction" component={constructionPort} />
            
            <Route  exact path="/port-remodelling" component={portRemodelling} />
            
            <Route  exact path="/port-design" component={portDesign} />

           

            

            
            
            
            

            
            
            

           

          </Switch>
        </Router>
    </div>
  );
}

export default App;


