import React from "react";
import { Link } from "react-router-dom";
import vNight2 from './../../../images/vNight2.jpg'



const FirstPane = () => (

    <div>
            <div
            style={{ backgroundImage: `url(${vNight2})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            paddingBottom: '50px',
            paddingTop: '100px',
            height: '100vh'
        }}>


            <div className="uk-container light uk-margin-xlarge-top">
                <div className="uk-width-2-3@s">
                <h2 className="header2 light">About Toolbox</h2>
                <p className="uk-margin-top">   
                We pride ourselves in providing a win-win investment for our partners with our
unique experience in infrastructure; international business solutions and
procurement. We are renowned for our impressive small but growing portfolio
and have gained a good reputation because we deliver a service that speaks
integrity and professionalism.
             </p>
                </div>


                <div className="uk-width-1-3@s">

                </div>





             
            </div>


          

            
        
        </div>
        </div>
)

export default FirstPane;