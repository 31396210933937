import React from 'react'
import investmentBg from './../../../images/investmentBg.jpg'
import barline from './../../../images/Bar_line.png'

const First_Pane = () => (
    <div>
         <div
            style={{ backgroundImage: `url(${investmentBg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            paddingBottom: '50px',
            paddingTop: '100px',
            height: '90vh'
        }}

        >

            <div className='uk-container'>
           <div className='uk-grid' data-uk-grid>
                           
                <div className='uk-width-1-2@s'>
                    <h1 className='light uk-text-bold uk-padding-small uk-margin-xlarge-top'>Investment</h1>
                    <img src= {barline} uk-scrollspy="cls: uk-animation-slide-left; repeat: true" className='uk-padding-small'/>
                    <p className=' light uk-padding-small'>As leading property developers with a track record of building quality
                     and developing great communities we offer a lucrative investment opportunity, giving you the opportunity
                      to be a part of our ongoing projects.</p>
                 </div>
                  <div className='uk-width-1-2@s'>

                 </div>
                 </div> 
           </div>
     
        </div>
    </div>
  )


export default First_Pane