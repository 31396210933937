import react from "react"
import Layout from "../Layout"
import First_Pane from "./First_Pane/First_Pane"


export default function (){
    return(
        <Layout >
            <First_Pane />
        </Layout>
    )
}