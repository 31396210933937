import React from "react";
import {Link} from 'react-router-dom'
import valley2 from './../../../images/valley2.png'
import valley3 from './../../../images/valley3.png'
import valley4 from './../../../images/valley4.png'
import valley5 from './../../../images/valley5.png'
import valley6 from './../../../images/valley6.png'
import valley7 from './../../../images/valley7.png'
import valley8 from './../../../images/valley8.png'
import valley9 from './../../../images/valley9.png'
import valley10 from './../../../images/valley10.png'
import quality from './../../../images/quality.png'

import gray_dots from './../../../images/gray_dots.jpg'



const Fourth_Pane = () => (
    
    <div>
        <div className="container uk-margin-large-top">
              <div className="uk-text-center">
                   <h3 className=" uk-text-bold">Valley Court Realizations</h3>
              </div>


           <div className="row uk-margin-large-top">
              <div className="col-sm-4">
                   <div
                      style={{ backgroundImage: `url(${valley2})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      paddingBottom: '50px',
                      paddingTop: '100px',
                      height: '45vh'
                      }}
                   
                   >

                   </div>
              </div>

              <div className="col-sm-4">
              <div
                      style={{ backgroundImage: `url(${valley4})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      paddingBottom: '50px',
                      paddingTop: '100px',
                      height: '45vh'
                      }}
                   
                   >

                   </div> </div>

              <div className="col-sm-4">
              <div
                      style={{ backgroundImage: `url(${valley3})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      paddingBottom: '50px',
                      paddingTop: '100px',
                      height: '45vh'
                      }}
                   
                   >

                   </div>
              </div>
           </div>



                  <div className="row uk-margin-top">
                      <div className="col-sm-6">
                             <div className="row">
                                  <div className="col-sm-6">
                                  <div
                                style={{ backgroundImage: `url(${valley5})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                paddingBottom: '50px',
                                paddingTop: '100px',
                                height: '45vh'
                                }}>

                               </div>
                                  </div>

                                  <div className="col-sm-6">
                                  <div className="uk-padding"
                                style={{ backgroundColor: `#3FC6EB`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                paddingBottom: '50px',
                                paddingTop: '100px',
                                height: '45vh'
                                }}>
                                    
                       <img src = {quality}  className="quality"/>
                       <h4 className="light">We don't compromise quality</h4>

                               </div>
                                  </div>
                             </div>
                      </div>


                      <div className="col-sm-6">
                      <div
                                    style={{ backgroundImage: `url(${valley6})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    paddingBottom: '50px',
                                    paddingTop: '100px',
                                    height: '45vh'
                                    }}>

                   </div>
                     </div>
                  </div>

                           
                           <div className="uk-text-center uk-margin-large-top">
                               <h1>The Process</h1>
                           </div>


                           <div className="uk-grid uk-grid-collapse" data-uk-grid>
                                <div className="uk-width-1-4@s">
                                <div
                                    style={{ backgroundImage: `url(${valley7})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    paddingBottom: '50px',
                                    paddingTop: '100px',
                                    height: '70vh'
                                    }}>
                                      </div>
                            </div>

                                <div className="uk-width-1-4@s">
                                <div
                                    style={{ backgroundImage: `url(${valley8})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    paddingBottom: '50px',
                                    paddingTop: '100px',
                                    height: '70vh'
                                    }}>
                                      </div>
                                </div>

                                <div className="uk-width-1-4@s">
                                <div
                                    style={{ backgroundImage: `url(${valley9})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    paddingBottom: '50px',
                                    paddingTop: '100px',
                                    height: '70vh'
                                    }}>
                                      </div>
                                </div>

                                <div className="uk-width-1-4@s">
                                <div
                                    style={{ backgroundImage: `url(${valley10})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    paddingBottom: '50px',
                                    paddingTop: '100px',
                                    height: '70vh'
                                    }}>
                                      </div>
                                </div>
                           </div>
  </div>

                 

                 


       {/*Next pane*/}
             <div className="uk-margin-large-top">
                 <div className=""
                 style={{ backgroundImage: `url(${gray_dots})`,
                 backgroundRepeat: 'no-repeat',
                 backgroundSize: 'cover',
                 paddingBottom: '50px',
               
                 height: '70vh'
                 }}>
                <div className="uk-grid" data-uk-grid>
                     <div className="uk-width-1-2@s">
                       <div className="uk-container uk-margin-large-top ">

                           <div className="uk-grid" data-uk-grid>
                              <div className="uk-width-1-5@s">

                              </div>


                              <div className="uk-width-3-5@s">
                              <h1 className="uk-text-bold uk-text-left light uk-padding">Let’s sit down
                               and talk about
                                 your new home</h1>
                              </div>


                              <div className="uk-width-1-5">

                              </div>

                           </div>

                           <div className="uk-grid" data-uk-grid>
                              <div className="uk-width-1-5">

                              </div>


                              <div className="uk-width-3-5">
                            <Link to="/contact"><p className="header1 uk-text-left light uk-padding">Contact Us
                               </p>  </Link>
                              </div>


                              <div className="uk-width-1-5">

                              </div>


                           </div>

                           </div>
                     </div>


                     <div className="uk-width-1-2@s">
                       <div
                         style={{ backgroundImage: `url(${valley5})`,
                         backgroundRepeat: 'no-repeat',
                         backgroundSize: 'cover',
                        
                       
                         height: '70vh'
                         }}
                       
                       >

                        </div>
                     </div>
                </div>
                </div>
             </div>















    </div>

)



export default Fourth_Pane