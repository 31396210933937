import react from 'react'
import {Link } from 'react-router-dom'
import Sadam from './../../images/Sadam.jpg'



const Third_Pane = () => (
                  <div className='uk-margin-xlarge-top'>

                          <div className='container'>
                          <div className="row  ">
                              <div className='borderRec'>

                                      <div className='row'>
                                            <div className='col-sm-4'>
                                            
                                            </div>

                                            <div className='col-sm-8'>
                                                <div className='uk-padding'>
                                            <h3 className='uk-text-bold'>We work together as a passionate group of people with love to design</h3>
                                            <p className='uk-margin-medium-top'>Diam arcu tristique nibh, nec egestas diam elit at nulla. Suspendisse potenti. In non lacinia 
                                                risus, ac tempor ipsum. Phasellus venenatis leo eu semper varius. </p>

                                                <p>Maecenas sit
                                                 amet molestie leo. Morbi vitae urna mauris. Nulla nec tortor vitae eros iaculis 
                                                 hendrerit aliquet non urna.</p>
                                                 
                                       <div className='uk-margin-medium-top '  uk-scrollspy="cls: uk-animation-slide-top; repeat: true">
                                            <Link to="/team" className=' ctaBtn'>Meet the team</Link></div>
                                                 </div>   
                                               </div>
                                      </div>
                              </div>   </div>



                          <div className="row  ">
                              <div className='col-sm-5'   uk-scrollspy="cls: uk-animation-fade; repeat: true">

                                  <div className='sadam'
                                    style={{ backgroundImage: `url(${Sadam})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    paddingBottom: '50px',
                                    paddingTop: '100px',
                                    height: '70vh',
                                    borderRadius:'10px'
                                }}
                                  >

                                  </div>
                              </div>

                              <div className='col-sm-7'>
                                  <div className=''
                                       
                                  
                                  >
                              
                                  </div>
                              </div>
                          </div>
                          </div>





                  </div>
)


export default Third_Pane
