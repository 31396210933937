import react from 'react'
import remodel7 from './../../../images/remodel7.png'
import remodel8 from './../../../images/remodel8.png'
import remodel1 from './../../../images/remodel1.png'
import remodel3 from './../../../images/remodel3.png'
import remodel2 from './../../../images/remodel2.png'
import remodel5 from './../../../images/remodel5.png'
import gray_dots from './../../../images/gray_dots.jpg'
import { Link } from 'react-router-dom'







const Third_Pane = () => (
    <div className='uk-margin-large-top'>
        
        <div className='container'>
            <div className='row'>
             <div className='col-sm-3'>
                     <div
                        style={{ backgroundImage: `url(${remodel7})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        paddingBottom: '10px',
                      
                        height: '80vh'
                    }}
                     
                     >
                         
                    </div>
             </div>

             <div className='col-sm-6'>
                <div className='row '>
                    <div className='col-sm-6'>
                    <div
                        style={{ backgroundImage: `url(${remodel1})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        paddingBottom: '10px',
                      
                        height: '39vh'
                    }}
                     
                     >
                         
                    </div>
                    </div>

                    <div className='col-sm-6'>
                    <div
                        style={{ backgroundImage: `url(${remodel3})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        paddingBottom: '10px',
                      
                        height: '39vh'
                    }}
                     
                     >
                         
                    </div>
                    </div>
                </div>





                <div className='row uk-margin-small-top'>
                    <div className='col-sm-6'>
                    <div
                        style={{ backgroundImage: `url(${remodel2})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        paddingBottom: '10px',
                      
                        height: '39vh'
                    }}
                     
                     >
                         
                    </div>
                    </div>

                    <div className='col-sm-6'>
                    <div
                        style={{ backgroundImage: `url(${remodel5})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        paddingBottom: '10px',
                      
                        height: '39vh'
                    }}
                     
                     >
                         
                    </div>
                    </div>
                </div>
               </div>


               <div className='col-sm-3'>
                      <div
                         style={{ backgroundImage: `url(${remodel8})`,
                         backgroundRepeat: 'no-repeat',
                         backgroundSize: 'cover',
                         paddingBottom: '10px',
                       
                         height: '80vh'
                     }}
                      >

                      
                      </div>
               </div>
               </div>
        </div>







 {/*Next pane*/}
 <div className="uk-margin-xlarge-top">
                 <div className=""
                 style={{ backgroundImage: `url(${gray_dots})`,
                 backgroundRepeat: 'no-repeat',
                 backgroundSize: 'cover',
                 paddingBottom: '50px',
               
                 height: '85vh'
                 }}>
                <div className="uk-grid" data-uk-grid>
                     <div className="uk-width-1-2@s">
                       <div className="uk-container uk-margin-xlarge-top ">

                           <div className="uk-grid" data-uk-grid>
                              <div className="uk-width-1-6">

                              </div>


                              <div className="uk-width-5-6">
                              <h2 className="header2 uk-text-bold uk-text-left light ">Let’s turn your  home to a new haven</h2>
                              </div>


                              <div className="uk-width-1-5">

                              </div>

                           </div>

                           <div className="uk-grid" data-uk-grid>
                              <div className="uk-width-1-5">

                              </div>


                              <div className="uk-width-3-5">
                            <Link to="/contact" className='ctaBtn'>Book Consultaion
                                </Link>
                              </div>


                              <div className="uk-width-1-5">

                              </div>


                           </div>

                           </div>
                     </div>


                     <div className="uk-width-1-2@s">
                       <div
                         style={{ backgroundImage: `url(${remodel5})`,
                         backgroundRepeat: 'no-repeat',
                         backgroundSize: 'cover',
                        
                       
                         height: '85vh'
                         }}
                       
                       >

                        </div>
                     </div>
                </div>
                </div>
             </div>
















    </div>
)



export default Third_Pane

