import Layout from "../Layout";
import React from "react";
import First_Pane from "./Blocks/First_pane";
import Second_Pane from './Blocks/Second_pane'
import Third_Pane from './Blocks/Third_pane'
import Fourth_Pane from "./Blocks/Fourth_Pane";


export default function Valley_Court2() {

    return (
        <Layout>
        <First_Pane />
            <Second_Pane />
            <Third_Pane />
            <Fourth_Pane />
        </Layout>

    )




}