import React from 'react'
import { Link } from 'react-router-dom'
import valley7 from './../../../images/valley7.png'
import valley12 from './../../../images/valley12.png'
import valley10 from './../../../images/valleyPort10.png'
import valley14 from './../../../images/valley14.png'
import vc2 from './../../../images/vc2.jpg'
import vc3 from './../../../images/vc3.jpg'

const Third_Pane = () => (

    <div className=' grayBg' >
            <div className='uk-container'>
            <div className='row uk-margin-large-top uk-margin-large-bottom' data-uk-grid>
                <div className='col-sm-3'>
                   <div
                       style={{ backgroundImage: `url(${valley7})`,
                       backgroundRepeat: 'no-repeat',
                       backgroundSize: 'cover',
                       paddingBottom: '50px',
                       paddingTop: '100px',
                       height: '70vh'
                   }}
                   
                   >

                   </div>
                </div>

                <div className='col-sm-6'>
                           <div className='row'>
                                <div className='col-6'>
                                <div
                                  style={{ backgroundImage: `url(${valley10})`,
                                  backgroundRepeat: 'no-repeat',
                                  backgroundSize: 'cover',
                                  paddingBottom: '50px',
                                  paddingTop: '100px',
                                  height: '34vh'
                              }}
                              
                              >
                                 </div>
                        </div>

                                <div className='col-6'>
                                <div
                                  style={{ backgroundImage: `url(${vc3})`,
                                  backgroundRepeat: 'no-repeat',
                                  backgroundSize: 'cover',
                                  paddingBottom: '50px',
                                  paddingTop: '100px',
                                  height: '34vh'
                              }} >

                            </div>
                                </div>
                           </div>


                           <div className='row uk-margin-top'>
                                <div className='col-6'>
                                <div
                                  style={{ backgroundImage: `url(${vc2})`,
                                  backgroundRepeat: 'no-repeat',
                                  backgroundSize: 'cover',
                                  paddingBottom: '50px',
                                  paddingTop: '100px',
                                  height: '34vh'
                              }}
                              
                              >
                                 </div>
                        </div>

                                <div className='col-6'>
                                <div
                                  style={{ backgroundImage: `url(${valley14})`,
                                  backgroundRepeat: 'no-repeat',
                                  backgroundSize: 'cover',
                                  paddingBottom: '50px',
                                  paddingTop: '100px',
                                  height: '34vh'
                              }} >

                            </div>
                                </div>
                           </div>




              </div>


              <div className='col-sm-3'>
              <div
                       style={{ backgroundImage: `url(${valley12})`,
                       backgroundRepeat: 'no-repeat',
                       backgroundSize: 'cover',
                       paddingBottom: '50px',
                       paddingTop: '100px',
                       height: '70vh'
                   }}
                   
                   >

                   </div>
               </div>

            </div>


</div>




         

    </div>

  )


export default Third_Pane