import React from 'react'
import First_Pane from './Blocks/First_Pane'
import Layout from './../Layout'
import Second_Pane from './Blocks/Second_Pane'

const Portfolio = () => {
  return (
    <Layout>
        <First_Pane/>
        <Second_Pane />
        
    </Layout>
  )
}

export default Portfolio