import React from "react";
import { Link } from "react-router-dom";
import heroBg from './../../../images/cover_image.JPG'

import heroBgSmall from './../../../images/heroBgSmall.jpg'
import haj1 from './../../../images/haj1.jpg'
import valley2 from './../../../images/valley2.png'

import slide2 from './../../../images/slide2.JPG'
import vNight2 from './../../../images/vNight2.jpg'
import firstImage from './../../../images/cover_image.JPG'





const FirstPane = () => (

    <div>

       {/***DESKTOP VIEW***/}
   {/***DESKTOP VIEW***/}
   <div className="uk-visible@s">
    <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" data-uk-slideshow="animation: push">

<ul class="uk-slideshow-items">

<li>
        <div class="uk-position-cover uk-animation-kenburns uk-animation-reverse uk-transform-origin-center-left">
            <img src={firstImage} alt="" uk-cover />
         
               
                <div class="uk-position-center uk-position-small uk-text-center">
                <h1 className="header1" style={{color:"#000"}} uk-slideshow-parallax="x: 100,-100">Toolbox Ateliers</h1>
                <p style={{backgroundColor:"#000",color:"#fff", opacity:"0.7", fontSize: "14px",  borderRadius:"5px", padding:"10px"}} uk-slideshow-parallax="x: 200,-200">
                    We design and build modern contemporary homes that are Luxurious and affordable</p>
            </div>
        
        </div>
    </li>



    <li>
        <div class="uk-position-cover uk-animation-kenburns uk-animation-reverse uk-transform-origin-center-left">
            <img src={haj1} alt="" uk-cover />
         
               
                <div class="uk-position-center uk-position-small uk-text-center">
                <h1 className="header1" uk-slideshow-parallax="x: 100,-100">Toolbox Ateliers</h1>
                <p style={{backgroundColor:"#000",color:"#fff", opacity:"0.7", fontSize: "14px",  borderRadius:"5px", padding:"10px"}} uk-slideshow-parallax="x: 200,-200">
                    Where quality homes meet your budget while exceeding your expectations</p>
            </div>
                

               
         
          
         
        </div>
    </li>
    <li>
        <div class="uk-position-cover uk-animation-kenburns uk-animation-reverse uk-transform-origin-top-right">
            <img src={slide2} alt="" uk-cover />
                
            <div class="uk-position-center uk-position-small uk-text-center">
                <h1 className="header1" uk-slideshow-parallax="x: 100,-100">Toolbox Ateliers</h1>
                <p style={{backgroundColor:"#000",color:"#fff", opacity:"0.7", width:"650px", fontSize: "14px",  borderRadius:"5px", padding:"10px"}} 
                uk-slideshow-parallax="x: 200,-200">From choosing your house designs to getting your keys. You 
                won't strain a muscle; We don’t  tell stories; we build great houses.

</p>
            </div>
        </div>
    </li>
    <li>
        <div class="uk-position-cover uk-animation-kenburns uk-animation-reverse uk-transform-origin-bottom-left">
            <img src={vNight2} alt="" uk-cover />
                
            <div class="uk-position-center uk-position-small uk-text-center">
                <h1 className="header1" uk-slideshow-parallax="x: 100,-100">Toolbox Ateliers</h1>
                <p style={{backgroundColor:"#3bcbee", fontSize: "14px",  borderRadius:"5px", padding:"10px"}} uk-slideshow-parallax="x: 200,-200">Where quality homes meet your pocket while exceeding your EGRITY & YOU
</p>
            </div>
        </div>
    </li>
</ul>

<a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-previous uk-slideshow-item="previous"></a>
<a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-next uk-slideshow-item="next"></a>

</div>


        </div>




          {/***MOBILE VIEW***/}
    <div className="uk-hidden@s">
    





        </div>
        </div>
)

export default FirstPane;