import react from 'react';
import boiteHero from './../../images/boiteHero.jpg'
import valley2 from './../../images/valley2.png'
import new3 from './../../images/new3.jpg'
import sapphire2 from './../../images/sapphire2.jpg'
import vc2 from './../../images/vc2.jpg'
import gray_dots from './../../images/gray_dots.jpg'
import int from './../../images/int.jpg'
import new6 from './../../images/new6.jpg'
import {Link } from 'react-router-dom'





const Fifth_Pane = () => (
      
    <div className='fifthUpPane '>

             <div className='uk-container'>
                 <div className='uk-grid' data-uk-grid>
                   <div className='uk-width-1-2@s '>
                 <div uk-scrollspy="cls: uk-animation-slide-left-medium; repeat: true"
                      
                   style={{ backgroundImage: `url(${boiteHero})`,
                   backgroundRepeat: 'no-repeat',
                   backgroundSize: 'cover',
                   paddingBottom: '50px',
                   height: '90vh',
                   borderRadius: "10px"
               }}
                 
                 >
            
                 </div>

            <div className='uk-margin-top projectLink uk-text-bold uk-text-center'>
                 <h3 className=' uk-text-bold'>
                 Boite 311 Residence
                 </h3>
                 <Link to = "/boite311">View Project </Link>
            </div>



             </div>

             <div className='uk-width-1-2@s'>
                 <div className='uk-grid uk-grid-small' data-uk-grid>
                     <div className='uk-width-2-3 uk-text-right '>
                         <h5 className='blueText  uk-text-bold'>Realizations</h5>
                    </div>
                     <div className='uk-width-1-3'><hr></hr></div>
                     
                </div>
                
                <div> 
                    <h2 className='header2 uk-text-right uk-margin-top uk-text-bold'>Our Latest Works</h2>

                  <div className='uk-margin-medium-top' uk-scrollspy="cls: uk-animation-slide-right-medium; repeat: true"

                        style={{ backgroundImage: `url(${valley2})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        paddingBottom: '50px',
                        height: '60vh',
                        borderRadius: "10px"
                        }}
                  >

                  </div>
                  <div className='uk-margin-top projectLink uk-text-bold uk-text-center'>
                 <h3 className=' uk-text-bold'>
                 Valley Court
                 </h3>
                 <Link to = "/valley_court">View Project </Link>
            </div>



                </div>
             </div>
        </div>
   

            <div className='uk-margin-medium-top'>
               <div className='uk-grid uk-grid-small' data-uk-grid>
                  <div className='uk-width-1-3@s'>
                     <div   uk-scrollspy="cls: uk-animation-slide-left-medium; repeat: true"
                     style={{ backgroundImage: `url(${new6})`,
                     backgroundRepeat: 'no-repeat',
                     backgroundSize: 'cover',
                     paddingBottom: '50px',
                     height: '50vh',
                     borderRadius: "10px"
                     }}
                     
                      >

                     </div>


                     <div className='uk-margin-top projectLink uk-text-bold uk-text-center'>
                 <h3 className=' uk-text-bold'>
                 Boite Azure
                 </h3>
                 <Link to = "/boiteAzure">View Project </Link>
            </div>
                  </div>

                  <div className='uk-width-1-3@s'>
                            <div
                               uk-scrollspy="cls: uk-animation-slide-bottom-medium; repeat: true"
                        style={{ backgroundImage: `url(${sapphire2})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        paddingBottom: '50px',
                        height: '50vh',
                        borderRadius: "10px"
                        }}
                            >

                            </div>

                            
         
                            <div className='uk-margin-top projectLink uk-text-bold uk-text-center'>
                 <h3 className=' uk-text-bold'>
                 Boite Sapphire
                 </h3>
                 <Link to = "/BoiteSapphire">View Project </Link>
            </div>

                  </div>

                  <div className='uk-width-1-3@s '>
                     <div
                     uk-scrollspy="cls: uk-animation-slide-right-medium; repeat: true"
                     style={{ backgroundImage: `url(${valley2})`,
                     backgroundRepeat: 'no-repeat',
                     backgroundSize: 'cover',
                     paddingBottom: '50px',
                     height: '50vh',
                     borderRadius: "10px"
                     }}
                     
                     >

                     </div>

                           
         
                     <div className='uk-margin-top projectLink uk-text-bold uk-text-center'>
                 <h3 className=' uk-text-bold'>
                 Valley Court II
                 </h3>
                 <Link to = "/Valley_Court2"> View Project</Link>
            </div>
                  </div>
             </div>
             </div>

    </div>





       {/*Next pane*/}
       <div className="uk-margin-xlarge-top">
                 <div className=""
                 style={{ backgroundImage: `url(${gray_dots})`,
                 backgroundRepeat: 'no-repeat',
                 backgroundSize: 'cover',
                 paddingBottom: '50px',
               
                 height: '85vh'
                 }}>
                <div className="uk-grid" data-uk-grid>
                     <div className="uk-width-1-2@s">
                       <div className="uk-container uk-margin-xlarge-top ">

                           <div className="uk-grid" data-uk-grid>
                              <div className="uk-width-1-6@s">

                              </div>


                              <div className="uk-width-5-6@s uk-padding">
                              <h2 className="header2 uk-text-bold uk-text-left light ">Let’s sit down
                               and talk about
                                 your new home</h2>
                              </div>


                              <div className="uk-width-1-5@s">

                              </div>

                           </div>

                           <div className="uk-grid" data-uk-grid>
                              <div className="uk-width-1-5">

                              </div>


                              <div className="uk-width-3-5">
                            <Link to="/contact" className='ctaBtn'>Consult Us
                               </Link>
                              </div>


                              <div className="uk-width-1-5">

                              </div>


                           </div>

                           </div>
                     </div>


                     <div className="uk-width-1-2@s">
                       <div
                         style={{ backgroundImage: `url(${int})`,
                         backgroundRepeat: 'no-repeat',
                         backgroundSize: 'cover',
                        
                       
                         height: '85vh'
                         }}
                       
                       >

                        </div>
                     </div>
                </div>
                </div>
             </div>















    </div>

)

export default Fifth_Pane