import react from 'react';
import v8 from './../../../images/v8.jpg'
import lifecamp7 from './../../../images/null.png'
import {Link } from 'react-router-dom'



const Fourth_Pane = () => (

    <div className=''>

              {/***DEKSTOP***/}
          <div className='uk-visible@s'>
              <div className='uk-container   uk-margin-large-top '>
             </div>



           <div className='uk-container '>
           <div className='uk-grid' data-uk-grid>
           <div className='uk-width-1-2@s'>
                  <div className='uk-padding' uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true">
                      <img src= {lifecamp7} className=""/>
                    </div>
               </div>

   
               <div className='uk-width-1-2@s uk-padding uk-margin-large-top'>
                    <h1 className='header1 uk-margin-remove-top'  uk-scrollspy="cls: uk-animation-slide-right; repeat: true">Great homes begin with great relationships.</h1>
                  <p className='uk-text-small'  uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                  Throughout the design process, we collaborate with each client to gain an understanding of their unique needs – defining the success of each building.
                  </p>

                  <h6 className='header6 uk-text-small uk-text-bold uk-margin-top '  uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true">
                    Toolbox Ateliers attracts the kind of people who make no small plans,
                   who thrive in collaboration, and have the determination to realize their ideas. </h6>
                  
                   <div className='uk-grid uk-margin-medium-top' data-uk-grid>
                       <div className='uk-width-1-2'>
                       <Link to="/Portfolio"><div class="btn uk-text-center " uk-scrollspy="cls: uk-animation-slide-left; repeat: true">View Portfolio</div> </Link>
                 
                       </div>
                       <div className='uk-width-1-2'>

                        </div>
                   </div>
                       
               </div>

              

           </div>
           </div>
           </div>







           
          <div className='uk-hidden@s'>
        <div className='uk-container   uk-margin-large-top '>
             </div>



           <div className='uk-container '>
           <div className='uk-grid' data-uk-grid>

           <div className='uk-width-1-2@s'>
                  <div className='uk-padding' uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true">
                      <img src= {lifecamp7} />
                    </div>
               </div>

               <div className='uk-width-1-2@s uk-padding-large uk-margin-large-top'>
                  <p className='uk-text-light uk-text-small uk-text-left'>VISION</p>
                  <h1 className='header1 uk-margin-remove-top'  uk-scrollspy="cls: uk-animation-slide-right; repeat: true">Architectures for Complex Projects</h1>
                  <p className='uk-text-small'  uk-scrollspy="cls: uk-animation-slide-right; repeat: true">To become the foremost and biggest player in the real estate market in Nigeria and Africa by providing top quality affordable and exquisite housing solutions to 
                  our esteemed clientele while also giving them value for money</p>

                  <p className='uk-text-small'  uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true">To become the foremost and biggest player in the real estate market in Nigeria and Africa by providing top quality affordable and exquisite housing solutions to 
                  our esteemed clientele while also giving them value for money</p>
               </div>

               

           </div>
           </div>
           </div>

    </div>






)

export default Fourth_Pane;