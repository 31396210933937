import react from 'react'
import {Link } from 'react-router-dom'
import sapphire1 from './../../../images/sapphire1.jpg'
import sapphire2 from './../../../images/sapphire2.jpg'
import sapphire3 from './../../../images/sapphire3.jpg'
import sapphire4 from './../../../images/sapphire4.jpg'

import sapphire5 from './../../../images/sapphire5.jpg'
import quality from './../../../images/quality.png'
import architectBg from './../../../images/architectBg.png'
import gray_dots from './../../../images/gray_dots.jpg'
import vc6 from './../../../images/vc6.jpg'






const Fourth_Pane = ( ) =>(
    <div>
    <div className='uk-container uk-margin-large-top '>

           <div className='uk-text-center'>
                 <h2 className='uk-text-bold'>Boite Sapphire Realizations</h2>
           </div>


           <div className='uk-grid uk-grid-small uk-margin-medium-top' data-uk-grid>

               <div className='uk-width-1-3@s'>
                 <div 
                   style={{ backgroundImage: `url(${sapphire1})`,
                   backgroundRepeat: 'no-repeat',
                   backgroundSize: 'cover',
                   paddingBottom: '50px',
                   paddingTop: '100px',
                   height: '45vh'
               }}
               uk-scrollspy="cls: uk-animation-slide-left; repeat: true"
               >

                 </div>
               </div>

               <div className='uk-width-1-3@s '>
                 <div 
                   style={{ backgroundImage: `url(${sapphire2})`,
                   backgroundRepeat: 'no-repeat',
                   backgroundSize: 'cover',
                   paddingBottom: '50px',
                   paddingTop: '100px',
                   height: '45vh'
               }}
               uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true"
               >

                 </div>
               </div>

               <div className='uk-width-1-3@s'>
                 <div
                  style={{ backgroundImage: `url(${sapphire3})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  paddingBottom: '50px',
                  paddingTop: '100px',
                  height: '45vh'
              }}
              uk-scrollspy="cls: uk-animation-slide-right; repeat: true" >
                     
                 </div>
               </div>

           </div>



           <div className='uk-grid uk-grid-small uk-margin-small-top' data-uk-grid>
               <div className='uk-width-1-2@s'>
                      <div 
                       style={{ backgroundImage: `url(${sapphire4})`,
                       backgroundRepeat: 'no-repeat',
                       backgroundSize: 'cover',
                       paddingBottom: '50px',
                       paddingTop: '100px',
                       height: '50vh'
                   }}
                   uk-scrollspy="cls: uk-animation-slide-left; repeat: true"  >

                      </div>
               </div>

               <div className='uk-width-1-2@s'>
                    <div className='uk-grid uk-grid-small' data-uk-grid>
                          <div className='uk-width-1-2'>
                              <div 
                              
                              style={{
                              backgroundColor:"#3FC6EB", 
                              height:"50vh",
                              width:"100%",
            
                               }}
                               uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true"
                               >


                              <div className="uk-padding ">
                                <img src = {quality}  className="quality"/>
                                 <h4 className="light uk-margin-medium-top">We don't compromise quality</h4>
                             </div>

                              </div>
                          </div>

                          <div className='uk-width-1-2'>
                              <div
                              
                              style={{ backgroundImage: `url(${sapphire2})`,
                              backgroundRepeat: 'no-repeat',
                              backgroundSize: 'cover',
                              paddingBottom: '50px',
                              paddingTop: '100px',
                              height: '50vh'
                          }}
                             
                          uk-scrollspy="cls: uk-animation-slide-right; repeat: true"
                             >


                              </div>
                          </div>
                    </div>
               </div>
           </div>


           </div>
          

                <div className='uk-margin-large-top'
                     style={{ backgroundImage: `url(${architectBg})`,
                     backgroundRepeat: 'no-repeat',
                     backgroundSize: 'cover',
                     paddingBottom: '50px',
                     paddingTop: '100px',
                     height: '60vh'
                 }}
                >


                   <div className='uk-container'>

                    <div className='uk-grid uk-padding uk-grid-collapse' data-uk-grid>
                          <div className='uk-width-2-5@s'>
                              <div className='' >
                                     <div className=''>
                                          <h1>Location</h1>
                                     </div>

                                     <div className=''>
                                     <p>Situated in Lifecamp, a vibrant and rejuvenated 
                                 area of Abuja. Boite Sapphire offers residents 
                                 the perfect spot for city living.</p>
                                     </div>
                              </div>
                               
                          </div>


                          
                          <div className='uk-width-3-5@s'>
                            
                          </div>
                    </div>
                    </div>

                </div>




                


       {/*Next pane*/}
             <div className="uk-margin-xlarge-top">
                 <div className=""
                 style={{ backgroundImage: `url(${gray_dots})`,
                 backgroundRepeat: 'no-repeat',
                 backgroundSize: 'cover',
                 paddingBottom: '50px',
               
                 height: '70vh'
                 }}>
                <div className="uk-grid" data-uk-grid>
                     <div className="uk-width-1-2@s">
                       <div className="uk-container uk-margin-large-top ">

                           <div className="uk-grid" data-uk-grid>
                              <div className="uk-width-1-5@s">

                              </div>


                              <div className="uk-width-3-5@s">
                              <h1 className="uk-text-bold uk-text-left light uk-padding">Let’s sit down
                               and talk about
                                 your new home</h1>
                              </div>


                              <div className="uk-width-1-5@s">

                              </div>


                           </div>




                           <div className="uk-grid" data-uk-grid>
                              <div className="uk-width-1-5">

                              </div>


                              <div className="uk-width-3-5">
                            <Link to="/contact"><p className="header1 uk-text-left light uk-padding">Contact Us
                               </p>  </Link>
                              </div>


                              <div className="uk-width-1-5">

                              </div>


                           </div>



                      
                           </div>
                     </div>


                     <div className="uk-width-1-2@s">
                       <div
                         style={{ backgroundImage: `url(${sapphire5})`,
                         backgroundRepeat: 'no-repeat',
                         backgroundSize: 'cover',
                        
                       
                         height: '70vh'
                         }}
                       
                       >



                        </div>
                     </div>
                </div>
                </div>
             </div>


    </div>
)


export default Fourth_Pane