import react from 'react'
import projectImg2 from './../../../images/projectImg2.jpg'
import v1 from './../../../images/v1.jpg'
import v3 from './../../../images/v3.jpg'
import v4 from './../../../images/v4.jpg'
import v8 from './../../../images/v8.jpg'
import v9 from './../../../images/v9.jpg'
import {Link} from 'react-router-dom'


const Third_Pane = () =>{
    return(
        <div>
            <div className='uk-container uk-margin-large-top'>

                         <div className='header1 uk-text-center uk-margin-medium-bottom'>
                             <h1>Boite 311 Realizations</h1>
                         </div>
                  <div className='uk-grid uk-grid-small' data-uk-grid>
                      <div className='uk-width-1-2@s'>
                           <div
                           style={{ backgroundImage: `url(${v1})`,
                           backgroundRepeat: 'no-repeat',
                           backgroundSize: 'cover',
                           paddingBottom: '10px',
                         
                           height: '55vh'
                           }}>


                           </div>
                      </div>

                      <div className='uk-width-1-2@s'>
                           
                      <div
                           style={{ backgroundImage: `url(${v3})`,
                           backgroundRepeat: 'no-repeat',
                           backgroundSize: 'cover',
                           paddingBottom: '10px',
                         
                           height: '55vh'
                           }}>


                           </div>

                      </div>
                  </div>


                   <div className='uk-grid uk-grid-small' data-uk-grid>
                      <div className='uk-width-1-3@s'>
                      <div
                           style={{ backgroundImage: `url(${v4})`,
                           backgroundRepeat: 'no-repeat',
                           backgroundSize: 'cover',
                           paddingBottom: '10px',
                         
                           height: '55vh'
                           }}>


                           </div>
                      </div>

                      <div className='uk-width-1-3@s'>
                      <div
                           style={{ backgroundImage: `url(${v8})`,
                           backgroundRepeat: 'no-repeat',
                           backgroundSize: 'cover',
                           paddingBottom: '10px',
                         
                           height: '55vh'
                           }}>


                           </div>
                     </div>


                     <div className='uk-width-1-3@s'>
                     <div
                           style={{ backgroundImage: `url(${v9})`,
                           backgroundRepeat: 'no-repeat',
                           backgroundSize: 'cover',
                           paddingBottom: '10px',
                         
                           height: '55vh'
                           }}>


                           </div>
                     </div>
                   </div>














            </div>
        </div>
    )
}


export default Third_Pane;