import react from 'react'
import projectImg from './../../../images/projectImg.jpg'
import projectImg2 from './../../../images/projectImg2.jpg'
import { Link } from 'react-router-dom' 




const Third_Pane = () => {
    return(

        <div className='uk-container uk-margin-large-top'>
        



        </div>
    )
}


export default Third_Pane