import  react from 'react';

import teamHeader from './../../../images/teamHeader.jpg'

import team4 from './../../../images/team4.png'
import briefCase from './../../../images/briefCase.png'


import Layout from './../../Layout/layout'




export default function() {
    return(
        <Layout>
        <div
        
        style={{ 
        backgroundImage: `-webkit-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${teamHeader})`,
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${teamHeader})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        paddingBottom: '50px',
        paddingTop: '100px',
        height: '75vh'
        }}


    
    >


<div className='uk-container '>
             <div className='uk-grid' data-uk-grid>
                 <div className='uk-width-1-3@s uk-margin-xlarge-top'>
                    <h1 className='light uk-text-bold'>Suraj Umar Abdulhameed  </h1>
                    <h3 className='light uk-margin-remove-top'>Head of Marketing </h3>

                    <div className='uk-margin-top'>
                      <a href="" class="uk-icon-button uk-margin-small-right" uk-icon="twitter"></a>
                        <a href="" class="uk-icon-button  uk-margin-small-right" uk-icon="facebook"></a>
                        <a href="" class="uk-icon-button" uk-icon="youtube"></a>
                        </div>
                 </div>

                 <div className='uk-width-1-3@s'>

                 </div>

                 <div className='uk-width-1-3@s'>
                     <div className='teamBg'>
                     <img src= {team4} />
                     </div>
                 </div>
             </div>
        </div>


      


    </div>



        <div className='uk-container uk-margin-large-top'>
        <div className='uk-grid' data-uk-grid>
            <div className='uk-width-3-5@s'>
               <p className='uk-margin-top'>
               A graduate of Business and information system, 
               from the Universi ty of East London, Suraj is 
               well grounded in the business world</p>

                <p className='uk-margin-top'>
                He is adept at providing operations and process management 
                strategies for business ventures. He holds professional
                knowledge in coordinating activities of Business and 
                Strategy Division</p>

                

              

            </div>


            <div className='uk-width-2-5@s uk-margin-medium-top '>
               
               <div className='teamDesc'>
            <div class="uk-flex ">
                <div class="uk-card "><img src ={briefCase} className="briefImg"/></div>
                <div class="uk-card uk-margin-left"><h4 className='uk-text-bold'> Marketing, Leads</h4></div>
            </div>


            <div class="uk-flex">
                <div class="uk-card "><a href="" uk-icon="icon: location"></a></div>
                <div class="uk-card uk-margin-left"><h4 className=''>Abuja, Nigeria</h4></div>
            </div>
            </div>

               
               </div>
        </div>


        </div>




        <div className='uk-container uk-margin-large-top'>
            <div className='uk-grid' data-uk-grid>
                 <div className='uk-width-1-2@s'>
                   <div>
                       <h1 className=''>FUN <span className=' uk-text-bold'>FACT</span></h1>
                       <hr className='line'></hr>

                       <p>Suraj holidays only in Europe and some part of Africa</p>
                   </div>
                 </div>

                 <div className='uk-width-1-2@s'>
                 
                 </div>
            </div>
        </div>


    </Layout>
    )
}