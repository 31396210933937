import react from 'react'
import Fourth_Pane from './Fourth_Pane'
import Layout from '../Layout/'
import First_Pane from './First_Pane'
import Second_Pane from './Second_Pane'
import Third_Pane from './Third_Pane'
import Fifth_Pane from './Fifth_Pane'




const Home2 = () => (
      <Layout >
       <First_Pane />
       <Second_Pane />
       <Third_Pane />
       <div className='uk-margin-remove-top fourthUpPane'><Fourth_Pane /></div>
       <Fifth_Pane className=""/>


          </Layout>
)


export default Home2